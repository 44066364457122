import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Popup from '../../components/Popup/Popup';
import Button from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import api from '../../config/api';
import compliant from '../../assets/images/compliant.png';
import notCompliant from '../../assets/images/notCompliant.png';
import sortUp from '../../assets/images/sortUp.svg';
import sortDown from '../../assets/images/sortDown.svg';
import css from './ListOfFormsPage.module.css';
import ReactPaginate from 'react-paginate';
import FilterModal from '../../components/FilterModal/FilterModal';
import edit_icon from '../../assets/images/edit.png';

const ListOfFormsPage = () => {
  const [id, setId] = useState();
  const [value, setValue] = useState('all');
  const [forms, setForms] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [formsToSend, setFormsToSend] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isResume, setIsResume] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [sortBy, setSortBy] = useState({ id: 'id' });
  const [options, setOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formFilters, setFormFilters] = useState([]);
  const navigate = useNavigate();

  const getData = () => {
    api.GET_FORMS(page).then(response => {
      setLastPage(response.lastPage);
      setForms(
        response.items.map(el => {
          if (el.status === 'new' || el.status === 'processing') {
            setTimeout(() => getData(), 15000);
          }
          return { ...el, isChecked: false, created_by: el?.created_by ?? '-' };
        }),
      );
    });
  };

  const getFilteredData = value => {
    api.GET_FILTERED_FORMS(value, page).then(response => {
      setLastPage(response.lastPage);
      setForms(
        response.items.map(el => {
          if (el.status === 'new' || el.status === 'processing') {
            setTimeout(() => getData(), 15000);
          }
          return { ...el, isChecked: false, created_by: el?.created_by ?? '-' };
        }),
      );
    });
  };

  useEffect(() => {
    if (isPopupOpen) {
      return;
    }
    if (value === 'all') {
      getData();
    } else {
      getFilteredData(value);
    }
  }, [page, isPopupOpen, value]);

  useEffect(() => {
    api
      .GET_FORMS_OPTIONS()
      .then(response => {
          setFormFilters(response.form_filters);
          setOptions([{ key: 'all', title: 'All Statuses' }, ...response.booking_form_statuses]);
        },
      );
  }, []);

  const handleSelectChange = e => {
    const value = e.target.value;
    setValue(value);
    setPage(1);
    setLastPage(1);

    if (value === 'all') {
      getData();
      return;
    }

    getFilteredData(value);
  };

  const handleSortForms = (e, search, keys) => {
    if (!e && search && keys) {
      api.GET_SEARCHED_FORMS('-id', search, keys.join(','), value).then(response => {
          setForms(
            response.map(el => {
              if (el.status === 'new' || el.status === 'processing') {
                setTimeout(() => getData(), 15000);
              }
              return {
                ...el,
                isChecked: false,
                created_by: el?.created_by ?? '-',
              };
            }),
          );
          setPage(1);
          setLastPage((response.length) / 20);
        },
      );
      return;
    }
    if (search === '' && value === 'all') {
      getData();
      return;
    } else {
      getFilteredData(value);
    }
    if (
      e.target.tagName === 'INPUT' ||
      e.target.closest('th').id === 'compliance'
    ) {
      return;
    }

    const sortById = e.target.closest('th').id;
    if (sortBy.id === sortById) {
      api.GET_SORTED_FORMS(sortById).then(response =>
        setForms(
          response.map(el => {
            if (el.status === 'new' || el.status === 'processing') {
              setTimeout(() => getData(), 15000);
            }
            return {
              ...el,
              isChecked: false,
              created_by: el?.created_by ?? '-',
            };
          }),
        ),
      );
      setSortBy({ id: null });
      return;
    }

    api.GET_SORTED_FORMS(`-${sortById}`).then(response =>
      setForms(
        response.map(el => {
          if (el.status === 'new' || el.status === 'processing') {
            setTimeout(() => getData(), 15000);
          }
          return { ...el, isChecked: false, created_by: el?.created_by ?? '-' };
        }),
      ),
    );
    setSortBy({ id: sortById });
  };

  const handleCheckAll = () => {
    if (isAllChecked === false) {
      setIsAllChecked(true);
      return setForms(forms.map(el => ({ ...el, isChecked: true })));
    } else {
      setIsAllChecked(false);
      return setForms(forms.map(el => ({ ...el, isChecked: false })));
    }
  };

  const handleCheckForm = (id) =>
    setForms(
      forms.map(el => {
        if (el.id === id) {
          el.isChecked = !el.isChecked;
          return el;
        }

        return el;
      }),
    );

  const handleOpenArchivePopup = () => {
    if (!forms.find(el => el.isChecked)) {
      return;
    }

    setFormsToSend(forms.filter(el => el.isChecked));
    setIsPopupOpen(true);
    setIsAllChecked(false);
  };

  const handleOpenDeletePopup = () => {
    if (!forms.find(el => el.isChecked)) {
      return;
    }

    setFormsToSend(forms.filter(el => el.isChecked));
    setIsDelete(true);
    setIsPopupOpen(true);
    setIsAllChecked(false);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
    setIsDelete(false);
    setIsAllChecked(false);
    setIsResume(false);
    setFormsToSend(null);
    setId(null);
  };

  const handleFormClick = (e, id) => {
    if (e.target.tagName === 'INPUT' || e.target.tagName === 'BUTTON') {
      return;
    }
    navigate(`/booking-form/${id}`, { replace: true });
  };

  const handleIsResume = (id) => {
    setId(id);
    setIsResume(true);
    setIsPopupOpen(true);
  };

  const handleGeneratePDF = (id) => {
    api.GET_DOCUMENTS(id).then(
      res =>
        res.status === 'OK' &&
        res.data.map(doc =>
          api.DOWNLOAD_DOCUMENT(id, { filename: doc.name }).then(response => {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: 'application/pdf;charset=utf-8',
              }),
            );
            const link = document.createElement('a');
            link.href = url;
            link.download = doc.download_name;
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
          }),
        ),
    );
  };

  const handlePressFilterModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      {isPopupOpen && (
        <Popup
          isDelete={isDelete}
          isResume={isResume}
          onClose={handlePopupClose}
          users={formsToSend}
          id={id}
        />
      )}
      <main className={css.main}>
        <div className={css.btnsWrapper}>
          <div className={css.flex}>
            <Link className={css.create} to='/booking-form'>
              Create
            </Link>
            <Select
              value={value}
              options={options}
              onChange={handleSelectChange}
            />
          </div>
          <div>
            <div className={css.btnContainer}>
              <Button
                type='delete'
                onClick={handleOpenDeletePopup}
                title='Delete'
              />
            </div>
            <Button
              type='cancel'
              onClick={handleOpenArchivePopup}
              title='Archive'
            />
          </div>
        </div>
        <div className={css.tableWrapper}>
          <table className={css.table}>
            <thead className={css.thead}>
            <tr className={css.trHead} onClick={handleSortForms}>
              <th className={css.thFirst} id='applicant_name'>
                <label className={css.checkboxFirst}>
                  <input
                    className={css.checkbox}
                    type='checkbox'
                    checked={isAllChecked}
                    onChange={handleCheckAll}
                  />
                </label>
                Applicant Name
                <img
                  alt='sorting way'
                  className={css.icon}
                  src={sortBy.id === 'applicant_name' ? sortUp : sortDown}
                />
              </th>
              <th className={css.thSecond} id='applicant_position'>
                Applicant Position
                <img
                  alt='sorting way'
                  className={css.icon}
                  src={sortBy.id === 'applicant_position' ? sortUp : sortDown}
                />
              </th>
              <th className={css.thFormId} id='id'>
                Form id
                <img
                  alt='sorting way'
                  className={css.icon}
                  src={sortBy.id === 'id' ? sortUp : sortDown}
                />
              </th>
              <th className={css.th} id='placement_external_id'>
                Placement ID
                <img
                  alt='sorting way'
                  className={css.icon}
                  src={
                    sortBy.id === 'placement_external_id' ? sortUp : sortDown
                  }
                />
              </th>
              <th className={css.th} id='status'>
                Form Status
                <img
                  alt='sorting way'
                  className={css.icon}
                  src={sortBy.id === 'status' ? sortUp : sortDown}
                />
              </th>
              <th className={css.th} id='updated_at'>
                Last updated
                <img
                  alt='sorting way'
                  className={css.icon}
                  src={sortBy.id === 'updated_at' ? sortUp : sortDown}
                />
              </th>
              <th className={`${css.thCompliance}`} id='compliance'>
                Compliance
              </th>
              <th className={`${css.th}`} id='completed_by'>
                Completed
                <img
                  alt='sorting way'
                  className={css.icon}
                  src={sortBy.id === 'completed_by' ? sortUp : sortDown}
                />
              </th>
              <th className={`${css.thLast} ${css.th5}`} id='created_by'>
                <div className={css.lastContainer}>
                  <div>
                    Created
                    <img
                      alt='sorting way'
                      className={css.icon}
                      src={sortBy.id === 'created_by' ? sortUp : sortDown}
                    />
                  </div>
                  <div className={css.filterContainer} onClick={(e) => handlePressFilterModal(e, 'applicant_name')}>
                    <Button
                      title='Filter'
                      type='filter'
                      onClick={() => {
                      }}
                    />
                  </div>
                  {isModalOpen && (
                    <FilterModal
                      onClose={handlePressFilterModal}
                      onSearch={handleSortForms}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      formFilters={formFilters} />
                  )}
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colSpan='9'>
                <div className={css.tbody}>
                  <table className={css.table}>
                    <tbody>
                    {forms.length > 0 &&
                      forms.map(el => (
                        <tr
                          className={css.tr}
                          key={el.id}
                          id={el.id}
                          onClick={(event)=>{
                            handleFormClick(event, el.id);
                          }}
                        >
                          <td className={`${css.tdFirst} ${css.td1}`}>
                            <label>
                              <input
                                className={css.checkbox}
                                type='checkbox'
                                checked={el.isChecked}
                                onChange={handleCheckForm.bind(this, el.id)}
                              />
                            </label>
                            <div className={css.tdNew}>
                              {el.applicant_name}
                            </div>
                          </td>
                          <td>
                            <div className={css.tdNew}>
                              {el.applicant_position}
                            </div>
                          </td>
                          <td className={`${css.td} ${css.td2}`}>
                            {el.id}
                          </td>
                          <td className={`${css.td} ${css.td3}`}>
                            {el.placement_external_id}
                          </td>
                          <td className={`${css.td} ${css.td4Draft}`}>
                            {el.status_name}
                          </td>
                          <td className={`${css.td} ${css.td4Date}`}>
                            {moment(el.updated_at).format('DD/MM/YYYY')}
                          </td>
                          <td className={`${css.td} ${css.td4}`}>
                            {el.is_compliant ? (
                              <div className={css.compliantContainer}>
                                <img
                                  className={css.img}
                                  src={compliant}
                                  alt='compliant'
                                />
                                <p className={css.compliant}>Compliant</p>
                              </div>
                            ) : (
                              <div className={css.compliantContainer}>
                                <img
                                  className={css.img}
                                  src={notCompliant}
                                  alt='not compliant'
                                />
                                <p className={css.notCompliant}>
                                  Not Compliant
                                </p>
                              </div>
                            )}
                          </td>
                          <td className={`${css.td} ${css.td4}`}>
                            <div className={css.completedContainer}>
                              {el.completed_by}
                              {el.edited_by &&
                                <img src={edit_icon} alt='Edit Icon' className={css.editIcon} title={el.edited_by_user} />
                              }
                            </div>

                          </td>
                          <td className={`${css.tdLast} ${css.td5}`}>
                            {el.created_by}

                            {!el.is_compliant && (
                              <Button
                                type='accent'
                                onClick={handleIsResume.bind(this, el.id)}
                                title='Resume'
                                disabled={el.is_compliant}
                              />
                            )}
                            {el.status === 'sent' && (
                              <Button
                                title='PDFs'
                                type='pdf'
                                onClick={handleGeneratePDF.bind(
                                  this,
                                  el.id,
                                )}
                              />
                            )}
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

          <ReactPaginate
            breakLabel='...'
            nextLabel='Next →'
            onPageChange={({ selected }) => {
              setPage(selected + 1);
            }}
            pageRangeDisplayed={5}
            pageCount={lastPage}
            initialPage={0}
            previousLabel='← Previous'
            containerClassName={css.pagination}
            marginPagesDisplayed
            previousLinkClassName={css.pagination__link}
            nextLinkClassName={css.pagination__link}
            disabledClassName={css.pagination__link_disabled}
            activeClassName={css.pagination__link_active}
          />
        </div>
      </main>
    </>
  );
};

export default ListOfFormsPage;
