import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {
  setAdHocDates,
  changeRate,
  setIsHoliday,
  saveDatesForBooking,
} from '../../../features/bookingForm/bookingFormSlice';
import Calendar from '../Calendar/Calendar';
import JobSectionFooter from '../JobSectionFooter';
import Input from '../../Input/Input';
import Select from '../../Select/Select';
import delete_icon from '../../../assets/images/delete.png';
import split_icon from '../../../assets/images/split.png';
import css from '../Standard/Standard.module.css';
import localCss from './AdHoc.module.css';
import { IMaskInput } from 'react-imask';
import inputCss from '../../Input/Input.module.css';

const momentFormat = 'HH:mm';

const AdHoc = ({ isAvailability }) => {
  const [hint, setHint] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [disableConfirm, setDisableConfirm] = useState(false);

  const adHocDates = useSelector(state => state.bookingForm.adHocDates);
  const { isHoliday } = useSelector(state => state.bookingForm);

  const dates = useSelector(
    state => state.bookingForm.formData.job?.booked_data?.dates,
  );

  const pay_rates = useSelector(
    state => state.bookingForm.formData.job?.booked_data.pay_rates,
  );
  const placement_purchase_order = useSelector(
    state => state.bookingForm.formData.job?.placement_purchase_order,
  );
  const { shift_types } = useSelector(state => state.referenceBook.bookData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dates?.length > 0) {
      let modifiedDates = [...dates];
      let dateCounts = modifiedDates.reduce((acc, cur) => {
        acc[cur.date] = (acc[cur.date] || 0) + 1;
        return acc;
      }, {});

      let seenDates = {};

      modifiedDates = modifiedDates.map(el => {
        if (dateCounts[el.date] > 1) {
          seenDates[el.date] = seenDates[el.date] ? seenDates[el.date] + 1 : 1;
          if (seenDates[el.date] === 2) {
            return { ...el, splitDate: el.date, hours: formatTime(el.hours) };
          }
        }
        const newEl = { ...el, hours: formatTime(el.hours) };
        return newEl;
      });
      dispatch(setAdHocDates(modifiedDates));
    }
  }, [dates, dispatch]);

  function formatTime(time) {
    let timeStr = time.toString();
    if (time < 100) {
      timeStr = timeStr.padStart(2, '0') + '00';
    } else {
      timeStr = timeStr.padStart(4, '0');
    }
    return timeStr.slice(0, 2) + ':' + timeStr.slice(2);
  }

  let title = 'Day Shift';

  const options = [
    {
      key: 'all',
      title: 'Copy 1st date data for all picked dates',
    },
  ];

  const handleCheckbox = () => dispatch(setIsHoliday(!isHoliday));

  const handleChange = (day, e, splitDate) => {
    if (e.target.name === 'hours') {
      if (e.target.value === '') {
      } else if (+e.target.value <= 0) return;
    }
    let datesToDispatch = [...adHocDates];
    const filterField = splitDate ? 'splitDate' : 'date';
    datesToDispatch = datesToDispatch.map(el => {
      if (el[filterField] === day && (!el.splitDate || splitDate)) {
        if (e.target.name === 'hours') {
          if (!e.target.value) return el;
          const parseToHours = moment(e.target.value, momentFormat).format('h');
          if (parseToHours === 'Invalid date') {
            return {
              ...el,
              [e.target.name]: '',
            };
          }
          const getHours = moment(e.target.value, momentFormat).get('h');
          const getMinutes = moment(e.target.value, momentFormat).format('m');

          let endTime = moment(el.start)
            .add(+getHours, 'h')
            .add(+getMinutes, 'm')
            .toDate();

          if (e.target.value === '2400' || e.target.value === '24:00') {
            let tempEndTime = new Date(endTime);
            tempEndTime.setDate(tempEndTime.getDate() + 1);
            endTime = tempEndTime;
          }

          return {
            ...el,
            [e.target.name]: e.target.value,
            end: endTime,
            end_time: endTime,
          };
        } else if (e.target.name === 'shift_external_id') {
          const sameDates = datesToDispatch.filter(
            date =>
              moment(date[filterField]).format('YYYY-MM-DD') ===
              moment(day).format('YYYY-MM-DD'),
          );

          if (sameDates.length > 1) {
            let shift;
            if (filterField === 'date') {
              const relevantDay = sameDates.find(d => !d.splitDate);
              shift = relevantDay ? relevantDay.shift_external_id : null;
            } else {
              const relevantDay = sameDates.find(d => d.splitDate === day);
              shift = relevantDay ? relevantDay.shift_external_id : null;
            }

            if (+shift === +e.target.value || +e.target.value === 39) {
              setHint(true);
              return el;
            } else if (+e.target.value === 28 || +e.target.value === 44) {
              if (+shift === 28 || +shift === 44) {
                setHint(true);
                return el;
              }
            } else if (
              +e.target.value === 30 ||
              +e.target.value === 40 ||
              +e.target.value === 41 ||
              +e.target.value === 43
            ) {
              if (
                +shift === 30 ||
                +shift === 40 ||
                +shift === 41 ||
                +shift === 43
              ) {
                setHint(true);
                return el;
              }
            }
          }

          const startTime =
            +e.target.value === 28 || +e.target.value === 44
              ? '19:00:00'
              : +e.target.value === 39
              ? '00:00:00'
              : '07:00:00';
          const endTime =
            +e.target.value === 28 || +e.target.value === 44
              ? '07:00:00'
              : +e.target.value === 39
              ? '00:00:00'
              : '19:00:00';
          const hours = +e.target.value === 39 ? '24:00' : '12:00';

          return {
            ...el,
            [e.target.name]: e.target.value,
            start: new Date(
              moment(el.start).format('YYYY-MM-DD') + 'T' + startTime,
            ),
            end: new Date(
              `${
                +e.target.value === 28 ||
                +e.target.value === 44 ||
                +e.target.value === 39
                  ? moment(el.start).add(1, 'day').format('YYYY-MM-DD')
                  : moment(el.start).format('YYYY-MM-DD')
              }T` + endTime,
            ),
            start_time: new Date(
              moment(el.start).format('YYYY-MM-DD') + 'T' + startTime,
            ),
            end_time: new Date(
              `${
                +e.target.value === 28 ||
                +e.target.value === 44 ||
                +e.target.value === 39
                  ? moment(el.start).add(1, 'day').format('YYYY-MM-DD')
                  : moment(el.start).format('YYYY-MM-DD')
              }T` + endTime,
            ),
            hours: hours,
            title:
              e.target.name === 'shift_external_id'
                ? shift_types.find(el => +el.external_id === +e.target.value)
                    .name
                : title,
          };
        } else {
          return {
            ...el,
            [e.target.name]: e.target.value,
          };
        }
      }
      return el;
    });

    if (e.target.name === 'shift_external_id') {
      let newRates = [];
      if (!pay_rates.find(el => +el.shift_external_id === +e.target.value)) {
        const shiftType = shift_types.find(
          el => el.external_id === +e.target.value,
        );

        let currentDay;
        if (filterField === 'date') {
          currentDay = adHocDates.find(el => el.date === day);
        } else {
          currentDay = adHocDates.find(el => el.splitDate === day);
        }

        const currentDays =
          datesToDispatch?.filter(
            el => +el.shift_external_id === +currentDay?.shift_external_id,
          ) ?? [];

        newRates = [
          ...pay_rates,
          {
            new: true,
            id: shiftType.overtime_rate_external_id,
            external_id: shiftType.overtime_rate_external_id,
            shift_external_id: shiftType.external_id,
            name: shiftType.name,
            charge: 0,
            rate: 0,
            fee: 0,
            hidden: false,
          },
        ].filter(el =>
          +el.shift_external_id !== +currentDay.shift_external_id
            ? true
            : +el.shift_external_id === 30 || currentDays.length >= 1,
        );
      } else if (
        pay_rates.find(el => +el.shift_external_id === +e.target.value)
      ) {
        let currentDay;
        if (filterField === 'date') {
          currentDay = adHocDates.find(el => el.date === day);
        } else {
          currentDay = adHocDates.find(el => el.splitDate === day);
        }

        const currentDays =
          datesToDispatch?.filter(
            el => +el.shift_external_id === +currentDay?.shift_external_id,
          ) ?? [];

        newRates = [
          ...pay_rates
            .map(el => {
              if (+el.shift_external_id === +e.target.value) {
                return { ...el, hidden: false };
              }
              return el;
            })
            .filter(el =>
              +el.shift_external_id !== +currentDay.shift_external_id
                ? true
                : +el.shift_external_id === 30 || currentDays.length >= 1,
            ),
        ];
      }

      dispatch(changeRate(newRates));
    }

    // dispatch(setAdHocDates(datesToDispatch));
    checkDates(datesToDispatch);
  };

  const checkDates = datesToDispatch => {
    let modifiedDates = [...datesToDispatch];
    let dateCounts = modifiedDates.reduce((acc, cur) => {
      acc[cur.date] = (acc[cur.date] || 0) + 1;
      return acc;
    }, {});
    setDisableConfirm(false);

    let seenDates = {};
    let tempModifiedDates = [];
    modifiedDates.forEach((el, index) => {
      let newEl = { ...el };
      if (dateCounts[el.date] > 1) {
        seenDates[el.date] = seenDates[el.date] ? seenDates[el.date] + 1 : 1;
        modifiedDates.forEach(other => {
          if (other !== el && other.date === el.date) {
            const start = moment(el.start_time, 'YYYY-MM-DD HH:mm:ss');
            const end = moment(el.end_time, 'YYYY-MM-DD HH:mm:ss');
            const otherStart = moment(other.start_time, 'YYYY-MM-DD HH:mm:ss');
            const otherEnd = moment(other.end_time, 'YYYY-MM-DD HH:mm:ss');
            if (!el.splitDate) {
              if (start.isBefore(otherEnd)) {
                newEl.error = {
                  dateEndError: end.isAfter(otherStart),
                };
                setDisableConfirm(true);
              } else {
                newEl.error = {
                  dateStartError: false,
                };
                setDisableConfirm(false);
              }
            } else {
              if (start.isBefore(otherEnd)) {
                newEl.error = {
                  dateStartError: end.isAfter(otherStart),
                };
                setDisableConfirm(true);
              } else {
                newEl.error = {
                  dateStartError: false,
                };
                setDisableConfirm(false);
              }
            }
          }
        });

        if (seenDates[el.date] === 2) {
          newEl.splitDate = el.date;
        }
      } else {
        newEl.error = {
          dateStartError: false,
          dateEndError: false,
        };
      }
      tempModifiedDates.push(newEl);
    });

    dispatch(setAdHocDates(tempModifiedDates));
  };

  const handleStartTimeChange = (day, value, splitDate) => {
    let datesToDispatch = [...adHocDates];
    const filterField = splitDate ? 'splitDate' : 'date';
    datesToDispatch = datesToDispatch.map(el => {
      if (el[filterField] === day && (!el.splitDate || splitDate)) {
        if (!value) return el;
        const getHoursStart = moment(value, momentFormat).get('h');
        const getMinutesStart = moment(value, momentFormat).format('m');

        const getHours = moment(el.hours, momentFormat).get('h');
        const getMinutes = moment(el.hours, momentFormat).format('m');

        const startTime = moment(el.start)
          .set('h', getHoursStart)
          .set('m', getMinutesStart)
          .toDate();

        let endTime = moment(startTime)
          .add(+getHours, 'h')
          .add(+getMinutes, 'm')
          .toDate();

        // if (moment(value).toDate().getDate() !== endTime.getDate()) {
        //   endTime = moment(endTime)
        //     .subtract(1, 'd')
        //     .set('h', 23)
        //     .set('m', 59)
        //     .toDate();
        // }

        return {
          ...el,
          start: startTime,
          start_time: startTime,
          end: endTime,
          end_time: endTime,
        };
      }
      return el;
    });
    // dispatch(setAdHocDates(datesToDispatch));
    checkDates(datesToDispatch);
  };

  const handleCalendarClick = (value, e) => {
    if (
      adHocDates.filter(
        date =>
          moment(date.date).format('YYYY-MM-DD') ===
          moment(value).format('YYYY-MM-DD'),
      ).length > 1
    ) {
      setHint(true);
      return;
    }

    if (
      adHocDates.find(
        date =>
          moment(date.date).format('YYYY-MM-DD') ===
          moment(value).format('YYYY-MM-DD'),
      )
    ) {
      setHint(true);
      return;
    }

    const startTime = '07:00:00';
    const endTime = '19:00:00';

    const datesToDispatch = [...adHocDates];

    const getTitle = () => {
      const day = new Date(value).getDay();

      if (day === 6) {
        const unique = [
          ...new Map(
            [
              ...pay_rates,
              {
                charge: 0,
                external_id: 800,
                fee: 0,
                hidden: false,
                id: 800,
                name: 'Saturday',
                new: true,
                rate: 0,
                shift_external_id: 40,
              },
            ].map(item => [item['external_id'], item]),
          ).values(),
        ];

        dispatch(changeRate(unique));

        return {
          shift_external_id: 40,
          title: 'Saturday',
        };
      } else if (day === 0) {
        const unique = [
          ...new Map(
            [
              ...pay_rates,
              {
                charge: 0,
                external_id: 798,
                fee: 0,
                hidden: false,
                id: 798,
                name: 'Sunday & BH',
                new: true,
                rate: 0,
                shift_external_id: 41,
                custom: true,
              },
            ].map(item => [item['external_id'], item]),
          ).values(),
        ];

        dispatch(changeRate(unique));

        return {
          shift_external_id: 41,
          title: 'Sunday & BH',
        };
      }
      return {
        shift_external_id: 30,
        title: 'Day Shift',
      };
    };

    const date = {
      date: value,
      start: new Date(moment(value).format('YYYY-MM-DD') + 'T' + startTime),
      end: new Date(moment(value).format('YYYY-MM-DD') + 'T' + endTime),
      start_time: new Date(
        moment(value).format('YYYY-MM-DD') + 'T' + startTime,
      ),
      end_time: new Date(moment(value).format('YYYY-MM-DD') + 'T' + endTime),
      hours: '12:00',
      purchase_order: null,
      ...getTitle(),
    };
    datesToDispatch.push(date);

    dispatch(setAdHocDates(datesToDispatch));
  };

  const handleDelete = (day, split) => {
    let datesToDispatch = [...adHocDates];
    const filterField = split ? 'splitDate' : 'date';
    const currentDay = datesToDispatch.find(el => el[filterField] === day);

    if (currentDay) {
      const currentDays = adHocDates.filter(
        el => +el.shift_external_id === +currentDay.shift_external_id,
      );
      if (currentDays.length === 1) {
        const newRates = [
          ...pay_rates.filter(
            el =>
              +el.shift_external_id === 30 ||
              +el.shift_external_id !== +currentDay.shift_external_id,
          ),
        ];
        dispatch(changeRate(newRates));
      }

      if (split) {
        const originalEntryIndex = datesToDispatch.findIndex(
          el => el.date === day && !el.splitDate,
        );
        if (originalEntryIndex !== -1) {
          const originalEntry = datesToDispatch[originalEntryIndex];
          let durationToAdd = moment.duration(
            moment(currentDay.end_time).diff(moment(currentDay.start_time)),
          );

          let newEndTime = moment(originalEntry.end_time).add(durationToAdd);
          let additionalHours = durationToAdd.asHours();

          const updatedHours = originalEntry.hours + additionalHours;

          datesToDispatch[originalEntryIndex] = {
            ...originalEntry,
            end_time: new Date(newEndTime),
            end: new Date(newEndTime),
            hours: updatedHours,
          };
        }
      }
    }

    datesToDispatch = datesToDispatch.filter(el => el[filterField] !== day);
    // dispatch(setAdHocDates(datesToDispatch));
    checkDates(datesToDispatch);
  };

  const handlePressSplit = day => {
    let datesToDispatch = [...adHocDates];
    const alreadySplit = datesToDispatch.some(el => el.splitDate === day);

    if (!alreadySplit) {
      const index = datesToDispatch.findIndex(el => el.date === day);
      const currentDay = datesToDispatch[index];

      if (currentDay && index !== -1) {
        const startMoment = moment(
          currentDay.start_time,
          'YYYY-MM-DD HH:mm:ss',
        );
        const endMoment = moment(currentDay.end_time, 'YYYY-MM-DD HH:mm:ss');
        if (endMoment.isBefore(startMoment)) {
          endMoment.add(1, 'day');
        }
        const totalDuration = moment.duration(endMoment.diff(startMoment));
        const halfDuration = totalDuration.asMilliseconds() / 2;
        const middleTime = startMoment
          .clone()
          .add(Math.floor(halfDuration / 3600000) * 3600000, 'milliseconds');
        const halfHours = Math.floor(halfDuration / 3600000);

        const newDay = {
          ...currentDay,
          splitDate: currentDay.date,
          start_time: new Date(middleTime),
          start: new Date(middleTime),
          end_time: new Date(endMoment),
          end: new Date(endMoment),
          hours: halfHours,
        };
        datesToDispatch[index] = {
          ...currentDay,
          end_time: new Date(middleTime),
          end: new Date(middleTime),
          hours: halfHours,
        };

        datesToDispatch.splice(index + 1, 0, newDay);
      }
      dispatch(setAdHocDates(datesToDispatch));
    }
  };

  let datesToShow = [];

  if (adHocDates?.length > 0) {
    datesToShow = [...adHocDates];
    datesToShow.sort((a, b) => a.date - b.date);
  }

  const handleDuplicate = e => {
    if (e.target.value === 'all') {
      const dateToDuplicate = datesToShow[0];
      const duplicatedDates = datesToShow.map((date, index) => {
        const start = moment(date.start)
          .set('h', 0)
          .set('m', 0)
          .set('second', 0);
        const duplicateStart = moment(dateToDuplicate.start)
          .set('h', 0)
          .set('m', 0)
          .set('second', 0);
        const duplicateEnd = moment(dateToDuplicate.end)
          .set('h', 0)
          .set('m', 0)
          .set('second', 0)
          .toDate();

        const diff = moment(duplicateEnd).diff(duplicateStart, 'd');

        const end = moment(date.date)
          .add(diff, 'd')
          .set('h', moment(dateToDuplicate.end).get('h'))
          .set('m', moment(dateToDuplicate.end).get('m'))
          .toDate();

        return {
          ...date,
          shift_external_id: dateToDuplicate.shift_external_id,
          hours: dateToDuplicate.hours,
          start: moment(dateToDuplicate.start)
            .add(moment(start).diff(duplicateStart, 'd'), 'd')
            .toDate(),
          end: end,
          start_time: moment(dateToDuplicate.start)
            .add(moment(start).diff(duplicateStart, 'd'), 'd')
            .toDate(),
          end_time: end,
          purchase_order: dateToDuplicate.purchase_order,
          title: dateToDuplicate.title,
        };
      });
      dispatch(setAdHocDates(duplicatedDates));

      const newRates = [
        ...pay_rates.filter(
          el =>
            +el.shift_external_id === 30 ||
            +el.shift_external_id === +dateToDuplicate.shift_external_id,
        ),
      ];

      dispatch(changeRate(newRates));

      return;
    }
  };

  const parseDate = date => {
    if (typeof date !== 'string') return new Date(date);
    if (isAvailability) {
      return new Date(date);
    } else {
      return new Date(
        new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000,
      );
    }
  };

  const handleCloseHint = e => {
    e.stopPropagation();
    setHint(false);
  };

  return (
    <div className={css.main}>
      <Calendar onChange={handleCalendarClick} />
      <div className={css.checkboxWrapper}>
        <div className={css.checkboxContainer}>
          <div className={css.color1}></div>
          <span className={css.checkboxText}>Applicant Avaliable</span>
        </div>
        <div className={css.checkboxContainer}>
          <div className={css.color5}></div>
          <span className={css.checkboxText}>Applicant Allocated</span>
        </div>
        <div className={css.checkboxContainer}>
          <input
            className={css.checkbox}
            type="checkbox"
            checked={isHoliday}
            onChange={handleCheckbox}
          />
          <div className={css.color2}></div>
          <span className={css.checkboxText}>Bank Holiday</span>
        </div>
        <div className={css.checkboxContainer}>
          <div className={css.color3}></div>
          <span className={css.checkboxText}>Job Requirement</span>
        </div>
        <div className={css.checkboxContainer}>
          <div className={css.color4}></div>
          <span className={css.checkboxText}>Applicant Not Avaliable</span>
        </div>
      </div>
      <div className={css.grid}>
        <div className={css.header}>
          {hint ? (
            <div className={localCss.hintOverlay} onClick={handleCloseHint}>
              <div className={localCss.hintWrapper}>
                <p className={localCss.hintText}>
                  This date is already picked!
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <span className={css.header}>Shifts</span>
        <span className={css.header}>Hours</span>
        <span className={css.header}>Start Time</span>
        <span className={css.header}>End Time</span>
        <span className={css.header}>Purchase Order</span>
        <span className={css.header}>Ward</span>
        <div className={css.header}>
          {datesToShow.length > 1 ? (
            <Select
              value=""
              size="duplicate"
              options={options}
              onChange={handleDuplicate}
            />
          ) : null}
        </div>
      </div>
      <div className={`${css.grid} ${localCss.scroll}`}>
        {datesToShow?.map((el, i) => (
          <Fragment key={i}>
            {!el.splitDate ? (
              <Input
                name="date"
                type="text"
                custom={localCss.inputDate}
                value={moment(el.date).format('DD.MM.YY ddd') || ''}
                readOnly
              />
            ) : (
              <div className={localCss.inputDate}></div>
            )}
            <Select
              name="shift_external_id"
              value={el.shift_external_id || ''}
              options={shift_types}
              size="x100"
              defaultValue="Day Shift"
              onChange={e => {
                handleChange(el.date, e, el.splitDate);
              }}
              withoutAdaptivePlaceholder
            />
            <IMaskInput
              mask={'00:00'}
              value={(() => {
                if (el?.hours) {
                  if (typeof el.hours === 'number') {
                    const value = el?.hours.toString();
                    if (value.includes('.')) {
                      const [hours, minutes] = value.split('.');

                      return `${+hours < 10 ? '0' + hours : hours}:${Number(
                        (+minutes * 60) / 100,
                      ).toFixed(0)}`;
                    } else if (value.length === 2) {
                      return `${value}:00`;
                    } else return value;
                  } else return el?.hours.toString();
                } else return '';
              })()}
              unmask={true} // true|false|'typed'
              onAccept={(value, mask) => {
                handleChange(
                  el.date,
                  {
                    target: { name: 'hours', value: value },
                  },
                  el.splitDate,
                );
              }}
              onBlur={e => {
                const value = moment(e.target.value, momentFormat).format(
                  momentFormat,
                );
                handleChange(
                  el.date,
                  {
                    target: {
                      name: 'hours',
                      value:
                        value === 'Invalid date'
                          ? ''
                          : value === '00:00'
                          ? '24:00'
                          : value,
                    },
                  },
                  el.splitDate,
                );
              }}
              placeholder="8"
              className={inputCss.input}
            />
            <div>
              <DatePicker
                locale="en"
                className={
                  el?.error?.dateStartError ? css.inputTimeRed : css.inputTime
                }
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="time"
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                placeholderText="07:00"
                onChange={e => {
                  handleStartTimeChange(el.date, e, el.splitDate);
                }}
                selected={(el.start && parseDate(el.start)) || ''}
              />
            </div>
            <div>
              <DatePicker
                locale="en"
                className={
                  el?.error?.dateEndError ? css.inputTimeRed : css.inputTime
                }
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="time"
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                placeholderText="19:00"
                readOnly
                selected={(el.end && parseDate(el.end)) || ''}
              />
            </div>
            <Input
              name="purchase_order"
              value={el.purchase_order || ''}
              size="x100"
              type="text"
              placeholder="Purchase Order"
              onChange={e => {
                handleChange(el.date, e, el.splitDate);
              }}
              withoutAdaptivePlaceholder
              readOnly={placement_purchase_order ? true : false}
            />
            <Input
              name="ward"
              value={el.ward || ''}
              size="x100"
              type="text"
              placeholder="Ward"
              onChange={e => {
                handleChange(el.date, e, el.splitDate);
              }}
              withoutAdaptivePlaceholder
            />
            <div className={css.deleteContainer}>
              {!el.splitDate ? (
                <img
                  className={css.delete}
                  alt="Delete"
                  src={delete_icon}
                  onClick={handleDelete.bind(this, el.date, false)}
                />
              ) : (
                <img
                  className={css.delete}
                  alt="Delete"
                  src={delete_icon}
                  onClick={handleDelete.bind(this, el.date, true)}
                />
              )}
              {!el.splitDate ? (
                <div
                  className={css.splitContainer}
                  onClick={handlePressSplit.bind(this, el.date)}
                >
                  <img className={css.split} alt="Split" src={split_icon} />
                </div>
              ) : (
                <div className={css.splitContainerEmpty} />
              )}
            </div>
          </Fragment>
        ))}
      </div>
      <JobSectionFooter disableConfirm={disableConfirm} />
    </div>
  );
};

export default AdHoc;
