import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import css from './Editor.module.css';

export default function Editor({
                                 myValue,
                                 onChangeValue,
                                 disabled,
                                 formData,
                                 handleImageChange,
                                 files,
                                 handleDownloadFiles,
                                 isAgree,
                                 onChangeIsAgree,
                               }) {
  const [value, setValue] = useState(myValue);

  const handleChangeValue = (event, editor) => {
    const content = editor.getData();

    setValue(content);

    if (onChangeValue) {
      setTimeout(() => {
        onChangeValue(content);
      }, 1000);
    }
  };

  // CKEditor config object
  const editorConfig = {
    fontFamily: {
      options: [
        'default',
        'Ubuntu, Arial, sans-serif',
        'Ubuntu Mono, Courier New, Courier, monospace',
      ],
    },
    items: [
      'undo', 'redo',
      '|', 'heading',
      '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
      '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
      '|', 'link', 'uploadImage', 'blockQuote', 'codeBlock',
      '|', 'alignment',
      '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent',
    ],
    shouldNotGroupWhenFull: true,
  };

  return (
    <div className={css.editorContainer}>
      <div className={css.qq}>
        <div className={`${css.noteArea} ${disabled ? css.fullWidth : ''}`}>
          <CKEditor
            editor={ClassicEditor}
            config={editorConfig}
            data={value}
            onChange={handleChangeValue}
            disabled={disabled}
            onInit={editor => {
              editor.editing.view.change(writer => {
                const viewRoot = editor.editing.view.document.getRoot();
                writer.setAttribute('contentEditable', 'true', viewRoot);
              });

              editor.model.document.on('change:data', () => {
                editor.ui.view.editableElement.style.height = 'auto';
                editor.ui.view.editableElement.style.height = `${editor.ui.view.editableElement.scrollHeight}px`;
              });

              setTimeout(() => {
                editor.ui.view.editableElement.style.height = `${editor.ui.view.editableElement.scrollHeight}px`;
              }, 100);
            }}

          />
        </div>
        {!disabled &&
          <div className={css.textContainer}>
            <p className={css.bulletPoint}>Shift / Job Dates and Times</p>
            <p className={css.bulletPoint}>Wards</p>
            <p className={css.bulletPoint}>PO / BRN numbers</p>
            <p className={css.bulletPoint}>Charge Rate(s), Pay Rate(s), and Agency Fee(s)</p>
            <p className={css.bulletPoint}>Payment Type</p>
            <p className={css.bulletPoint}>NI/Levy (if different) and Include/Exclude (as needed from confirmation)</p>
            <p className={css.bulletPointEmpty}>Additional details (if any)</p>
            <div className={css.checkBoxContainer}>
              <label className={css.checkBoxLabel}>
                <input
                  type='checkbox'
                  className={css.checkBoxInput}
                  checked={isAgree}
                  value={isAgree}
                  onChange={(e) => {
                    onChangeIsAgree(e.target.checked);
                  }} />
                I confirm that all rates are accurate as per client agreed rates / rate card
              </label>
            </div>
          </div>
        }
      </div>
      <div className={css.form11}>
        {!formData.placement_external_id ?
          <input
            type='file'
            onChange={handleImageChange}
            accept='.png, .jpg, .jpeg, .docx'
            className={css.filePicker} multiple />
          :
          <div className={css.filePickerEmpty}></div>
        }
        {files.length > 0 &&
          <div
            onClick={async () => {
              await handleDownloadFiles(null);
            }}
            className={css.downloadAllButton}>
            <p>Download all files</p>
          </div>
        }
      </div>
    </div>
  );
}
