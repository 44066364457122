import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  changeRate,
  addDate,
  setDatesToConfirm,
  setIsConfirmed,
  setSummary, setLevyValue, setLevyEnabled,
} from '../../features/bookingForm/bookingFormSlice';
import { confirmSuccess } from '../../helpers/notyf';
import Button from '../Button/Button';
import Input from '../Input/Input';
import ConfirmPopup from '../Popup/ConfirmPopup';
import css from './JobSection.module.css';
import closeBtn from '../../assets/images/close-button.png';
import Select from '../Select/Select';
import edit_icon from '../../assets/images/edit.png';

const PAYE_PERCENTAGE = process.env.REACT_APP_PAYE_PERCENTAGE;

const PAY_RATES = [
  {
    charge: 0,
    external_id: 797,
    fee: 0,
    hidden: false,
    id: 797,
    name: 'Night Rate',
    new: true,
    rate: 0,
    shift_external_id: 28,
    custom: true,
  },
  {
    charge: 0,
    external_id: 801,
    fee: 0,
    hidden: false,
    id: 801,
    name: 'On Call',
    new: true,
    rate: 0,
    shift_external_id: 39,
    custom: true,
  },
  {
    charge: 0,
    external_id: 49,
    fee: 0,
    hidden: false,
    id: 49,
    name: 'Overtime',
    new: true,
    rate: 0,
    shift_external_id: 43,
    custom: true,
  },
  {
    charge: 0,
    external_id: 800,
    fee: 0,
    hidden: false,
    id: 800,
    name: 'Saturday',
    new: true,
    rate: 0,
    shift_external_id: 40,
    custom: true,
  },
  {
    charge: 0,
    external_id: 822,
    fee: 0,
    hidden: false,
    id: 822,
    name: 'Saturday / Night Rate',
    new: true,
    rate: 0,
    shift_external_id: 44,
    custom: true,
  },
  {
    charge: 0,
    external_id: 798,
    fee: 0,
    hidden: false,
    id: 798,
    name: 'Sunday & Bank Holiday Rate',
    new: true,
    rate: 0,
    shift_external_id: 41,
    custom: true,
  },
];

const JobSectionFooter = ({ disableConfirm }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [customPayRate, setCustomPayRate] = useState('');
  const [isNIInputActive, setIsNIInputActive] = useState(false);

  const levyEnabled = useSelector(state => state.bookingForm.levyEnabled);
  const levyValue = useSelector(state => state.bookingForm.levyValue);

  const [nILevyPercent, setNILevyPercent] = useState(levyValue || 1.138);
  const [isPercentageIncluded, setIsPercentageIncluded] = useState(levyEnabled !== false);
  const formData = useSelector(state => state.bookingForm.formData);

  const { datesToConfirm, adHocDates, isHoliday } = useSelector(
    state => state.bookingForm,
  );
  const start_date = useSelector(
    state => state.bookingForm.formData.job?.start_date,
  );
  const end_date = useSelector(
    state => state.bookingForm.formData.job?.end_date,
  );
  const calendar_data = useSelector(
    state => state.bookingForm.formData.job?.calendar_data,
  );
  const { type, day_shifts, pay_rates, summary } = useSelector(
    state => state.bookingForm.formData.job?.booked_data,
  );
  const pay_type_external_id = useSelector(
    state => state.bookingForm.formData.job?.pay_type_external_id,
  );
  const { shift_types } = useSelector(state => state.referenceBook.bookData);
  const dispatch = useDispatch();

  const rateFormater = toChange => {
    const changedRates = pay_rates.map(rate => {
      if (toChange) {
        let rateToChange = toChange.target;
        rateToChange.value = isNaN(+rateToChange.value[0])
          ? rateToChange.value.slice(1)
          : rateToChange.value;
        if (rate.external_id === +rateToChange.id) {
          if (rateToChange.name === 'charge') {
            return {
              ...rate,
              [rateToChange.name]: rateToChange.value,
              fee:
                +pay_type_external_id !== 799
                  ? +pay_type_external_id === 24
                    ? +(
                      +rateToChange.value -
                      ((+rate.rate / 100) * +PAYE_PERCENTAGE + +rate.rate)
                    )
                      .toString()
                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                    : +(+rateToChange.value - +rate.rate)
                      .toString()
                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  : +rateToChange.value,
            };
          } else if (rateToChange.name === 'rate') {
            return {
              ...rate,
              charge:
                +pay_type_external_id === 799
                  ? +Number(+rateToChange.value * nILevyPercent + +rate.fee).toFixed(2)
                  : +rate.charge,
              [rateToChange.name]: rateToChange.value,
              fee:
                +pay_type_external_id !== 799
                  ? +pay_type_external_id === 24
                    ? +(
                      +rate.charge -
                      ((+rateToChange.value / 100) * +PAYE_PERCENTAGE +
                        +rateToChange.value)
                    )
                      .toString()
                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                    : +(+rate.charge - +rateToChange.value)
                      .toString()
                      .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                  : +rateToChange.value + +rate.fee,
            };
          } else if (rateToChange.name === 'fee') {
            return {
              ...rate,
              charge:
                +pay_type_external_id === 799
                  ? +Number(+rate.rate * nILevyPercent + +rateToChange.value).toFixed(2)
                  : +rate.charge,
              [rateToChange.name]: rateToChange.value,
            };
          }
        }

        return rate;
      } else {
        return {
          ...rate,
          charge:
            +pay_type_external_id === 799
              ? +Number(+rate.rate * nILevyPercent + +rate.fee).toFixed(2)
              : +rate.charge,
          fee:
            +pay_type_external_id !== 799
              ? +pay_type_external_id === 24
                ? +(
                  +rate.charge -
                  ((+rate.rate / 100) * +PAYE_PERCENTAGE + +rate.rate)
                )
                  .toString()
                  .match(/^-?\d+(?:\.\d{0,2})?/)[0]
                : +(+rate.charge - +rate.rate)
                  .toString()
                  .match(/^-?\d+(?:\.\d{0,2})?/)[0]
              : +rate.fee.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0],
        };
      }
    });
    dispatch(changeRate(changedRates));
  };

  useEffect(() => {
    rateFormater();
  }, [pay_type_external_id]);

  const handleConfirm = () => {
    if (type === 'standard') {
      let start = moment()
        .year(new Date(start_date).getFullYear())
        .month(new Date(start_date).getMonth())
        .date(new Date(start_date).getDate());
      let end = moment()
        .year(new Date(end_date).getFullYear())
        .month(new Date(end_date).getMonth())
        .date(new Date(end_date).getDate());
      let range = [];
      for (
        let i = start.clone();
        i.isSameOrBefore(end, 'day');
        i.add(1, 'day')
      ) {
        range.push(i.clone());
      }

      let dates = range.filter(date =>
        day_shifts.find(shift => {
          const hours =
            typeof shift.hours === 'string'
              ? Number(shift.hours?.split(':')?.[0] ?? 0)
              : shift.hours;
          return shift.day_name === date.format('dddd') && hours > 0;
        }),
      );
      //FIXME: some holidays can be couple days long, change .find logic (sameOrBefore -> add(1, day))
      if (!isHoliday)
        dates = dates.filter(
          date =>
            !calendar_data.bank_holiday.dates.find(
              el =>
                moment(el.start).format('DD.MM.YY') === date.format('DD.MM.YY'),
            ),
        );

      dates = dates.map(date => {
        const shift = day_shifts.find(
          shift => shift.day_name === date.format('dddd'),
        );

        const newDate = {
          ...shift,
          date: date,
          start: new Date(
            moment(date).format('YYYY-MM-DD') +
            'T' +
            moment(shift.start_time).format('HH:mm:ss'),
          ),
          end: new Date(
            `${
              +shift.shift_external_id === 28 ||
              +shift.shift_external_id === 44 ||
              +shift.shift_external_id === 39
                ? moment(date).add(1, 'day').format('YYYY-MM-DD')
                : moment(date).format('YYYY-MM-DD')
            }T` + moment(shift.end_time).format('HH:mm:ss'),
          ),
          title: shift_types.find(
            el => +el.external_id === +shift.shift_external_id,
          ).name,
        };

        return newDate;
      });
      dispatch(setDatesToConfirm(dates));
    } else if (type === 'ad_hoc') {
      let toMomentDate = adHocDates.map(hoc => {
        return {
          ...hoc,
          date: moment(hoc.date),
        };
      });
      dispatch(setDatesToConfirm(toMomentDate));
    }
    setIsPopupOpen(true);
  };

  const handleConfirmed = () => {
    dispatch(addDate([]));

    let datesToSet = [];
    let summaryToSet = {};
    let daysToSet = 0;
    let hoursToSet = 0;
    let valueToSet = 0;
    const momentFormat = 'HH:mm';
    if (type === 'standard') {
      datesToSet = datesToConfirm;

      daysToSet = datesToConfirm.length;

      datesToConfirm.map(date => {
        let fee = 0;
        const payRate = pay_rates.find(
          rate => +rate.shift_external_id === +date.shift_external_id,
        );

        fee = payRate?.fee ?? 0;

        let momentFormatHours = moment(date.hours, momentFormat).format('HH');
        if (momentFormatHours === '00' && moment(date.hours, momentFormat).format('mm') === '00') {
          momentFormatHours = '24';
        }
        const hours =
          +momentFormatHours - 0.5 * Math.floor(+momentFormatHours / 6);

        hoursToSet += hours;
        valueToSet += hours * fee;
      });
    }

    if (type === 'ad_hoc') {
      datesToSet = adHocDates;

      daysToSet = adHocDates.length;

      adHocDates.map(date => {
        let fee = 0;
        const payRate = pay_rates.find(
          rate => +rate.shift_external_id === +date.shift_external_id,
        );

        fee = payRate?.fee ?? 0;

        let momentFormatHours = moment(date.hours, momentFormat).format('HH');
        if (momentFormatHours === '00' && moment(date.hours, momentFormat).format('mm') === '00') {
          momentFormatHours = '24';
        }
        const hours =
          +momentFormatHours - 0.5 * Math.floor(+momentFormatHours / 6);

        hoursToSet += hours;
        valueToSet += hours * fee;
      });
    }

    if (type === 'complex') {
      //logic for complex
    }

    summaryToSet.days = daysToSet;
    summaryToSet.hours = hoursToSet;
    summaryToSet.value = valueToSet;

    dispatch(setSummary(summaryToSet));
    dispatch(addDate(datesToSet));
    dispatch(setIsConfirmed(true));
    confirmSuccess();
  };

  const handlePopupClose = () => setIsPopupOpen(false);

  const handleChangeRate = e => {
    if (isNaN(+e.target.value[0])) {
      if (isNaN(+e.target.value.slice(1)) || +e.target.value.slice(1) > 999)
        return;
    } else if (+e.target.value > 999) return;

    const rateToChange = Object.assign({}, e);
    rateFormater(rateToChange);
  };

  const ratesToSort = [...pay_rates];

  const payRates = [
    pay_rates.find(el => +el.shift_external_id === 30),
    ...ratesToSort
      .filter(el => +el.shift_external_id !== 30)
      .filter(el => el.hidden === false)
      .filter(rate => {
        if (rate?.custom) {
          if (type === 'standard') return day_shifts[0];
          else return adHocDates[0];
        }
        if (
          PAY_RATES.findIndex(
            el => el.shift_external_id === rate.shift_external_id,
          ) !== -1
        ) {
          if (type === 'standard') return day_shifts[0];
          else return adHocDates[0];
        }
        if (type === 'standard') {
          return day_shifts.find(
            shift => +shift.shift_external_id === +rate.shift_external_id,
          );
        }
        if (type === 'ad_hoc') {
          return adHocDates?.find(
            shift => +shift.shift_external_id === +rate.shift_external_id,
          );
        }
        //for complex tab
        return console.log('complex');
      })
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }

        return 0;
      }),
  ];

  const handlePayRateChange = e => {
    setCustomPayRate(+e.target.value);
  };

  const handleAddRate = () => {
    const payRate = PAY_RATES.find(el => el.external_id === +customPayRate);

    if (payRate) {
      dispatch(changeRate([...pay_rates, payRate]));
      setCustomPayRate('');
    }
  };

  const checkActiveDeleteRate = rate => {
    if (type === 'standard') {
      return (
        day_shifts.findIndex(
          el => +el.shift_external_id === +rate.shift_external_id,
        ) === -1
      );
    } else {
      return (
        adHocDates.findIndex(
          el => +el.shift_external_id === +rate.shift_external_id,
        ) === -1
      );
    }
  };

  const handleDeleteRate = rate => {
    const rates = pay_rates;

    if (type === 'standard') {
      day_shifts.findIndex(
        el => +el.shift_external_id === +rate.shift_external_id,
      ) === -1 &&
      dispatch(
        changeRate(rates.filter(el => el.external_id !== rate.external_id)),
      );
    } else {
      adHocDates.findIndex(
        el => +el.shift_external_id === +rate.shift_external_id,
      ) === -1 &&
      dispatch(
        changeRate(rates.filter(el => el.external_id !== rate.external_id)),
      );
    }
  };

  const handleMarkerClick = () => {
    setIsNIInputActive(true);
  };

  const changeAllShifts = (percent) => {
    let newPayRates = pay_rates.map((pay) => {
      return {
        ...pay,
        charge: +pay_type_external_id === 799 ? +Number(+pay.rate * (percent ? percent : 1) + +pay.fee).toFixed(2) : pay.charge,
        fee: +pay_type_external_id === 24 ? +Number(+pay.charge - ((percent ? percent : 1) * +pay.rate)).toFixed(2) : pay.fee,
      };
    });
    dispatch(changeRate(newPayRates));
  };

  const handleChangeNI = (e) => {
    const value = e.target.value;
    if (!/^(\d+\.?\d*|\.\d+)$/.test(value) && value !== '') {
      return;
    }
    setNILevyPercent(value);
    changeAllShifts(value);
    dispatch(setLevyValue(parseFloat(value)));
  };

  const handleToggle = () => {
    setIsPercentageIncluded(!isPercentageIncluded);
    changeAllShifts(!isPercentageIncluded ? nILevyPercent : null);
    dispatch(setLevyEnabled(!isPercentageIncluded));
  };

  const formatValue = (value) => {
    const match = value.toString().match(/^-?\d+(?:\.\d{0,2})?/);
    if (match) {
      return match[0];
    }
    return '0'; // Повертаємо '0', якщо регулярний вираз не знайшов жодних відповідностей
  };

  return (
    <>
      {isPopupOpen && (
        <ConfirmPopup onConfirm={handleConfirmed} onClose={handlePopupClose} />
      )}
      <div className={css.footer}>
        {payRates.map(
          rate =>
            rate && (
              <Fragment key={rate.external_id}>
                <div>
                  <span className={css.footerText}>{rate?.name}:</span>
                </div>
                <div>
                  <span className={css.footerText}>Charge</span>
                  <Input
                    id={rate.external_id}
                    value={`£${rate.charge}`}
                    name='charge'
                    type='text'
                    placeholder='£0'
                    size='xsm'
                    onChange={handleChangeRate}
                    readOnly={+pay_type_external_id === 799 && true}
                    withoutAdaptivePlaceholder
                  />
                </div>
                <div>
                  <span className={css.footerText}>Pay Rate</span>
                  <Input
                    id={rate?.external_id}
                    value={`£${rate.rate}`}
                    name='rate'
                    type='text'
                    placeholder='£0'
                    size='xsm'
                    onChange={handleChangeRate}
                    withoutAdaptivePlaceholder
                  />
                </div>
                <div>
                  <span className={css.footerText}>Fee</span>
                  <Input
                    id={rate?.external_id}
                    value={`£${rate.fee}`}
                    name='fee'
                    type='text'
                    placeholder='£0'
                    size='xsm'
                    onChange={handleChangeRate}
                    readOnly={+pay_type_external_id !== 799 && true}
                    withoutAdaptivePlaceholder
                  />
                </div>

                <div>
                  {rate.external_id !== 796 && checkActiveDeleteRate(rate) && (
                    <img
                      onClick={() => handleDeleteRate(rate)}
                      className={css.closeBtn}
                      alt='close_button'
                      src={closeBtn}
                    />
                  )}
                </div>
              </Fragment>
            ),
        )}
      </div>
      {!formData.placement_external_id &&
        <div className={`${css.grid} ${css.mb}`}>
          <div className={css.rateWrapper}>
            <Select
              size='x25'
              value={customPayRate}
              options={PAY_RATES.filter(
                rate =>
                  pay_rates.findIndex(
                    el => el.external_id === rate.external_id,
                  ) === -1,
              )}
              defaultValue='Select Pay Rate'
              onChange={handlePayRateChange}
              disabled={false}
            />

            <div className={css.rateSelectDivider} />

            <Button type='secondary' onClick={handleAddRate} title='Add Rate' />
          </div>
        </div>
      }

      {!formData.placement_external_id &&
        <div className={`${css.grid} ${css.mb}`}>
          <Button
            type={disableConfirm ? 'disabled' : 'secondary'}
            onClick={handleConfirm} title='Confirm'
            disabled={disableConfirm} />
        </div>
      }
      <>
        <h2 className={css.footerTitle}>Summary</h2>
        <div className={css.paySettings}>
          {(+pay_type_external_id === 799 || +pay_type_external_id === 24) &&
            <div className={css.inputContainer}>
              <span className={css.footerText}>NI/Levy %</span>
              <Input
                value={isNIInputActive ? nILevyPercent : nILevyPercent}
                custom={!isNIInputActive && css.customInput}
                readOnly={!isNIInputActive}
                name='ni'
                type='text'
                size='xsm'
                onChange={handleChangeNI}
                withoutAdaptivePlaceholder
              />
              {!formData.placement_external_id &&
                <>
                  {!isNIInputActive &&
                    <img src={edit_icon} alt='Edit Icon' onClick={handleMarkerClick} className={css.editIcon} />
                  }
                </>
              }
            </div>
          }
          {+pay_type_external_id === 799 &&
            <>
              <div className={css.toggleContainer}>
              <span className={css.toggleLabel}>
                Include this percentage on the calculation:
              </span>
                <label className={css.switch}>
                  <input
                    type='checkbox'
                    checked={isPercentageIncluded}
                    onChange={handleToggle}
                    disabled={formData.placement_external_id}
                  />
                  <span className={css.slider}></span>
                </label>
              </div>
            </>
          }
        </div>
        <div className={css.footer2}>
          <div>
            <span className={css.footerText}>Days Booked</span>
            <span className={css.dataTag}>{summary.days}</span>
          </div>
          <div>
            <span className={css.footerText}>Hours Booked</span>
            <span className={css.dataTag}>{summary.hours}</span>
          </div>
          <div>
            <span className={css.footerText}>Estimated value</span>
            <span className={css.dataTag}>
              {`£${+formatValue(summary?.value ?? 0)}`}
            </span>
          </div>
        </div>
      </>
    </>
  );
};

export default JobSectionFooter;
