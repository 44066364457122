import css from './SelectRecruiter.module.css';
import React, { useEffect, useState } from 'react';
import closeButton from '../../../../assets/images/close-button.png';
import sortUp from '../../../../assets/images/sortUp.svg';
import sortDown from '../../../../assets/images/sortDown.svg';

export const SelectRecruiter = ({
                                  formFilters,
                                  onClick,
                                  onGetRecruiterData,
                                  isRecruiter,
                                  className,
                                  onChangeSelectedRecruiter,
                                  onOpenSelect,
                                  isActive,
                                  user,
                                }) => {
  const [filters, setFilters] = useState(formFilters);

  useEffect(() => {
    if (filters.length === 0) {
      setFilters(formFilters);
    }
  }, [formFilters]);

  const changeVisibilityModal = () => {
    onOpenSelect(isActive ? null : 'recruiter');
  };

  const handleCheckboxChange = (index) => {
    if (index === 1 && isRecruiter) {
      const updatedFilters = filters.map((filter, i) => {
        if (filters[index].checked) {
          if (i === 0) {
            onChangeSelectedRecruiter(filter.key, true);
            return { ...filter, checked: true };
          } else {
            onChangeSelectedRecruiter(filter.key, false);
            return { ...filter, checked: false };
          }
        } else {
          onChangeSelectedRecruiter(filter.key, true);
          return { ...filter, checked: true };
        }
      });
      setFilters(updatedFilters);
    } else {
      const updatedFilters = filters.map((filter, i) => {
        if (i === index) {
          onChangeSelectedRecruiter(filter.key, !filter.checked);
          return { ...filter, checked: !filter.checked };
        } else {
          return filter;
        }
      });

      const isCheckedExists = updatedFilters.some(filter => filter.checked);
      if (!isCheckedExists) {
        onChangeSelectedRecruiter(updatedFilters[0].key, true);
        updatedFilters[0] = { ...updatedFilters[0], checked: true };
      }
      setFilters(updatedFilters);
    }
  };

  const onPressClear = (close) => {
    const newFilters = filters.map((filter, i) => {
      if (i === 0) {
        return { ...filter, checked: true };
      } else {
        onChangeSelectedRecruiter(filter.key, false);
        return { ...filter, checked: false };
      }
    });
    setFilters(newFilters);
    onGetRecruiterData([newFilters[0].key], true);
    if (close !== true) {
      changeVisibilityModal();
    }
  };

  const onPressApply = () => {
    const allChecked = filters.every(filter => filter.checked);
    const newFilters = allChecked ? [] : filters
      .map((filter) => {
        if (filter.checked) {
          return filter.key;
        }
      })
      .filter(el => el !== undefined);

    onGetRecruiterData(newFilters, true);
    changeVisibilityModal();
  };

  return (
    <div className={`${css.mainContainer} ${className || {}}`}>
      <div
        className={css.selectContainer}
        onClick={changeVisibilityModal}>
       <span className={css.selectContainerText}>
        Recruitment: {
         (filters || []).filter(item => item.checked).length === 1 && (filters || []).some(item => item.title === 'All' && item.checked)
           ? 'All'
           : (filters || [])
             .filter(item => item.checked)
             .filter(item => item.title !== 'All')
             .map(item => item.title)
             .join(', ')}
        </span>
        <div className={css.crossContainer}>
          {(user?.role_name === 'recruiter' ? (filters || []).filter(item => item.checked).length > 1 : (!((filters || []).filter(item => item.checked).length === 1 && (filters || []).some(item => item.title === 'All' && item.checked)))) ?
            <img
              onClick={(e) => {
                e.stopPropagation();
                onPressClear(true);
              }}
              alt='cross'
              className={css.closeButton}
              src={closeButton}
            />
            :
            <img
              alt='sorting way'
              className={css.filterIcon}
              src={isActive ? sortUp : sortDown}
            />
          }
        </div>
      </div>
      {isActive && (
        <div className={`${css.modalContainer}`} onClick={onClick}>
          <div className={css.modalContent}>
            <div className={css.listContainer}>
              {user?.role_name === 'recruiter' &&
                <div
                  className={css.filterItemContainerFirst}
                  onClick={() => {
                    if (!((filters || []).filter(item => item.checked).length === 1 && filters[0]?.checked)) {
                      handleCheckboxChange(0);
                    }
                  }}>
                  <input
                    type='checkbox'
                    id={`item${22}`}
                    name={`item${243}`}
                    onChange={() => {
                    }}
                    className={css.checkBox}
                    checked={filters[0]?.checked} />
                  <label htmlFor={`item${filters[0].key}`} className={css.itemName}>{filters[0]?.title ?? ''}</label>
                </div>
              }

              {filters.map((item, index) => (
                <div key={`item_div${item?.id ?? index}`}>
                  {index !== 0 &&
                    <div key={item.id} className={css.filterItemContainer} onClick={() => handleCheckboxChange(index)}>
                      <input
                        type='checkbox'
                        id={`item${item.id}`}
                        name={`item_name${item.id}`}
                        className={css.checkBox}
                        onChange={() => {
                        }}
                        checked={item.checked} />
                      <label htmlFor={`item${index}`} className={css.itemName}>{item.title}</label>
                    </div>
                  }
                </div>
              ))}
            </div>
            <div className={css.buttonsContainer}>
              <span onClick={onPressClear}>Clear</span>
              <div className={css.applyButton} onClick={onPressApply}>
                <span>Apply</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
