import React, { useEffect, useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import api from '../../config/api';
import Button from '../Button/Button';
import css from './Header.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setEditedId } from '../../features/bookingForm/bookingFormSlice';

const Header = () => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const editedId = useSelector(state => state.bookingForm.editedId);
  const dispatch = useDispatch();


  useEffect(() => {
    localStorage.getItem('ACCESS_TOKEN') &&
    api.AUTH_ME().then(response => setUser(response));
  }, []);

  const logOutHandler = () => {
    handleResetEditing().then(() => {
      api.LOG_OUT().then(navigate('/login'));
    });
  };

  const handleResetEditing = (async () => {
    if (editedId) {
      await api.CHANGE_EDITING(editedId);
      dispatch(setEditedId(null));
    }
  });

  return (
    <header className={css.header}>
      <nav className={`${css.nav} ${user.role_name && user.role_name !== 'admin' ? css.nav1 : {} }`}>
        <NavLink
          onClick={handleResetEditing}
          to='/list-of-forms'
          className={({ isActive }) => (isActive ? css.active : css.navLink)}
        >
          Booking form
        </NavLink>
        {user.role_name && user.role_name === 'admin' && (
          <NavLink
            onClick={handleResetEditing}
            to='/users'
            className={({ isActive }) => (isActive ? css.active : css.navLink)}
          >
            Users
          </NavLink>
        )}
        <NavLink
          onClick={handleResetEditing}
          to='/availability'
          className={({ isActive }) => (isActive ? css.active : css.navLink)}
        >
          Availability
        </NavLink>
      </nav>
      <div>
        <span className={css.hi}>Hi, {user.name}</span>
        <Button type='accent' onClick={logOutHandler} title='Log out' />
      </div>
    </header>
  );
};

export default Header;
