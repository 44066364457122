import React from 'react';
import Calendar from '../JobSection/Calendar/Calendar';
import closeBtn from '../../assets/images/close-button.png';
import Button from '../Button/Button';
import css from './Popup.module.css';

const ConfirmPopup = ({ onConfirm, onClose }) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <div className={css.popupOverlay}>
      <div className={`${css.wrapper} ${css.x100}`}>
        <h1 className={css.title}>Are you sure?</h1>
        <Calendar confirmation={true} />
        <div className={css.submit}>
          <Button type="secondary" onClick={handleConfirm} title="Confirm" />
        </div>
        <Button type="cancel" onClick={onClose} title="Cancel" />

        <div className={css.closeBtnWrapper}>
          <div className={css.closeBtnArea} onClick={onClose}>
            <img className={css.closeBtn} alt="close_button" src={closeBtn} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
