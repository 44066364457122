import css from './AddNewEventModal.module.css';
import closeButton from '../../../../assets/images/close-button.png';
import sortUp from '../../../../assets/images/sortUp.svg';
import sortDown from '../../../../assets/images/sortDown.svg';
import checkIcon from '../../../../assets/images/checkIcon.png';
import plusBlueIcon from '../../../../assets/images/plusBlueIcon.png';
import trashIcon from '../../../../assets/images/trashIcon.png';
import DatePicker from 'react-datepicker';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Oval } from 'react-loader-spinner';
import { customSuccessNotification } from '../../../../helpers/notyf';
import api from '../../../../config/api';
import { isUndefined } from 'lodash';
import { SendDeleteEmailModal } from '../SendDeleteEmailModal/SendDeleteEmailModal';

const selectRepeatEventData = [
  { id: 'daily', name: 'Daily' },
  { id: 'weekly', name: 'Weekly' },
  { id: 'monthly', name: 'Monthly' },
  { id: 'yearly', name: 'Yearly' },
];

// const selectEndsRepeatData = [
//   { id: 3, name: 'On' },
//   { id: 4, name: 'After' },
// ];

const props = {
  color: 'var(--primary)',
  height: 100,
  width: 110,
  secondaryColor: 'var(--primary)',
};

export const AddNewEventModal = ({
                                   calendarItem,
                                   onChangeVisibility,
                                   index,
                                   applicantId,
                                   onGetCalendarData,
                                   eventDataGlobal,
                                   isEdit,
                                   setGlobalLoading,
                                   isDeleteEvent,
                                   onGetEvenData,
                                   isBooking,
                                   setSendData,
                                   onChangeDeleteModal,
                                 }) => {
  const { eventData, date, timestamp } = calendarItem || {};

  const [repeat, setRepeat] = useState(false);
  const [isOpenEventSelect, setIsOpenEventSelect] = useState(false);
  const [isOpenRepeatSelect, setIsOpenRepeatSelect] = useState(false);
  const [selectedRepeatType, setSelectedRepeatType] = useState('daily');
  const [myEventData, setMyEventData] = useState([]);
  const [myRangeData, setMyRangeData] = useState({});
  const [eventDataLocal, setEventDataLocal] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingSmall, setLoadingSmall] = useState(false);
  const [counterRepeat, setCounterRepeat] = useState(1);
  const [isDelete, setIsDelete] = useState(isDeleteEvent ? isDeleteEvent : false);
  const [daysData, setDaysData] = useState([
    { id: 1, name: 'Mon', checked: false },
    { id: 2, name: 'Tue', checked: false },
    { id: 3, name: 'Wed', checked: false },
    { id: 4, name: 'Thu', checked: false },
    { id: 5, name: 'Fri', checked: false },
    { id: 6, name: 'Sat', checked: false },
    { id: 7, name: 'Sun', checked: false },
  ]);

  const handleChangeDaysSelect = (dayId) => {
    setDaysData(daysData.map(day =>
      day.id === dayId ? { ...day, checked: !day.checked } : day,
    ));
  };

  useEffect(() => {
    let currentDayNumber = moment().day();
    currentDayNumber = currentDayNumber === 0 ? 7 : currentDayNumber;
    handleChangeDaysSelect(currentDayNumber);
  }, []);

  useEffect(() => {
    setEventDataLocal(eventDataGlobal);
    setLoading(false);
  }, [eventDataGlobal]);

  useEffect(() => {
    if (myEventData.length === 0) {
      setRepeat(isDeleteEvent ? false : (eventData?.tab ?? 'ad_hoc') !== 'ad_hoc');
      if ((eventData?.events ?? []).length === 0) {
        const momentDate = timestamp ? moment(timestamp) : moment(date);
        const formattedDate = momentDate.format('YYYY-MM-DD HH:mm:ss');
        setMyEventData([{
          'id': null,
          'name': 'Ad Hoc 1',
          'type_id': null,
          'start_time': formattedDate,
          'end_time_range': null,
          'end_time': formattedDate,
          'note': null,
          'placement_id': null,
          'applicant': null,
          'client': null,
          'can_edit': false,
          'can_delete': true,
          'all_day': true,
        }]);
        setMyRangeData({
          'date_from': formattedDate,
          'date_to': formattedDate,
          'type_id': null,
          'start_time': '09:00',
          'end_time': '10:00',
          'note': '',
          'all_day': false,
          'repeat_by': 'daily',
          'repeat_every': 1,
          'repeat_days': [],
        });
      } else {
        const newEvents = [];
        (eventData?.events ?? []).map((event, index) => {
          event.name = `Ad Hoc ${index + 1}`;
          event.openNotes = !!event.note;
          newEvents.push(event);
          if (index === 0 && (eventData?.tab ?? 'ad_hoc') === 'ad_hoc') {
            setMyRangeData({
              'name': `Ad Hoc ${index + 1}`,
              'note': '',
              'all_day': false,
              'repeat_by': 'daily',
              'repeat_every': 1,
              'repeat_days': [],
              date_from: event.start_time,
              date_to: event.end_time,
              type_id: event.type_id,
              start_time: formatStringTime(event.start_time),
              end_time: formatStringTime(event.end_time),
              openNotes: !!event.note,
            });
          }
        });
        setMyEventData(newEvents);
      }
    }
  }, [eventData, eventDataLocal]);

  const repeatTypeObject = selectRepeatEventData.find(eventType => eventType.id === selectedRepeatType);
  const repeatTypeName = repeatTypeObject ? repeatTypeObject.name : '';

  const handleAddAdHoc = async () => {
    const newAdHoc = {
      'id': null,
      'name': `Ad Hoc ${myEventData.length + 1}`,
      'type_id': null,
      'start_time': null,
      'end_time': myEventData.length > 0 ? myEventData[0].end_time : null,
      'note': null,
      'placement_id': null,
      'applicant': null,
      'client': null,
      'can_edit': false,
      'can_delete': true,
      'all_day': true,
      openNotes: false,
    };
    setMyEventData([...myEventData, newAdHoc]);
  };

  const handleRemoveAdHoc = (nameToRemove) => {
    const updatedEvents = myEventData.filter(event => event.name !== nameToRemove);
    setMyEventData(updatedEvents);
  };

  const formatStringDate = (strDate) => {
    const formattedDate = moment(strDate, 'YYYY-MM-DD HH:mm:ss');
    return new Date(formattedDate.format());
  };

  const formatStringTime = (strDate) => {
    return moment(strDate).format('HH:mm');
  };

  const handleChangeEventType = (name, eventType) => {
    let switchValue;
    const newAdHocData = myEventData.map(adHoc => {
      if (adHoc.name === name) {
        if (eventType.acronym === 'A') {
          switchValue = true;
        } else switchValue = eventType.acronym === 'NA';
        const [datePart] = adHoc.start_time.split(' ');
        const newStartTime = `${datePart} ${eventType.start_time}`;
        const [datePart1] = adHoc.end_time.split(' ');
        const newEndTime = `${datePart1} ${eventType.end_time}`;
        return { ...adHoc, type_id: eventType.id, start_time: newStartTime, end_time: newEndTime };
      }
      return adHoc;
    });
    setMyEventData(newAdHocData);
    setIsOpenEventSelect(undefined);
    toggleSwitch(name, switchValue, newAdHocData);
  };

  const handleChangeStartDate = async (date, name) => {
    setLoadingSmall(true);
    const momentDate = moment(new Date(date));
    const formattedDate = momentDate.format('YYYY-MM-DD HH:mm:ss');
    const formattedDateRequest = momentDate.format('YYYY-MM-DD');
    const data = await onGetEvenData(applicantId, { date: formattedDateRequest });
    const newAdHocData = myEventData.map(adHoc => {
      if (adHoc.name === name) {
        if (data.events.length > 0) {
          data.events[0].name = name;
          data.events[0].openNotes = !!data.events[0].note;
          return data.events[0];
        } else {
          return {
            'id': null,
            'name': name,
            'type_id': null,
            'start_time': formattedDate,
            'end_time': adHoc.end_time,
            'note': null,
            'placement_id': null,
            'applicant': null,
            'client': null,
            'can_edit': false,
            'can_delete': true,
            'all_day': true,
            openNotes: false,
          };
        }
      }
      return adHoc;
    });
    setLoadingSmall(false);
    setMyEventData(newAdHocData);
  };

  const toggleSwitch = (name, switchValue, newAdHocData1) => {
    const newAdHocData = (newAdHocData1 || myEventData).map(adHoc => {
      if (adHoc.name === name) {
        return { ...adHoc, all_day: isUndefined(switchValue) ? !adHoc.all_day : switchValue };
      }
      return adHoc;
    });
    setMyEventData(newAdHocData);
  };

  const handleChangeStartTime = (time, name) => {
    const newAdHocData = myEventData.map(adHoc => {
      if (adHoc.name === name) {
        const [datePart] = adHoc.start_time.split(' ');
        const newStartTime = `${datePart} ${time}`;
        return { ...adHoc, start_time: newStartTime };
      }
      return adHoc;
    });
    setMyEventData(newAdHocData);
  };

  const handleChangeEndTime = (time, name) => {
    const newAdHocData = myEventData.map(adHoc => {
      if (adHoc.name === name) {
        const [datePart] = (adHoc.end_time ? adHoc.end_time : adHoc.start_time).split(' ');
        const newStartTime = `${datePart} ${time}`;
        return { ...adHoc, end_time: newStartTime };
      }
      return adHoc;
    });
    setMyEventData(newAdHocData);
  };

  const handleChangeNote = (e, name) => {
    const newAdHocData = myEventData.map(adHoc => {
      if (adHoc.name === name) {
        return { ...adHoc, note: e.target.value };
      }
      return adHoc;
    });
    setMyEventData(newAdHocData);
  };

  const handleOpenNotes = (name) => {
    const newAdHocData = myEventData.map(adHoc => {
      if (adHoc.name === name) {
        return { ...adHoc, openNotes: adHoc.openNotes ? !adHoc.openNotes : true };
      }
      return adHoc;
    });
    setMyEventData(newAdHocData);
  };

  const handleCheckDeleteBooking = async (adhoc) => {
    if (adhoc) {
      let newEventData = [];
      let myEventDataCopy = JSON.parse(JSON.stringify(myEventData));
      myEventDataCopy.map((event) => {
        newEventData.push({
          type_id: 31,
          event_id: event.id,
        });
      });
      const data = {
        'mail_data': {
          'microsoft_access_token': localStorage.getItem('MICROSOFT_ACCESS_TOKEN'),
        },
        'tab': 'ad_hoc',
        'applicant_id': applicantId,
        'events': newEventData,
      };
      const result = await api.CHECK_BEFORE_CANCEL(data);
      if (result.status !== 'FAILED') {
        setSendData({...data, mail_data: result?.data?.mail_data ?? {}});
        onChangeDeleteModal(true);
        onChangeVisibility();
      }
    } else {
      const repeat_days = [];
      daysData.map((day) => {
        if (day.checked) {
          repeat_days.push(day.id);
        }
      });
      const dataForSaving = {
        tab: 'range',
        applicant_id: applicantId,
        mail_data: {
          'microsoft_access_token': localStorage.getItem('MICROSOFT_ACCESS_TOKEN'),
        },
        range_data: {
          repeat_by: selectedRepeatType,
          repeat_days: selectedRepeatType === 'weekly' ? repeat_days : [],
          repeat_every: counterRepeat,
          date_from: moment(myRangeData.date_from).format('YYYY-MM-DD'),
          date_to: moment(myRangeData.date_to).format('YYYY-MM-DD'),
        },
      };
      const result = await api.CHECK_BEFORE_CANCEL(dataForSaving);
      if (result.status !== 'FAILED') {
        setSendData({...dataForSaving, mail_data: result?.data?.mail_data ?? {}});
        onChangeDeleteModal(true);
        onChangeVisibility();
        setGlobalLoading(false);
      }
      setGlobalLoading(false);
    }
  };

  const handleSaveEvent = async () => {
    setGlobalLoading(true);
    let isCreate = true;
    let newEventData = [];
    let myEventDataCopy = JSON.parse(JSON.stringify(myEventData));
    myEventDataCopy.map((event) => {
      if (event.id) {
        isCreate = false;
      }
      const { start_time, end_time } = event;
      const date = start_time.split(' ')[0];
      const startTimeFormatted = start_time.split(' ')[1].slice(0, 5);
      const endTimeFormatted = end_time.split(' ')[1].slice(0, 5);

      event.date = date;
      event.start_time = startTimeFormatted;
      event.end_time = endTimeFormatted;
      event.event_id = event.id;
      newEventData.push(event);
    });
    const dataForSaving = {
      tab: 'ad_hoc',
      applicant_id: applicantId,
      action: isEdit ? (isDelete ? 'delete' : 'update') : 'create',
      events: newEventData,
    };

    try {
      const result = await api.SAVE_EVENT(dataForSaving);
      const { created, ignored, updated, deleted } = result.data;
      if (created > 0) {
        customSuccessNotification(`Successfully created ${created} event${created > 1 ? 's' : ''}`);
      }
      if (ignored > 0) {
        customSuccessNotification(`${ignored} event${ignored > 1 ? 's' : ''} ignored`);
      }
      if (updated > 0) {
        customSuccessNotification(`Successfully updated ${updated} event${updated > 1 ? 's' : ''}`);
      }
      if (updated > 0) {
        customSuccessNotification(`Successfully updated ${updated} event${updated > 1 ? 's' : ''}`);
      }
      if (deleted > 0) {
        customSuccessNotification(`Successfully deleted ${deleted} event${deleted > 1 ? 's' : ''}`);
      }
      onGetCalendarData(true);
      onChangeVisibility();
    } catch (e) {
      console.log(e);
      setGlobalLoading(false);
    }
  };

  const handleSaveRepeatEvent = async () => {
    const repeat_days = [];
    daysData.map((day) => {
      if (day.checked) {
        repeat_days.push(day.id);
      }
    });
    const dataForSaving = {
      tab: 'range',
      applicant_id: applicantId,
      action: isEdit ? (isDelete ? 'delete' : 'update') : 'create',
      range_data: {
        ...myRangeData,
        repeat_by: selectedRepeatType,
        repeat_days: selectedRepeatType === 'weekly' ? repeat_days : [],
        repeat_every: counterRepeat,
        date_from: moment(myRangeData.date_from).format('YYYY-MM-DD'),
        date_to: moment(myRangeData.date_to).format('YYYY-MM-DD'),
      },
    };
    try {
      const result = await api.SAVE_EVENT(dataForSaving);
      const { created, ignored, updated, deleted } = result.data;
      onGetCalendarData(true);
      onChangeVisibility();
      if (created > 0) {
        customSuccessNotification(`Successfully created ${created} event${created > 1 ? 's' : ''}`);
      }
      if (ignored > 0) {
        customSuccessNotification(`${ignored} event${ignored > 1 ? 's' : ''} ignored`);
      }
      if (updated > 0) {
        customSuccessNotification(`Successfully updated ${updated} event${updated > 1 ? 's' : ''}`);
      }
      if (deleted > 0) {
        customSuccessNotification(`Successfully deleted ${deleted} event${deleted > 1 ? 's' : ''}`);
      }
    } catch (e) {
      console.log(e);
      setGlobalLoading(false);
    }
  };

  const selectedEventType = ((calendarItem?.eventData?.event_types && calendarItem.eventData.event_types.length > 0)
    ? calendarItem.eventData.event_types
    : (eventDataLocal?.event_types ?? [])).find(eventType => eventType.id === myRangeData.type_id);
  const eventTypes = (calendarItem?.eventData?.event_types && calendarItem.eventData.event_types.length > 0)
    ? calendarItem.eventData.event_types
    : (eventDataLocal?.event_types ?? []);

  const shouldDisableButton = myEventData.some(adHoc =>
    (isEdit && !adHoc.can_edit) || (isDelete && !adHoc.can_delete) || (!isDelete && !isEdit && adHoc.id),
  );

  return (
    <div
      style={{ left: index < 18 ? '52px' : '-428px' }}
      className={css.modalContent}>
      <div className={css.headerContainer}>
        <span>{isBooking ? 'Delete Booked Event' : !isEdit ? 'New Event' : 'Edit Event'}</span>
        <img src={closeButton} alt={'closeButton'} className={css.closeButton} onClick={onChangeVisibility} />
      </div>
      <div className={css.contentContainer}>
        <div className={css.buttonsContainer}>
          <div
            className={css.buttonSwitchContainer}
            style={{ backgroundColor: !repeat ? '#0CADD3' : 'white' }}
            onClick={() => {
              setRepeat(false);
            }}>
            <span className={css.buttonSwitchText} style={{ color: !repeat ? '#ffffff' : '#2E2E30' }}>Ad Hoc</span>
          </div>
          <div
            className={css.buttonSwitchContainer}
            style={{ backgroundColor: !repeat ? 'white' : '#0CADD3' }}
            onClick={() => {
              setRepeat(true);
            }}>
            <span className={css.buttonSwitchText} style={{ color: !repeat ? '#2E2E30' : '#FFFFFF' }}>Repeat</span>
          </div>
        </div>
        {!loading ?
          <>
            {repeat &&
              <div className={css.rangeContainer}>
                <>
                  <span className={css.typeDateText}>Type & Date</span>
                  <div className={css.firsRowContainer}>
                    <div style={{ marginTop: '16px' }}>
                      <span className={css.titleText}>From</span>
                      <div className={css.dateSelectContainer}>
                        <DatePicker
                          locale='en'
                          calendarClassName={css.calendar}
                          className={css.inputDate}
                          dateFormat='dd/MM/yyyy'
                          onChange={(e) => {
                            const momentDate = moment(new Date(e));
                            const formattedDate = momentDate.format('YYYY-MM-DD HH:mm:ss');
                            setMyRangeData(prevState => ({
                              ...prevState,
                              date_from: formattedDate,
                            }));
                          }}
                          selected={(myRangeData.date_from && new Date(formatStringDate(myRangeData.date_from))) || ''}
                        />
                      </div>
                    </div>
                    <div style={{ marginTop: '16px', marginLeft: '26px' }}>
                      <span className={css.titleText}>To</span>
                      <div className={css.dateSelectContainer} style={{ width: '206px' }}>
                        <DatePicker
                          locale='en'
                          className={css.inputDate}
                          calendarClassName={css.calendar}
                          dateFormat='dd/MM/yyyy'
                          onChange={(e) => {
                            const momentDate = moment(new Date(e));
                            const formattedDate = momentDate.format('YYYY-MM-DD HH:mm:ss');
                            setMyRangeData(prevState => ({
                              ...prevState,
                              date_to: formattedDate,
                            }));
                          }}
                          selected={(myRangeData.date_to && new Date(formatStringDate(myRangeData.date_to))) || ''}
                        />
                      </div>
                    </div>
                  </div>
                  {isEdit && !isBooking &&
                    <div className={css.deleteContainer}>
                      <span>Delete Events</span>
                      <label className={css.switch}>
                        <input
                          type='checkbox'
                          checked={isDelete}
                          onChange={() => {
                            setIsDelete(!isDelete);
                          }} />
                        <span className={`${css.slider} ${css.round}`}></span>
                      </label>
                    </div>
                  }
                  {!isDelete &&
                    <>
                      <div className={css.eventTypeContainer}>
                        <span className={css.titleText}>Event Type</span>
                        <div
                          className={css.eventTypeSelectValue}
                          onClick={() => {
                            if (isOpenEventSelect === myRangeData.name) {
                              setIsOpenEventSelect(null);
                            } else {
                              setIsOpenEventSelect(myRangeData.name);
                            }
                          }}>
                          {selectedEventType ?
                            <>
                              <div
                                className={css.squareColorContainer}
                                style={{ backgroundColor: selectedEventType?.color ?? 'white' }} />
                              <span className={css.selectedEvent}>{selectedEventType?.name ?? ''}</span>
                            </>
                            :
                            <span className={css.selectedEventPlaceholder}>Select Type</span>
                          }
                          <img
                            alt='sorting way'
                            className={css.selectIcon}
                            src={isOpenEventSelect ? sortUp : sortDown}
                          />
                        </div>
                        {isOpenEventSelect === myRangeData.name &&
                          <div className={css.selectItemsContainer}>
                            {eventTypes.map((eventType) => (
                              <div
                                className={css.eventValuesContainer}
                                onClick={() => {
                                  let switchValue;
                                  if (eventType.acronym === 'A') {
                                    switchValue = true;
                                  } else switchValue = eventType.acronym === 'NA';
                                  const startTime = moment(eventType.start_time, 'HH:mm');
                                  const endTime = moment(eventType.end_time, 'HH:mm');
                                  const newStartTime = `${startTime.format('HH:mm')}`;
                                  const newEndTime = `${endTime.format('HH:mm')}`;
                                  setMyRangeData(prevState => ({
                                    ...prevState,
                                    type_id: eventType.id,
                                    start_time: newStartTime,
                                    end_time: newEndTime,
                                    all_day: switchValue,
                                  }));
                                  setIsOpenEventSelect(null);
                                }}>
                                <div
                                  className={css.squareColorContainer}
                                  style={{ backgroundColor: eventType.color }} />
                                <span
                                  style={{ color: selectedEventType?.id === eventType.id ? '#0CADD3' : '#2E2E30' }}
                                  className={css.selectItemText1}>{eventType?.name ?? ''} ({eventType.acronym})</span>
                                {selectedEventType?.id === eventType.id &&
                                  <img src={checkIcon} alt={'checkIcon'} className={css.checkIcon} />
                                }
                              </div>
                            ))}
                          </div>
                        }
                      </div>
                      <div className={css.allDayContainer}>
                        <span className={css.atimeText}>Time</span>
                        <span className={css.allDayText}>All day</span>
                        {isOpenEventSelect !== myRangeData.name &&
                          <label className={css.switch}>
                            <input
                              type='checkbox'
                              checked={myRangeData.all_day}
                              onChange={() => {
                                setMyRangeData(prevState => ({
                                  ...prevState,
                                  all_day: !prevState.all_day,
                                }));
                              }} />
                            <span className={`${css.slider} ${css.round}`}></span>
                          </label>
                        }
                      </div>
                      <div className={css.selectTimeContainer}>
                        <div className={css.timeValueContainer}>
                          <div className={css.timeSelectContainerRange}>
                            <span className={css.titleTextTime}>Start</span>
                            <input
                              disabled={myRangeData.all_day}
                              type='time'
                              className={css.timeSelect}
                              value={myRangeData.start_time}
                              onChange={(e) => {
                                setMyRangeData(prevState => ({
                                  ...prevState,
                                  start_time: e.target.value,
                                }));
                              }}
                            />
                          </div>
                          <div className={css.timeSelectContainerRange} style={{ marginLeft: '10px' }}>
                            <span className={css.titleTextTime}>End</span>
                            <input
                              type='time'
                              disabled={myRangeData.all_day}
                              className={css.timeSelect}
                              value={myRangeData.end_time}
                              onChange={(e) => {
                                setMyRangeData(prevState => ({
                                  ...prevState,
                                  end_time: e.target.value,
                                }));
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={css.noteContainer}>
                        <div className={css.rowContainer}>
                          <div
                            className={css.openNoteContainer}
                            onClick={() => {
                              setMyRangeData(prevState => ({
                                ...prevState,
                                openNotes: !prevState.openNotes,
                              }));
                            }}>
                            {!myRangeData.openNotes ?
                              <span className={css.openNoteText}>+</span>
                              :
                              <span className={css.openNoteText}>-</span>
                            }
                          </div>
                          {!myRangeData.openNotes ?
                            <span className={css.typeDateText}>Add Note</span>
                            :
                            <span className={css.typeDateText}>Remove Note</span>
                          }
                        </div>
                        {myRangeData.openNotes &&
                          <textarea
                            maxLength={512}
                            onChange={(e) => {
                              setMyRangeData(prevState => ({
                                ...prevState,
                                note: e.target.value,
                              }));
                            }}
                            value={myRangeData.note}
                            placeholder='Enter note (Optional)'
                            className={css.notesInput} />
                        }
                      </div>
                    </>
                  }
                  <div className={css.dividerSmall} />
                  <div className={css.repeatText}>
                    <span>Repeat</span>
                  </div>

                  <div className={css.firsRowContainer}>
                    <div>
                      <span className={css.titleText}>Repeat by</span>
                      <div className={css.eventTypeContainer1}
                           style={{ zIndex: isOpenEventSelect === myRangeData.name ? '-1' : 0 }}>
                        <div
                          className={css.eventTypeSelectValue}
                          onClick={() => {
                            setIsOpenRepeatSelect(!isOpenRepeatSelect);
                          }}>
                          <span className={css.selectedEvent}>{repeatTypeName}</span>
                          <img
                            alt='sorting way'
                            className={css.selectIcon}
                            src={isOpenRepeatSelect ? sortUp : sortDown}
                          />
                        </div>
                        {isOpenRepeatSelect &&
                          <div className={css.selectItemsContainer1}>
                            {selectRepeatEventData.map((eventType) => (
                              <div
                                className={css.eventValuesContainer}
                                onClick={() => {
                                  setSelectedRepeatType(eventType.id);
                                  setIsOpenRepeatSelect(false);
                                }}>
                                  <span
                                    style={{ color: selectedRepeatType === eventType.id ? '#0CADD3' : 'black' }}
                                    className={css.selectItemText}>
                                    {eventType?.name ?? ''}
                                  </span>
                                {selectedRepeatType === eventType.id &&
                                  <img src={checkIcon} alt={'checkIcon'} className={css.checkIcon} />
                                }
                              </div>
                            ))}
                          </div>
                        }
                      </div>
                    </div>
                    <div className={css.repeatEveryContainer}>
                      <span className={css.titleText}>Repeat Every</span>
                      <div className={css.repeatEveryItem}>
                        <div className={css.repeatTextContainer}>
                          <input
                            type='number'
                            min='1'
                            max='1000'
                            value={counterRepeat}
                            className={css.inputText}
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10);
                              if (value < 1) {
                                setCounterRepeat(1);
                              } else if (value > 999) {
                                setCounterRepeat(999);
                              } else if (isNaN(value)) {
                                setCounterRepeat(1);
                              } else {
                                setCounterRepeat(value);
                              }
                            }} />
                          <span className={css.repeatName}>
                                  {(repeatTypeName === 'Daily' ? 'Day' : repeatTypeName).replace('ly', '')}
                          </span>
                        </div>
                        <div className={css.rowContainer}>
                          <div
                            className={`${css.openNoteContainer} ${counterRepeat === 999 ? css.disableButton : {}}}`}
                            onClick={() => {
                              if (counterRepeat !== 999) {
                                setCounterRepeat(counterRepeat + 1);
                              }
                            }}>
                            <span className={css.openNoteText}>+</span>
                          </div>
                          <div
                            className={`${css.openNoteContainer} ${counterRepeat === 1 ? css.disableButton : {}}`}
                            onClick={() => {
                              if (counterRepeat !== 1) {
                                setCounterRepeat(counterRepeat - 1);
                              }
                            }}>
                            <span className={css.openNoteText}>-</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectedRepeatType === 'weekly' &&
                    <div style={{ marginTop: '16px' }}>
                      <div>
                        <span className={css.titleText}>Repeat by</span>
                      </div>
                      <div className={css.daysContainer}>
                        {daysData.map((day) => (
                          <div
                            className={`${css.dayItem} ${day.checked ? css.dayItemChecked : ''}`}
                            onClick={() => {
                              handleChangeDaysSelect(day.id);
                            }}>
                            <span
                              className={`${css.dayText} ${day.checked ? css.dayTextChecked : ''}`}>{day.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  }
                  {/*<div className={css.firsRowContainer} style={{ marginTop: '16px', zIndex: 1 }}>*/}
                  {/*  <div>*/}
                  {/*    <span className={css.titleText}>Ends</span>*/}
                  {/*    <div className={css.eventTypeContainer1}>*/}
                  {/*      <div*/}
                  {/*        className={css.eventTypeSelectValue}*/}
                  {/*        onClick={() => {*/}
                  {/*          setIsOpenEndsSelect(!isOpenEndsSelect);*/}
                  {/*        }}>*/}
                  {/*        <span className={css.selectedEvent}>{endsTypeName}</span>*/}
                  {/*        <img*/}
                  {/*          alt='sorting way'*/}
                  {/*          className={css.selectIcon}*/}
                  {/*          src={isOpenEndsSelect ? sortUp : sortDown}*/}
                  {/*        />*/}
                  {/*      </div>*/}
                  {/*      {isOpenEndsSelect &&*/}
                  {/*        <div className={css.selectItemsContainerEnds}>*/}
                  {/*          {selectEndsRepeatData.map((eventType) => (*/}
                  {/*            <div*/}
                  {/*              className={css.eventValuesContainer}*/}
                  {/*              onClick={() => {*/}
                  {/*                setSelectedEndsType(eventType.id);*/}
                  {/*                setIsOpenEndsSelect(false);*/}
                  {/*              }}>*/}
                  {/*                <span*/}
                  {/*                  style={{ color: selectedEndsType === eventType.id ? '#0CADD3' : 'black' }}*/}
                  {/*                  className={css.selectItemText}>*/}
                  {/*                  {eventType?.name ?? ''}*/}
                  {/*                </span>*/}
                  {/*              {selectedEndsType === eventType.id &&*/}
                  {/*                <img src={checkIcon} alt={'checkIcon'} className={css.checkIcon} />*/}
                  {/*              }*/}
                  {/*            </div>*/}
                  {/*          ))}*/}
                  {/*        </div>*/}
                  {/*      }*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*{endsTypeName === 'After' &&*/}
                  {/*  <div className={css.repeatEveryContainer} style={{ marginTop: '16px' }}>*/}
                  {/*    <div className={css.repeatEveryItem}>*/}
                  {/*      <div className={css.repeatTextContainer}>*/}
                  {/*        <input*/}
                  {/*          type='number'*/}
                  {/*          min='1'*/}
                  {/*          max='1000'*/}
                  {/*          value={counterOccurrence}*/}
                  {/*          className={css.inputText}*/}
                  {/*          onChange={(e) => {*/}
                  {/*            const value = parseInt(e.target.value, 10);*/}
                  {/*            if (value < 1) {*/}
                  {/*              setCounterOccurrence(1);*/}
                  {/*            } else if (value > 999) {*/}
                  {/*              setCounterOccurrence(999);*/}
                  {/*            } else if (isNaN(value)) {*/}
                  {/*              setCounterOccurrence(1);*/}
                  {/*            } else {*/}
                  {/*              setCounterOccurrence(value);*/}
                  {/*            }*/}
                  {/*          }} />*/}
                  {/*        <span className={css.repeatName}>Occurrences</span>*/}
                  {/*      </div>*/}
                  {/*      <div className={css.rowContainer}>*/}
                  {/*        <div*/}
                  {/*          className={`${css.openNoteContainer} ${counterOccurrence === 999 ? css.disableButton : {}}`}*/}
                  {/*          onClick={() => {*/}
                  {/*            if (counterOccurrence !== 999) {*/}
                  {/*              setCounterOccurrence(counterOccurrence + 1);*/}
                  {/*            }*/}
                  {/*          }}>*/}
                  {/*          <span className={css.openNoteText}>+</span>*/}
                  {/*        </div>*/}
                  {/*        <div*/}
                  {/*          className={`${css.openNoteContainer} ${counterOccurrence === 1 ? css.disableButton : {}}`}*/}
                  {/*          onClick={() => {*/}
                  {/*            if (counterOccurrence !== 1) {*/}
                  {/*              setCounterOccurrence(counterOccurrence - 1);*/}
                  {/*            }*/}
                  {/*          }}>*/}
                  {/*          <span className={css.openNoteText}>-</span>*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*}*/}
                  {/*{endsTypeName === 'On' &&*/}
                  {/*  */}
                  {/*}*/}
                  {/*</div>*/}
                  <div className={css.divider} />
                  {!isEdit ?
                    <div className={css.buttonContainer} onClick={() => {
                      setGlobalLoading(true);
                      setTimeout(async () => {
                        await handleSaveRepeatEvent();
                      }, 1000);
                    }}>
                      <span className={css.buttonText}>Insert Availability</span>
                    </div>
                    :
                    <div className={css.buttonsContainer1}>
                      <div className={css.cancelButton} onClick={onChangeVisibility}>
                        <span style={{ color: '#0E7193' }}>Cancel</span>
                      </div>
                      <div className={css.saveButton} onClick={() => {
                        setGlobalLoading(true);
                        setTimeout(async () => {
                          if (isBooking) {
                            await handleCheckDeleteBooking(false);
                          } else {
                            await handleSaveRepeatEvent();
                          }
                        }, 1000);
                      }}>
                        <span style={{ color: 'white' }}>{!isBooking ? 'Update' : 'Delete'}</span>
                      </div>
                    </div>
                  }
                </>
              </div>
            }
            {!repeat &&
              <div className={css.adHocMainContainer}>
                {loadingSmall &&
                  <div className={css.loaderOverlay}>
                    <Oval {...props} height={50} width={50} />
                  </div>
                }
                {isEdit && !isBooking &&
                  <div className={css.deleteContainer} style={{ marginBottom: '16px', marginTop: 0 }}>
                    <span>Delete Events</span>
                    <label className={css.switch}>
                      <input
                        type='checkbox'
                        checked={isDelete}
                        onChange={() => {
                          setIsDelete(!isDelete);
                        }} />
                      <span className={`${css.slider} ${css.round}`}></span>
                    </label>
                  </div>
                }
                {myEventData.map((adHoc, indexAd) => {
                  const selectedEventType = ((calendarItem?.eventData?.event_types && calendarItem.eventData.event_types.length > 0)
                    ? calendarItem.eventData.event_types
                    : (eventDataLocal?.event_types ?? [])).find(eventType => eventType.id === adHoc.type_id);
                  const eventTypes = (calendarItem?.eventData?.event_types && calendarItem.eventData.event_types.length > 0)
                    ? calendarItem.eventData.event_types
                    : (eventDataLocal?.event_types ?? []);

                  return (
                    <div className={css.adHocContainer}>
                      <span className={css.adHocName}>{adHoc?.name ?? ''}</span>
                      {indexAd !== 0 &&
                        <div className={css.trashIconContainer} onClick={() => {
                          handleRemoveAdHoc(adHoc.name);
                        }}>
                          <img src={trashIcon} alt={'trashIcon'} className={css.trashIcon} />
                        </div>
                      }
                      <span className={css.typeDateText}>Type & Date</span>
                      <div className={css.firsRowContainer1}>
                        <div style={{ marginTop: '16px', marginRight: '10px' }}>
                          <span className={css.titleText}>Date</span>
                          <div
                            className={css.dateSelectContainer}
                            style={{ width: isBooking ? '395px' : '169px' }}>
                            <DatePicker
                              locale='en'
                              style={{ border: ((isEdit && !adHoc.can_edit) || (isDelete && !adHoc.can_delete)) ? '1px solid #FF5151' : ((!isDelete && !isEdit && adHoc.id) ? '1px solid #E7E7E8' : '1px solid #FF5151') }}
                              className={`${isBooking ? css.inputDateFull : ((((isEdit && !adHoc.can_edit) || (isDelete && !adHoc.can_delete))) ? css.inputDateRed : (!isDelete && !isEdit && adHoc.id) ? css.inputDateRed : css.inputDate)}`}
                              dateFormat='dd/MM/yyyy'
                              onChange={(e) => {
                                handleChangeStartDate(e, adHoc.name);
                              }}
                              selected={(adHoc.start_time && new Date(formatStringDate(adHoc.start_time))) || ''}
                            />
                          </div>
                          {!isBooking &&
                            <>
                              {(((isEdit && !adHoc.can_edit) || (isDelete && !adHoc.can_delete)) || (!isDelete && !isEdit && adHoc.id)) &&
                                <span className={css.titleText} style={{ color: '#FF5151' }}>Choose another date</span>
                              }
                            </>
                          }
                        </div>
                        {!isBooking &&
                          <div className={css.eventTypeContainer}>
                            <span className={css.titleText}>Event Type</span>
                            <div
                              style={{ opacity: (isDelete || (!isDelete && !isEdit && adHoc.id)) ? '0.5' : '1' }}
                              className={css.eventTypeSelectValue}
                              onClick={() => {
                                if ((isDelete || (!isDelete && !isEdit && adHoc.id))) {
                                } else {
                                  if (isOpenEventSelect === adHoc.name) {
                                    setIsOpenEventSelect(null);
                                  } else {
                                    setIsOpenEventSelect(adHoc.name);
                                  }
                                }
                              }}>
                              {selectedEventType ?
                                <>
                                  <div
                                    className={css.squareColorContainer}
                                    style={{ backgroundColor: selectedEventType?.color ?? 'white' }} />
                                  <span className={css.selectedEvent}>{selectedEventType?.name ?? ''}</span>
                                </>
                                :
                                <span className={css.selectedEventPlaceholder}>Select Type</span>
                              }
                              <img
                                alt='sorting way'
                                className={css.selectIcon}
                                src={isOpenEventSelect ? sortUp : sortDown}
                              />
                            </div>
                            {isOpenEventSelect === adHoc.name &&
                              <div className={css.selectItemsContainer}>
                                {eventTypes.map((eventType) => (
                                  <div
                                    className={css.eventValuesContainer}
                                    onClick={() => {
                                      handleChangeEventType(adHoc.name, eventType);
                                    }}>
                                    <div
                                      className={css.squareColorContainer}
                                      style={{ backgroundColor: eventType.color }} />
                                    <span
                                      style={{ color: selectedEventType?.id === eventType.id ? '#0CADD3' : '#2E2E30' }}
                                      className={css.selectItemText1}>{eventType?.name ?? ''} ({eventType.acronym})</span>
                                    {selectedEventType?.id === eventType.id &&
                                      <img src={checkIcon} alt={'checkIcon'} className={css.checkIcon} />
                                    }
                                  </div>
                                ))}
                              </div>
                            }
                          </div>
                        }
                      </div>
                      {!isBooking &&
                        <div className={css.allDayContainer} style={{
                          zIndex: '0',
                          opacity: (isDelete || (!isDelete && !isEdit && adHoc.id)) ? '0.5' : '1',
                        }}>
                          <span className={css.atimeText}>Time</span>
                          <span className={css.allDayText}>All day</span>
                          <label className={css.switch}>
                            <input
                              type='checkbox'
                              checked={adHoc.all_day}
                              onChange={() => {
                                if ((isDelete || (!isDelete && !isEdit && adHoc.id))) {
                                } else {
                                  toggleSwitch(adHoc.name);
                                }
                              }} />
                            <span className={`${css.slider} ${css.round}`}></span>
                          </label>
                        </div>
                      }
                      {!isBooking &&
                        <div className={css.selectTimeContainer}
                             style={{ opacity: (isDelete || (!isDelete && !isEdit && adHoc.id)) ? '0.5' : '1' }}>
                          <div className={css.timeValueContainer}>
                            <div className={css.timeSelectContainerRange}>
                              <span className={css.titleTextTime}>Start</span>
                              <input
                                disabled={adHoc.all_day || (isDelete || (!isDelete && !isEdit && adHoc.id))}
                                type='time'
                                className={css.timeSelect}
                                value={formatStringTime(adHoc.start_time)}
                                onChange={(e) => {
                                  handleChangeStartTime(e.target.value, adHoc.name);
                                }}
                              />
                            </div>
                            <div className={css.timeSelectContainerRange} style={{ marginLeft: '10px' }}>
                              <span className={css.titleTextTime}>End</span>
                              <input
                                type='time'
                                disabled={adHoc.all_day || (isDelete || (!isDelete && !isEdit && adHoc.id))}
                                className={css.timeSelect}
                                value={formatStringTime(adHoc.end_time)}
                                onChange={(e) => {
                                  handleChangeEndTime(e.target.value, adHoc.name);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      }
                      <div className={css.noteContainer}>
                        {!isDelete &&
                          <div className={css.rowContainer}>
                            <div
                              className={css.openNoteContainer}
                              onClick={() => {
                                handleOpenNotes(adHoc.name);
                              }}>
                              {!adHoc.openNotes ?
                                <span className={css.openNoteText}>+</span>
                                :
                                <span className={css.openNoteText}>-</span>
                              }
                            </div>
                            {!adHoc.openNotes ?
                              <span className={css.typeDateText}>Note</span>
                              :
                              <span className={css.typeDateText}>Note</span>
                            }
                          </div>
                        }
                        {adHoc.openNotes &&
                          <textarea
                            disabled={isDelete}
                            maxLength={512}
                            onChange={(e) => {
                              handleChangeNote(e, adHoc.name);
                            }}
                            value={adHoc.note}
                            placeholder='Enter note (Optional)'
                            className={css.notesInputAdHoc} />
                        }
                      </div>
                    </div>
                  );
                })}
                <div className={css.rowContainer} onClick={handleAddAdHoc}>
                  <img src={plusBlueIcon} className={css.plusIcon} alt={'plusBlueIcon'} />
                  <span className={css.addDateText}>Add Date</span>
                </div>
                <div className={css.divider} />
                {!isEdit ?
                  <div
                    className={`${shouldDisableButton ? css.saveButtonDisabled : css.buttonContainer}`}
                    onClick={() => {
                      if (!shouldDisableButton) {
                        handleSaveEvent();
                      }
                    }}>
                    <span className={css.buttonText}>Insert Availability</span>
                  </div>
                  :
                  <div className={css.buttonsContainer1}>
                    <div className={css.cancelButton} onClick={onChangeVisibility}>
                      <span style={{ color: '#0E7193' }}>Cancel</span>
                    </div>
                    <div
                      className={`${isBooking ? css.saveButton : shouldDisableButton ? css.saveButtonDisabled1 : css.saveButton}`}
                      onClick={async () => {
                        if (isBooking) {
                          await handleCheckDeleteBooking(true);
                        } else {
                          if (!shouldDisableButton) {
                            handleSaveEvent();
                          }
                        }
                      }}>
                      <span style={{ color: 'white' }}>{!isBooking ? 'Update' : 'Delete'}</span>
                    </div>
                  </div>
                }
              </div>
            }
          </>
          :
          <div className={css.loaderContainer}>
            <div className={css.loader}>
              <Oval {...props} height={50} width={50} />
            </div>
          </div>
        }
      </div>
    </div>
  );
};
