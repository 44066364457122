import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {
  addShift,
  setIsHoliday,
  changeRate,
} from '../../../features/bookingForm/bookingFormSlice';
import Input from '../../Input/Input';
import Select from '../../Select/Select';
import Calendar from '../Calendar/Calendar';
import JobSectionFooter from '../JobSectionFooter';
import css from './Standard.module.css';
import inputCss from '../../Input/Input.module.css';
import { IMaskInput } from 'react-imask';

const momentFormat = 'HH:mm';

const Standard = () => {
  const { day_shifts, pay_rates } = useSelector(
    state => state.bookingForm.formData.job?.booked_data,
  );

  const placement_purchase_order = useSelector(
    state => state.bookingForm.formData.job?.placement_purchase_order,
  );
  const { shift_types, overtime_rates } = useSelector(
    state => state.referenceBook.bookData,
  );
  const { isHoliday } = useSelector(state => state.bookingForm);
  const dispatch = useDispatch();

  const options = [
    {
      key: 'all',
      title: 'Copy Monday data for all week',
    },
    {
      key: 'work',
      title: 'Copy Monday data for workdays',
    },
  ];

  const handleCheckbox = () => dispatch(setIsHoliday(!isHoliday));

  const handleChange = (day, e) => {
    if (e.target.name === 'hours') {
      if (e.target.value === '') {
      } else if (+e.target.value <= 0) return;
    }

    let changedShifts = [...day_shifts];

    changedShifts = changedShifts.map(shift => {
      if (shift.day_name === day) {
        if (e.target.name === 'hours' && shift.start_time) {
          if (!e.target.value)
            return { ...shift, [e.target.name]: e.target.value };
          const getHours = moment(e.target.value, momentFormat).get('h');
          const getMinutes = moment(e.target.value, momentFormat).format('m');

          let endTime = moment(shift.start_time)
            .add(+getHours, 'h')
            .add(+getMinutes, 'm')
            .toDate();

          // if (
          //   moment(shift.start_time).toDate().getDate() !== endTime.getDate()
          // ) {
          //   endTime = moment(endTime)
          //     .subtract(1, 'd')
          //     .set('h', 23)
          //     .set('m', 59)
          //     .toDate();
          // }

          return {
            ...shift,
            [e.target.name]: e.target.value,
            end_time:
              typeof shift.end_time === 'string' ? shift.end_time : endTime,
            end: typeof shift.end_time === 'string' ? shift.end_time : endTime,
          };
        } else if (e.target.name === 'shift_external_id') {
          const startTime =
            +e.target.value === 28 || +e.target.value === 44
              ? '19:00:00'
              : +e.target.value === 39
              ? '00:00:00'
              : '07:00:00';
          const endTime =
            +e.target.value === 28 || +e.target.value === 44
              ? '07:00:00'
              : +e.target.value === 39
              ? '00:00:00'
              : '19:00:00';
          const hours = +e.target.value === 39 ? '24:00' : '12:00';

          return {
            ...shift,
            [e.target.name]: e.target.value,
            start_time: new Date(
              moment().format('YYYY-MM-DD') + 'T' + startTime,
            ),
            start: new Date(moment().format('YYYY-MM-DD') + 'T' + startTime),
            end_time: new Date(moment().format('YYYY-MM-DD') + 'T' + endTime),
            end: new Date(moment().format('YYYY-MM-DD') + 'T' + endTime),
            hours: hours,
          };
        } else {
          return { ...shift, [e.target.name]: e.target.value };
        }
      }

      return shift;
    });

    if (e.target.name === 'shift_external_id') {
      let newRates = [];

      if (!pay_rates.find(el => +el.shift_external_id === +e.target.value)) {
        const shiftType = shift_types.find(
          el => el.external_id === +e.target.value,
        );
        const overtimeRate = overtime_rates.find(
          el => el.shift_external_id === +e.target.value,
        );

        const currentDay = day_shifts.find(el => el.day_name === day);

        const currentDays =
          changedShifts?.filter(
            el => +el.shift_external_id === +currentDay?.shift_external_id,
          ) ?? [];

        newRates = [
          ...pay_rates,
          {
            external_id: shiftType.overtime_rate_external_id,
            shift_external_id: shiftType.external_id,
            name: overtimeRate.name,
            charge: 0,
            rate: 0,
            fee: 0,
            hidden: false,
            new: true,
          },
        ].filter(el =>
          +el.shift_external_id !== +currentDay.shift_external_id
            ? true
            : +el.shift_external_id === 30 || currentDays.length >= 1,
        );
      } else if (
        pay_rates.find(el => +el.shift_external_id === +e.target.value)
      ) {
        const currentDay = day_shifts.find(el => el.day_name === day);

        const currentDays =
          changedShifts?.filter(
            el => +el.shift_external_id === +currentDay?.shift_external_id,
          ) ?? [];

        newRates = [
          ...pay_rates
            .map(el => {
              if (+el.shift_external_id === +e.target.value) {
                return { ...el, hidden: false };
              }
              return el;
            })
            .filter(el =>
              +el.shift_external_id !== +currentDay.shift_external_id
                ? true
                : +el.shift_external_id === 30 || currentDays.length >= 1,
            ),
        ];
      }

      dispatch(changeRate(newRates));
    }

    dispatch(addShift(changedShifts));
  };

  const handleStartTimeChange = (day, value) => {
    const changedShifts = day_shifts.map(shift => {
      if (shift.day_name === day) {
        if (!value) return { ...shift, start_time: value };
        if (shift.hours) {
          const getHours = moment(shift.hours, momentFormat).get('h');
          const getMinutes = moment(shift.hours, momentFormat).format('m');

          let endTime = moment(value)
            .add(+getHours, 'h')
            .add(+getMinutes, 'm')
            .toDate();

          return {
            ...shift,
            start_time: value,
            end_time: endTime,
            start: value,
            end: endTime,
          };
        } else {
          return { ...shift, start_time: value };
        }
      }

      return shift;
    });
    dispatch(addShift(changedShifts));
  };

  const handleDuplicate = e => {
    if (e.target.value === 'all') {
      const duplicateShift = day_shifts.find(el => el.day_name === 'Monday');
      const duplicatedShifts = day_shifts.map(shift => {
        return {
          ...shift,
          shift_external_id: duplicateShift.shift_external_id,
          hours: duplicateShift.hours,
          start_time: duplicateShift.start_time,
          end_time: duplicateShift.end_time,
          start: duplicateShift.start_time,
          end: duplicateShift.end_time,
          purchase_order: duplicateShift.purchase_order,
        };
      });
      dispatch(addShift(duplicatedShifts));

      const newRates = [
        ...pay_rates.filter(
          el =>
            +el.shift_external_id === 30 ||
            +el.shift_external_id === +duplicateShift.shift_external_id,
        ),
      ];

      dispatch(changeRate(newRates));

      return;
    }

    if (e.target.value === 'work') {
      const duplicateShift = day_shifts.find(el => el.day_name === 'Monday');
      const duplicatedShifts = day_shifts.map(shift => {
        if (shift.day_name !== 'Saturday' && shift.day_name !== 'Sunday') {
          return {
            ...shift,
            shift_external_id: duplicateShift.shift_external_id,
            hours: duplicateShift.hours,
            start_time: duplicateShift.start_time,
            end_time: duplicateShift.end_time,
            start: duplicateShift.start_time,
            end: duplicateShift.end_time,
            purchase_order: duplicateShift.purchase_order,
          };
        }
        return shift;
      });
      dispatch(addShift(duplicatedShifts));

      const weekendDates = duplicatedShifts.filter(
        el => el.day_name === 'Saturday' || el.day_name === 'Sunday',
      );

      const newRates = [
        ...pay_rates.filter(el =>
          weekendDates.find(
            date => +date.shift_external_id === +el.shift_external_id,
          )
            ? true
            : +el.shift_external_id === 30 ||
              +el.shift_external_id === +duplicateShift.shift_external_id,
        ),
      ];

      dispatch(changeRate(newRates));

      return;
    }
  };

  const handleClear = (day, e) => {
    const changedShifts = day_shifts.map(shift => {
      if (shift.day_name === day) {
        return {
          ...shift,
          date: null,
          shift_external_id: 30,
          hours: 0,
          start_time: null,
          end_time: null,
          start: null,
          end: null,
          purchase_order: null,
        };
      }

      return shift;
    });

    const currentDay = day_shifts.find(el => el.day_name === day);

    const currentDays =
      day_shifts?.filter(
        el => +el.shift_external_id === +currentDay?.shift_external_id,
      ) ?? [];

    if (currentDays.length === 1) {
      const newRates = [
        ...pay_rates.filter(
          el =>
            +el.shift_external_id === 30 ||
            +el.shift_external_id !== +currentDay.shift_external_id,
        ),
      ];

      dispatch(changeRate(newRates));
    }

    dispatch(addShift(changedShifts));
  };

  const parseDate = date => {
    if (typeof date !== 'string') return new Date(date);

    return new Date(
      new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000,
    );
  };

  return (
    <div className={css.main}>
      <Calendar />
      <div className={css.checkboxWrapper}>
        <div className={css.checkboxContainer}>
          <div className={css.color1}></div>
          <span className={css.checkboxText}>Applicant Avaliable</span>
        </div>
        <div className={css.checkboxContainer}>
          <div className={css.color5}></div>
          <span className={css.checkboxText}>Applicant Allocated</span>
        </div>
        <div className={css.checkboxContainer}>
          <input
            className={css.checkbox}
            type="checkbox"
            checked={isHoliday}
            onChange={handleCheckbox}
          />
          <div className={css.color2}></div>
          <span className={css.checkboxText}>Bank Holiday</span>
        </div>
        <div className={css.checkboxContainer}>
          <div className={css.color3}></div>
          <span className={css.checkboxText}>Job Requirement</span>
        </div>
        <div className={css.checkboxContainer}>
          <div className={css.color4}></div>
          <span className={css.checkboxText}>Applicant Not Avaliable</span>
        </div>
      </div>
      <div className={css.grid}>
        <span className={css.header}></span>
        <span className={css.header}>Shifts</span>
        <span className={css.header}>Hours</span>
        <span className={css.header}>Start Time</span>
        <span className={css.header}>End Time</span>
        <span className={css.header}>Purchase Order</span>
        <span className={css.header}>Ward</span>
        <Select
          value=""
          size="duplicate"
          options={options}
          onChange={handleDuplicate}
        />
        {day_shifts?.map(el => (
          <Fragment key={el.day_name}>
            <span className={css.title}>{el.day_name}</span>
            <Select
              name="shift_external_id"
              value={el.shift_external_id || ''}
              options={shift_types}
              size="x100"
              defaultValue="Day Shift"
              onChange={handleChange.bind(this, el.day_name)}
              withoutAdaptivePlaceholder
            />
            {/*<Input
              name="hours"
              value={el.hours || ''}
              size="x100"
              type="number"
              placeholder="8"
              onChange={handleChange.bind(this, el.day_name)}
              withoutAdaptivePlaceholder
            />*/}
            <IMaskInput
              mask={'00:00'}
              value={el?.hours ? el?.hours.toString() : ''}
              unmask={true} // true|false|'typed'
              onAccept={(value, mask) => {
                handleChange(el.day_name, {
                  target: { name: 'hours', value: value },
                });
              }}
              onBlur={e => {
                const value = moment(e.target.value, momentFormat).format(
                  momentFormat,
                );
                handleChange(el.day_name, {
                  target: {
                    name: 'hours',
                    value:
                      value === 'Invalid date'
                        ? ''
                        : value === '00:00'
                        ? '24:00'
                        : value,
                  },
                });
              }}
              placeholder="8"
              className={inputCss.input}
            />
            <div>
              <DatePicker
                locale="en"
                className={css.inputTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="time"
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                placeholderText={
                  +el.shift_external_id === 28 || +el.shift_external_id === 44
                    ? '19:00'
                    : +el.shift_external_id === 39
                    ? '00:00'
                    : '07:00'
                }
                onChange={handleStartTimeChange.bind(this, el.day_name)}
                selected={(el.start_time && parseDate(el.start_time)) || ''}
              />
            </div>
            <div>
              <DatePicker
                locale="en"
                className={css.inputTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="time"
                timeFormat="HH:mm"
                dateFormat="HH:mm"
                placeholderText={
                  +el.shift_external_id === 28 || +el.shift_external_id === 44
                    ? '07:00'
                    : +el.shift_external_id === 39
                    ? '00:00'
                    : '19:00'
                }
                readOnly
                selected={(el.end_time && parseDate(el.end_time)) || ''}
              />
            </div>
            <Input
              name="purchase_order"
              value={el.purchase_order || ''}
              size="x100"
              type="text"
              placeholder="Purchase Order"
              onChange={handleChange.bind(this, el.day_name)}
              withoutAdaptivePlaceholder
              readOnly={placement_purchase_order ? true : false}
            />
            <Input
              name="ward"
              value={el.ward || ''}
              size="x100"
              type="text"
              placeholder="Ward"
              onChange={handleChange.bind(this, el.day_name)}
              withoutAdaptivePlaceholder
            />
            <span
              className={css.title}
              onClick={handleClear.bind(this, el.day_name)}
            >
              Clear
            </span>
          </Fragment>
        ))}
      </div>
      <JobSectionFooter />
    </div>
  );
};

export default Standard;
