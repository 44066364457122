import css from './SendDeleteEmailModal.module.css';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';
import React, { useEffect, useState } from 'react';
import closeButton from '../../../../assets/images/close-button.png';
import { Oval } from 'react-loader-spinner';

const props = {
  color: 'var(--primary)',
  height: 100,
  width: 110,
  secondaryColor: 'var(--primary)',
};
export const SendDeleteEmailModal = ({ calendarData, onCloseModal, setSendData, sendData, onSendEmail }) => {
  const [toEmail, setToEmail] = useState(sendData.mail_data?.emails?.[0] ?? '');
  const [subject, setSubject] = useState(sendData.mail_data?.subject ?? '');
  const [body, setBody] = useState(sendData.mail_data?.body ?? '');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSendData({
      ...sendData, mail_data: {
        microsoft_access_token: sendData?.mail_data?.microsoft_access_token ?? '',
        subject,
        emails: [toEmail],
        body,
      },
    });
    setLoading(false);
  }, [toEmail, subject, body]);

  return (
    <div className={css.mainModalContainer}>
      <div className={css.modalContentContainer}>

        <div className={css.headerContainer}>
          <span className={css.headerText}>Send Email</span>
          <img
            src={closeButton}
            alt={'closeButton'}
            className={css.closeButton}
            onClick={onCloseModal}
          />
        </div>
        <div className={css.dataContainer}>
          <Input
            size='x100'
            value={toEmail}
            type='text'
            placeholder='To Email'
            required={true}
            onChange={(e) => setToEmail(e.target.value)}
          />
          <div className={css.inputContainer}>
            <Input
              size='x100'
              value={subject}
              type='text'
              placeholder='Subject'
              required={true}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className={css.bodyContainer}>
            <p className={css.bodyTitle}>Body</p>
            <textarea
              className={css.textArea}
              value={body}
              onChange={(event) => setBody(event.target.value)}
            />
          </div>
          <div className={css.buttonRowContainer}>
            <Button
              type='cancel'
              onClick={onCloseModal}
              title='Cancel'
            />
            <Button
              type='secondary'
              onClick={async () => {
                setLoading(true);
                await onSendEmail();
                setLoading(false);
              }}
              disabled={loading}
              title='Continue'
            />
          </div>
        </div>
      </div>
      {loading &&
        <div className={css.loader}>
          <Oval {...props} height={50} width={50} />
        </div>
      }
    </div>
  );
};
