import React, { useEffect, useRef, useState } from 'react';
import css from './AvailabilityFormPage.module.css';
import Input from '../../components/Input/Input';
import arrowRight from '../../assets/images/arrowRight.png';
import arrowLeft from '../../assets/images/arrowLeft.png';
import arrowDown from '../../assets/images/arrowDown.png';
import noDataIcon from '../../assets/images/noDataIcon.png';
import usersIcon from '../../assets/images/usersIcon.png';
import starInactive from '../../assets/images/starInactive.png';
import starActive from '../../assets/images/starActive.png';
import api from '../../config/api';
import { SelectRecruiter } from './components/SelectRecruiter/SelectRecruiter';
import { Oval } from 'react-loader-spinner';
import { ApplicantDetail } from './components/ApplicantDetail/ApplicantDetail';
import { DetailCalendarView } from './components/DetailCalendarView/DetailCalendarView';
import moment from 'moment';
import { CalendarDataItem } from './components/CalendarDataItem/CalendarDataItem';
import sortUp from '../../assets/images/sortUp.svg';
import sortDown from '../../assets/images/sortDown.svg';
import { MultiSelect } from '../../components/MultiSelect/MultiSelect';
import { debounce } from 'lodash';
import { DeleteEventModal } from './components/DeleteEventModal/DeleteEventModal';
import { customSuccessNotification } from '../../helpers/notyf';
import { PeriodSelect } from './components/PeriodSelect/PeriodSelect';
import Select from '../../components/Select/Select';
import { SendDeleteEmailModal } from './components/SendDeleteEmailModal/SendDeleteEmailModal';
import { InsertBookingRequest } from './components/InsertBookingRequest/InsertBookingRequest';

const props = {
  color: 'var(--primary)',
  height: 100,
  width: 110,
  secondaryColor: 'var(--primary)',
};

const props1 = {
  color: 'gray',
  height: 100,
  width: 110,
  secondaryColor: 'white',
};

const AvailabilityFormPage = () => {
  const [dates, setDates] = useState([]);
  const [months, setMonths] = useState([]);
  const [recruitersSelect, setRecruitersSelect] = useState([]);
  const [sectorsSelect, setSectorsSelect] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);
  const [specialtiesSelect, setSpecialtiesSelect] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState([]);
  const [gradesSelect, setGradesSelect] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [applicantStatusesSelect, setApplicantStatusesSelect] = useState([]);
  const [selectedApplicantStatuses, setSelectedApplicantStatuses] = useState([]);
  const [positionsSelect, setPositionsSelect] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [complianceStatusSelect, setComplianceStatusSelect] = useState([]);
  const [selectedComplianceStatus, setSelectedComplianceStatus] = useState([]);
  const [trustSelect, setTrustSelect] = useState([]);
  const [selectedTrust, setSelectedTrust] = useState(null);
  const [hospitalsSelect, setHospitalsSelect] = useState([]);
  const [selectedHospitals, setSelectedHospitals] = useState([]);

  const [selectedRecruiter, setSelectedRecruiter] = useState(['all']);
  const [pageApplicants, setPageApplicants] = useState(1);
  const [applicantsData, setApplicantsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingGlobal, setLoadingGlobal] = useState(false);
  const [applicantDetailLoaderId, setApplicantDetailLoaderId] = useState(null);
  const [isRecruiter, setIsRecruiter] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [calendarData, setCalendarData] = useState(null);
  const [loadingDates, setLoadingDates] = useState(false);
  const [currentDisplayedYear, setCurrentDisplayedYear] = useState(moment().utc().startOf('quarter'));
  const [openedModalIndex, setOpenedModalIndex] = useState(null);
  const [isFiltersOpened, setIsFiltersOpened] = useState(false);
  const [selectFiltersCount, setSelectFiltersCount] = useState(0);
  const [searchApplicant, setSearchApplicant] = useState('');
  const [activeSelect, setActiveSelect] = useState(null);
  const [shouldGetData, setShouldGetData] = useState(false);
  const [shouldCleanData, setShouldCleanData] = useState(false);
  const [getDataBySearch, setGetDataBySearch] = useState(false);
  const [eventData, setEventData] = useState(null);
  const [user, setUser] = useState({});
  const [currentQuarterStart, setCurrentQuarterStart] = useState(moment().utc().startOf('quarter'));
  const [currentQuarterEnd, setCurrentQuarterEnd] = useState(currentQuarterStart.clone().endOf('quarter'));
  const [isOpenGlobalDelete, setIsOpenGlobalDelete] = useState(false);
  const [openGlobalDeleteId, setOpenGlobalDeleteId] = useState(null);
  const [openGlobalDeleteApplicantId, setOpenGlobalDeleteApplicantId] = useState(null);
  const [shouldGetInit, setShouldGetInit] = useState(false);
  const [isExternalData, setIsExternalData] = useState(false);
  const [selectedAvailabilityType, setSelectedAvailabilityType] = useState(null);
  const [selectedAvailabilityPeriod, setSelectedAvailabilityPeriod] = useState(7);
  const [openGlobalDeleteEdit, setOpenGlobalDeleteEdit] = useState(null);
  const [trustFilterType, setTrustFilterType] = useState('history');
  const [preferenceTrustSelect, setPreferenceTrustSelect] = useState([]);
  const [combinedTrustSelect, setCombinedTrustSelect] = useState([]);
  const [getRecruiterDataState, setGetRecruiterDataState] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [isShowSendEmailModal, setIsShowSendEmailModal] = useState(false);
  const [sendData, setSendData] = useState({});
  const [isShowInsertBooking, setIsShowInsertBooking] = useState(false);
  const [applicantDetailId, setApplicantDetailId] = useState(null);
  const options = [{ key: false, title: 'Cache' }, { key: true, title: 'Live' }];

  const debounceGetDataRef = useRef(
    debounce(() => {
      window.scrollTo({
        top: 0,
        left: 0,
      });
      setGetDataBySearch(true);
    }, 700),
  );

  useEffect(() => {
    if (getDataBySearch) {
      handleGetDataByFilters();
      setGetDataBySearch(false);
    }
  }, [getDataBySearch]);

  const handleSearchApplicant = (e) => {
    setSearchApplicant(e.target.value);
    setSelectedSector([]);
    setSelectedSpecialty([]);
    setSelectedGrade([]);
    setSelectedComplianceStatus([]);
    setSelectedApplicantStatuses([]);
    setSelectedPositions([]);
    setSelectedTrust(null);
    setSelectedHospitals([]);
    debounceGetDataRef.current(e.target.value);
  };

  function getMonthData(startOfMonth) {
    let dates = [];
    let date = startOfMonth.clone();
    while (date.day() !== 1) {
      date.subtract(1, 'days');
    }
    for (let i = 0; i < 35; i++) {
      dates.push({
        date: date.clone(),
        isInCurrentMonth: date.month() === startOfMonth.month(),
      });
      date.add(1, 'days');
    }
    return dates;
  }

  const getYearCalendar = async (applicantId, next, updatedApplicantData) => {
    setLoadingDates(true);
    let newYear;
    if (next === true) {
      newYear = currentDisplayedYear + 1;
    } else if (next === false) {
      newYear = currentDisplayedYear - 1;
    } else {
      newYear = new Date().getFullYear();
    }

    setCurrentDisplayedYear(newYear);

    const startOfYear = moment().utc().year(newYear).startOf('year');
    const endOfYear = moment().utc().year(newYear).endOf('year');
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const yearCalendar = { year: newYear, months: {} };

    months.forEach((month, index) => {
      const daysInMonth = new Date(newYear, index + 1, 0).getDate();
      const firstDayOfMonthDate = new Date(newYear, index, 1);
      const firstDayOfMonth = moment(firstDayOfMonthDate).day();
      const monthDays = Array(37).fill({ date: '-' });
      const dashesCount = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
      for (let day = 1; day <= daysInMonth; day++) {
        const indexForDay = dashesCount + day - 1;
        monthDays[indexForDay] = {
          date: moment(new Date(newYear, index, day)),
        };
      }
      yearCalendar.months[month] = monthDays;
    });

    const calendar = await api.GET_CALENDAR_DATA(formatDate(startOfYear), formatDate(endOfYear), applicantId, isExternalData);
    combineYearDates(calendar.data[applicantId], applicantId, yearCalendar, updatedApplicantData);
  };

  async function generateQuarterCalendar(applicantId, next, updatedApplicantData, useCurrentQuarter) {
    setLoadingDates(true);
    let startOfQuarterVal, endOfQuarterVal;

    if (useCurrentQuarter) {
      startOfQuarterVal = currentQuarterStart.clone();
      endOfQuarterVal = currentQuarterEnd.clone();
    } else {
      if (next === true) {
        startOfQuarterVal = currentQuarterStart.clone().endOf('quarter').add(1, 'days').startOf('quarter');
      } else if (next === false) {
        startOfQuarterVal = currentQuarterStart.clone().subtract(1, 'day').startOf('quarter');
      } else {
        startOfQuarterVal = moment().utc().startOf('quarter');
      }
      endOfQuarterVal = startOfQuarterVal.clone().endOf('quarter');
    }
    setCurrentQuarterStart(startOfQuarterVal);
    setCurrentQuarterEnd(endOfQuarterVal);

    let currentMonth = startOfQuarterVal.clone();
    let quarterCalendar = [];

    while (currentMonth.isBefore(endOfQuarterVal)) {
      quarterCalendar.push({
        monthName: currentMonth.format('MMMM YYYY'),
        dates: getMonthData(currentMonth),
      });
      currentMonth.add(1, 'month');
    }

    await getQuarterCalendarData(formatDate(startOfQuarterVal), formatDate(endOfQuarterVal), applicantId, quarterCalendar, next, updatedApplicantData);
    setLoadingDates(false);
  }

  const getQuarterCalendarData = async (startOfQuarter, endOfQuarter, applicantId, quarterCalendar, next, updatedApplicantData) => {
    const calendar = await api.GET_CALENDAR_DATA(startOfQuarter, endOfQuarter, applicantId, isExternalData);
    await combineQuarterDates(calendar.data[applicantId], applicantId, quarterCalendar, next, updatedApplicantData);
    setLoadingDates(false);
  };

  const combineQuarterDates = async (calendarDates, applicantId, quarterCalendar, next, updatedApplicantData) => {
    if (!Array.isArray(calendarDates)) {
      console.error('calendarDates is not an array:', calendarDates);
      return [];
    }
    const newCalendarDates = quarterCalendar.map(monthItem => {
      const updatedDates = monthItem.dates.map(dateItem => {
        const dateMoment = formatDate(moment(dateItem.date));

        const matchingDateIndex = calendarDates.findIndex(d =>
          d.date === dateMoment);

        if (matchingDateIndex !== -1) {
          const matchingDate = calendarDates[matchingDateIndex];
          return { ...dateItem, ...matchingDate };
        } else {
          return dateItem;
        }
      });

      return {
        ...monthItem,
        dates: updatedDates,
      };
    });

    const newApplicantsData = (updatedApplicantData || applicantsData).items.map(item => {
      const newItem = { ...item, applicants: { ...item.applicants, items: [...item.applicants.items] } };
      newItem.applicants.items = newItem.applicants.items.map(applicant => {
        if (applicant.id === applicantId) {
          return { ...applicant, quarterData: newCalendarDates, openDetail: true };
        }
        return { ...applicant };
      });

      return { ...newItem };
    });
    setApplicantsData({ ...(updatedApplicantData || applicantsData), items: newApplicantsData });
    if (next === undefined) {
      await getYearCalendar(applicantId, undefined, {
        ...(updatedApplicantData || applicantsData),
        items: newApplicantsData,
      });
    } else {
      setLoadingDates(false);
    }
  };

  const combineYearDates = (calendarDates, applicantId, yearCalendar, updatedApplicantData) => {
    if (!Array.isArray(calendarDates)) {
      console.error('calendarDates is not an array:', calendarDates);
      return [];
    }
    let remainingCalendarDates = [...calendarDates];

    const newCalendarDates = Object.entries(yearCalendar.months).map(([monthName, days]) => {
      const updatedDates = days.map(dateItem => {
        if (dateItem.date === '-') return dateItem;
        const dateStr = formatDate(dateItem.date);
        const matchingDateIndex = remainingCalendarDates.findIndex(d => d.date === dateStr);
        if (matchingDateIndex !== -1) {
          const matchingDate = remainingCalendarDates[matchingDateIndex];
          remainingCalendarDates = remainingCalendarDates.filter((_, i) => i !== matchingDateIndex);
          return { ...dateItem, ...matchingDate };
        } else {
          return dateItem;
        }
      });
      return { monthName, dates: updatedDates };
    });

    const newApplicantsData = (updatedApplicantData || applicantsData).items.map(item => {
      const newItem = { ...item, applicants: { ...item.applicants } };
      newItem.applicants.items = newItem.applicants.items.map(applicant => {
        if (applicant.id === applicantId) {
          return { ...applicant, yearData: { year: yearCalendar.year, items: newCalendarDates }, openDetail: true };
        }
        return applicant;
      });

      return newItem;
    });

    setApplicantsData({ ...(updatedApplicantData || applicantsData), items: newApplicantsData });
    setLoadingDates(false);
  };

  const getDatesAndMonths = (next) => {
    let startDate = new Date();

    if (next && dateFrom) {
      startDate = new Date(dateFrom);
      startDate.setDate(startDate.getDate() - 28);
      setLoadingGlobal(true);
    }
    if (next === false && dateFrom) {
      startDate = new Date(dateFrom);
      startDate.setDate(startDate.getDate() + 28);
      setLoadingGlobal(true);
    }

    let datesAndWeekdays = [];
    let uniqueMonths = new Set();

    for (let i = 0; i < 28; i++) {
      let date = new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000);
      let day = date.getDate();
      let weekday = date.toLocaleString('en', { weekday: 'short' });
      let month = date.toLocaleString('en', { month: 'short' });

      datesAndWeekdays.push({ date: day, weekday, timestamp: date.getTime() });
      uniqueMonths.add(month);
    }

    uniqueMonths = [...uniqueMonths];
    setDates(datesAndWeekdays);
    setMonths(uniqueMonths);
    const startDateFormatted = formatDate(startDate);
    const endDateFormatted = formatDate(new Date(datesAndWeekdays[datesAndWeekdays.length - 1].timestamp));

    setDateFrom(startDateFormatted);
    setDateTo(endDateFormatted);

  };

  const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const getRecruiterFilters = async (notReload) => {
    let currentUser;
    if (!user.id) {
      currentUser = await api.AUTH_ME();
      setUser(currentUser);
    } else {
      currentUser = user;
    }
    setIsRecruiter(currentUser.role_name === 'recruiter');
    const filtersData = await api.INIT_AVAILABILITY();

    const { recruiters, current_recruiter } = filtersData.data;
    let newRecruiter = currentUser.role_name !== 'recruiter' ?
      [{ key: 'all', title: 'All', checked: true }] :
      current_recruiter ? [{ key: current_recruiter.id, title: current_recruiter.name, checked: true }, {
        key: 'all',
        title: 'All',
        checked: false,
      }] : [];
    recruiters.map((recruiter) => {
      newRecruiter.push({ key: recruiter.id, title: recruiter.name, checked: false });
    });
    setRecruitersSelect(newRecruiter);
    setSelectedRecruiter([current_recruiter?.id ?? 'all']);
    if (!notReload) {
      await getRecruiterData([current_recruiter?.id ?? 'all']);
    }

    await calculateFilters(filtersData.data, notReload);
  };

  const getAllFiltersData = async (recruiters, returnFilters) => {
    if (returnFilters) {
      const filtersDataNew = await api.GET_FILTERS_DATA('', [], [], [], null, [], [], [], [], (recruiters || selectedRecruiter), null);
      await calculateFilters(filtersDataNew.data);
      return filtersDataNew.data;
    } else {
      const filtersDataNew = await api.GET_FILTERS_DATA(searchApplicant, selectedSector, selectedApplicantStatuses, selectedComplianceStatus, selectedTrust, selectedHospitals, selectedPositions, selectedSpecialty, selectedGrade, (recruiters || selectedRecruiter), trustFilterType);
      await calculateFilters(filtersDataNew.data);
    }
  };

  const calculateFilters = async (filtersData) => {
    const {
      sectors,
      specialties,
      grades,
      compliance_statuses,
      applicant_statuses,
      positions,
      client_trusts,
      preference_trusts,
    } = filtersData;
    let newSectors = [];
    let newSpecialties = [];
    let newGrades = [];
    let newApplicantStatuses = [];
    let newPositions = [];
    let newComplianceStatuses = [];
    let newClientTrusts = [];
    let newPreferenceTrusts = [];
    let newCombinedTrusts = [];

    (sectors || []).map((sector) => {
      newSectors.push({ key: sector.id, title: sector.name, checked: false });
    });
    (specialties || []).map((specialty) => {
      newSpecialties.push({ key: specialty.id, title: specialty.name, checked: false });
    });
    (grades || []).map((grade) => {
      newGrades.push({ key: grade.id, title: grade.name, checked: false });
    });
    (applicant_statuses || []).map((status) => {
      newApplicantStatuses.push({ key: status.id, title: status.name, checked: false });
    });
    (positions || []).map((position) => {
      newPositions.push({ key: position.id, title: position.name, checked: false });
    });
    (client_trusts || []).map((trust) => {
      newClientTrusts.push({ key: trust.id, title: trust.name, checked: false });
    });
    (preference_trusts || []).map((trust) => {
      newPreferenceTrusts.push({ key: trust.id, title: trust.name, checked: false });
    });
    (compliance_statuses || []).map((status) => {
      newComplianceStatuses.push({ key: status, title: status, checked: false });
    });
    newCombinedTrusts = [...newClientTrusts];
    newPreferenceTrusts.forEach(preference => {
      if (!newClientTrusts.some(client => client.key === preference.key)) {
        newCombinedTrusts.push(preference);
      }
    });
    const newCombinedTrustsFiltered = Object.values(newCombinedTrusts).sort((a, b) => a.title.localeCompare(b.title));
    setSectorsSelect(newSectors);
    setSpecialtiesSelect(newSpecialties);
    setGradesSelect(newGrades);
    setApplicantStatusesSelect(newApplicantStatuses);
    setPositionsSelect(newPositions);
    setComplianceStatusSelect(newComplianceStatuses);
    setTrustSelect(newClientTrusts);
    setPreferenceTrustSelect(newPreferenceTrusts);
    setCombinedTrustSelect(newCombinedTrustsFiltered);
  };

  const getRecruiterData = async (recruiters, allData, page, loadFilters, scroll) => {
    setLoading(true);
    let currentPageApplicants = page ? page : pageApplicants;
    if (allData) {
      currentPageApplicants = 1;
    }
    if (!scroll) {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    }

    const applicants = await api.GET_APPLICANTS(currentPageApplicants, recruiters, searchApplicant, (loadFilters ? [] : selectedSector), (loadFilters ? [] : selectedSpecialty), (loadFilters ? [] : selectedGrade), (loadFilters ? [] : selectedApplicantStatuses), (loadFilters ? [] : selectedPositions), (loadFilters ? null : selectedTrust), (loadFilters ? [] : selectedHospitals), selectedComplianceStatus, selectedAvailabilityType, selectedAvailabilityPeriod, (dateFrom ? dateFrom : formatDate(new Date())), trustFilterType);
    const newData = applicants.data;
    if (currentPageApplicants !== 1) {
      newData.items = [...applicantsData.items, ...newData.items];
    }
    if (!loadFilters) {
      getAllFiltersData(recruiters);
    }
    setApplicantsData(newData);
    setLoading(false);
  };

  const getCalendarData = async (global, externalData) => {
    if (global) {
      setLoadingGlobal(true);
    }
    const calendar = await api.GET_CALENDAR_DATA(dateFrom, dateTo, null, (externalData !== undefined ? externalData : isExternalData));
    setCalendarData(calendar.data);
    setTimeout(() => {
      setLoadingGlobal(false);
    }, 300);
  };

  useEffect(() => {
    if (dateFrom && dateTo && recruitersSelect.length > 0) {
      getCalendarData();
      // handleGetDataByFilters();
    }
  }, [dateFrom, dateTo, recruitersSelect]);

  useEffect(() => {
    getDatesAndMonths();
    getRecruiterFilters();
    // getAllFiltersData();
  }, []);

  const handleLoadMoreApplicant = async (applicant) => {
    setLoading(true);
    const recruiterId = applicant.id;
    const newPage = applicant.applicants.currentPage + 1;
    const moreApplicant = await api.GET_MORE_APPLICANTS(newPage, recruiterId, trustFilterType, selectedAvailabilityType, selectedAvailabilityPeriod, selectedRecruiter, selectedSector, selectedSpecialty, selectedGrade, selectedPositions, selectedApplicantStatuses, selectedHospitals, selectedTrust, selectedComplianceStatus, searchApplicant, dateFrom);
    let newData = moreApplicant.data;
    newData.items = [...applicant.applicants.items, ...newData.items];

    let updatedApplicantsData = {
      ...applicantsData,
      items: applicantsData.items.map(item =>
        item.id === recruiterId ? { ...item, applicants: newData } : item,
      ),
    };

    setApplicantsData(updatedApplicantsData);
    setLoading(false);
  };

  const handleOpenAdditionalInformation = async (applicantToFind) => {
    let applicantDetailData = null;
    if (!applicantToFind.openDetail) {
      setApplicantDetailLoaderId(applicantToFind.id);
      applicantDetailData = await api.GET_APPLICANT_DETAIL(applicantToFind.id);
    }
    const newApplicantsData = applicantsData.items.map((dataItem) => {
      if (dataItem.applicants && Array.isArray(dataItem.applicants.items)) {
        const updatedApplicants = dataItem.applicants.items.map((applicant) => {
          if (applicant.id === applicantToFind.id) {
            return {
              ...applicant,
              applicantDetail: applicantDetailData ? applicantDetailData.data : {},
              openDetail: applicant.openDetail ? !applicant.openDetail : true,
            };
          }
          return applicant;
        });

        return {
          ...dataItem,
          applicants: {
            ...dataItem.applicants,
            items: updatedApplicants,
          },
        };
      }
      return dataItem;
    });

    setApplicantsData({ ...applicantsData, items: newApplicantsData });
    setApplicantDetailLoaderId(null);
    if (!applicantToFind.openDetail) {
      await generateQuarterCalendar(applicantToFind.id, undefined, { ...applicantsData, items: newApplicantsData });
    }
  };

  const handlePressBooking = (applicantId, keyOpen) => {
    const newApplicantsData = applicantsData.items.map((dataItem) => {
      if (dataItem.applicants && Array.isArray(dataItem.applicants.items)) {
        const updatedApplicants = dataItem.applicants.items.map((applicant) => {
          if (applicant.id === applicantId) {
            return {
              ...applicant,
              openContactsDetail: false,
              openNotesDetail: false,
              openBookingDetail: false,
              openPreferencesDetail: false,
              [keyOpen]: applicant[keyOpen] ? !applicant[keyOpen] : true,
            };
          }
          return applicant;
        });

        return {
          ...dataItem,
          applicants: {
            ...dataItem.applicants,
            items: updatedApplicants,
          },
        };
      }
      return dataItem;
    });

    setApplicantsData({ ...applicantsData, items: newApplicantsData });
  };

  const handleOpenSelect = (selectId) => {
    setActiveSelect(selectId);
  };

  const handleChangeSelectedRecruiter = (recruiterId, add) => {
    if (add) {
      setSelectedRecruiter(prevSelectedRecruiter => [...prevSelectedRecruiter, recruiterId]);
    } else {
      setSelectedRecruiter(prevSelectedRecruiter =>
        prevSelectedRecruiter.filter(id => id !== recruiterId),
      );
    }
  };

  const handleChangeSelectedSector = (sectorId, add) => {
    if (add) {
      setSelectedSector([...selectedSector, sectorId]);
    } else {
      setSelectedSector(prevSelected =>
        prevSelected.filter(id => id !== sectorId),
      );
    }

  };

  const handleChangeSelectedSpecialty = (specialtyId, add) => {
    if (add) {
      setSelectedSpecialty([...selectedSpecialty, specialtyId]);
    } else {
      setSelectedSpecialty(prevSelected =>
        prevSelected.filter(id => id !== specialtyId),
      );
    }
  };

  const handleChangeSelectedGrade = (gradeId, add) => {
    if (add) {
      setSelectedGrade([...selectedGrade, gradeId]);
    } else {
      setSelectedGrade(prevSelected =>
        prevSelected.filter(id => id !== gradeId),
      );
    }
  };

  const handleChangeSelectedComplianceStatus = (complianceStatusId, add) => {
    if (add) {
      setSelectedComplianceStatus([...selectedComplianceStatus, complianceStatusId]);
    } else {
      setSelectedComplianceStatus(prevSelected =>
        prevSelected.filter(id => id !== complianceStatusId),
      );
    }
  };

  const handleChangeSelectedApplicantStatus = (applicantStatusId, add) => {
    if (add) {
      setSelectedApplicantStatuses([...selectedApplicantStatuses, applicantStatusId]);
    } else {
      setSelectedApplicantStatuses(prevSelected =>
        prevSelected.filter(id => id !== applicantStatusId),
      );
    }
  };

  const handleChangeSelectedPositions = (positionsId, add) => {
    if (add) {
      setSelectedPositions([...selectedPositions, positionsId]);
    } else {
      setSelectedPositions(prevSelected =>
        prevSelected.filter(id => id !== positionsId),
      );
    }
  };

  const handleChangeHospitals = (hospitalId, add) => {
    if (add) {
      setSelectedHospitals([...selectedHospitals, hospitalId]);
    } else {
      setSelectedHospitals(prevSelected =>
        prevSelected.filter(id => id !== hospitalId),
      );
    }
  };

  const handleTrustChange = async (trustId) => {
    setSelectedTrust(trustId);
    setSelectedHospitals([]);
    if (trustId) {
      const hospitalsData = await api.GET_HOSPITALS_BY_TRUST(trustId, selectedRecruiter, searchApplicant, selectedSector, selectedSpecialty, selectedGrade, selectedApplicantStatuses, selectedPositions, selectedHospitals, selectedComplianceStatus, trustFilterType);
      let hospitalsSelectNew = [];
      (hospitalsData?.data ?? []).map((hospital) => {
        hospitalsSelectNew.push({ key: hospital.id, title: hospital.name, checked: false });
      });
      setHospitalsSelect(hospitalsSelectNew);
    }
    setShouldGetData(true);
  };

  const handleGetDataByFilters = async (search) => {
    setLoading(true);
    window.scrollTo({
      top: 0,
      left: 0,
    });


    const data = await api.GET_APPLICANTS(1, selectedRecruiter, (search || searchApplicant), selectedSector, selectedSpecialty, selectedGrade, selectedApplicantStatuses, selectedPositions, selectedTrust, selectedHospitals, selectedComplianceStatus, selectedAvailabilityType, selectedAvailabilityPeriod, dateFrom, trustFilterType);
    await getAllFiltersData();
    setApplicantsData(data?.data ?? {});
    countFilters();
    setLoading(false);
  };

  const countFilters = () => {
    let selectFiltersCountNew = 0;
    if (selectedSector.length > 0) {
      selectFiltersCountNew += 1;
    }
    if (selectedSpecialty.length > 0) {
      selectFiltersCountNew += 1;
    }
    if (selectedGrade.length > 0) {
      selectFiltersCountNew += 1;
    }
    if (selectedComplianceStatus.length > 0) {
      selectFiltersCountNew += 1;
    }
    if (selectedApplicantStatuses.length > 0) {
      selectFiltersCountNew += 1;
    }
    if (selectedPositions.length > 0) {
      selectFiltersCountNew += 1;
    }
    if (selectedTrust) {
      selectFiltersCountNew += 1;
    }
    if (selectedTrust && selectedHospitals.length > 0) {
      selectFiltersCountNew += 1;
    }
    if (selectedAvailabilityType !== null) {
      selectFiltersCountNew += 1;
    }
    setSelectFiltersCount(selectFiltersCountNew);
  };

  const handleClearFilters = () => {
    setSelectedSector([]);
    setSelectedSpecialty([]);
    setSelectedGrade([]);
    setSelectedComplianceStatus([]);
    setSelectedApplicantStatuses([]);
    setSelectedPositions([]);
    setSelectedTrust(null);
    setSelectedHospitals([]);
    setSearchApplicant('');
    setShouldGetData(true);
    setShouldCleanData(true);
    // setShouldGetInit(true);
    setSelectedAvailabilityType(null);
    setSelectedAvailabilityPeriod(7);
    setTrustFilterType('history');
    setTimeout(() => {
      setShouldCleanData(false);
    }, 1000);
  };

  useEffect(() => {
    if (shouldGetData) {
      setPageApplicants(1);
      handleGetDataByFilters();
      setShouldGetData(false);
    }
  }, [shouldGetData, selectedSector, selectedSpecialty, selectedGrade, selectedComplianceStatus, selectedApplicantStatuses, selectedPositions, selectedTrust, selectedHospitals]);

  useEffect(() => {
    if (shouldGetInit) {
      getAllFiltersData();
      setShouldGetInit(false);
    }
  }, [shouldGetInit]);

  const handleGetEvenData = async (applicantId, calendarItem, monthName, year) => {
    let date;
    if (calendarItem.timestamp) {
      date = formatDate(calendarItem.timestamp);
    } else {
      date = calendarItem.date;
    }
    const eventData = await api.GET_EVENT_DATA(calendarItem?.id ?? null, applicantId, date);
    let calendarDataNew = { ...calendarData };
    if (calendarDataNew[applicantId]) {
      const dayIndex = calendarDataNew[applicantId].findIndex(item => item.day === calendarItem.day);
      if (dayIndex !== -1) {
        calendarDataNew[applicantId][dayIndex] = {
          ...calendarDataNew[applicantId][dayIndex],
          eventData: eventData.data,
        };
      }
      setEventData(eventData.data);
    } else {
      if (calendarItem?.id ?? false) {
        calendarDataNew[applicantId] = [{ day: calendarItem.day, ...eventData }];
        setEventData(eventData.data);
      } else {
        setEventData(eventData.data);
      }
    }
    setCalendarData(calendarDataNew);
    if (monthName && !year) {
      const updatedApplicantsData = applicantsData.items.map(applicant => {
        return {
          ...applicant,
          applicants: {
            ...applicant.applicants,
            items: applicant.applicants.items.map(applicantDetail => {
              if (applicantDetail.id === applicantId) {
                const monthData = applicantDetail.quarterData.find(item => item.monthName === monthName);
                if (monthData) {
                  const updatedDates = monthData.dates.map(date => {
                    if (date.day === calendarItem.day) {
                      return { ...date, eventData: eventData.data };
                    }
                    return date;
                  });

                  return {
                    ...applicantDetail,
                    quarterData: applicantDetail.quarterData.map(qData => {
                      if (qData.monthName === monthName) {
                        return { ...qData, dates: updatedDates };
                      }
                      return qData;
                    }),
                  };
                }
              }
              return applicantDetail;
            }),
          },
        };
      });
      setApplicantsData({ ...applicantsData, items: updatedApplicantsData });
    }
    if (monthName && year) {
      const updatedApplicantsData = applicantsData.items.map(applicant => {
        return {
          ...applicant,
          applicants: {
            ...applicant.applicants,
            items: applicant.applicants.items.map(applicantDetail => {
              if (applicantDetail.id === applicantId) {
                const monthData = (applicantDetail.yearData?.items ?? []).find(item => item.monthName === monthName);
                if (monthData) {
                  const updatedDates = monthData.dates.map(date => {
                    if (date.day === calendarItem.day) {
                      return { ...date, eventData: eventData.data };
                    }
                    return date;
                  });

                  return {
                    ...applicantDetail,
                    yearData: {
                      ...applicantDetail.yearData,
                      items: applicantDetail.yearData.items.map(qData => {
                        if (qData.monthName === monthName) {
                          return { ...qData, dates: updatedDates };
                        }
                        return qData;
                      }),
                    },
                  };
                }
              }
              return applicantDetail;
            }),
          },
        };
      });
      setApplicantsData({ ...applicantsData, items: updatedApplicantsData });
    }
    return eventData.data;
  };

  const handleOpenGlobalDelete = (id, applicantId, handleEditEvent, isBooking) => {
    setIsOpenGlobalDelete(!isOpenGlobalDelete);
    setOpenGlobalDeleteId(id);
    setOpenGlobalDeleteApplicantId(applicantId);
    setOpenGlobalDeleteEdit(() => handleEditEvent);
    setIsBooking(isBooking);
  };

  const handleDeleteEvent = async () => {
    if (isBooking) {
      openGlobalDeleteEdit(true, true, true);
      handleOpenGlobalDelete();
    } else {
      setLoadingGlobal(true);
      handleOpenGlobalDelete();
      try {
        await api.DELETE_EVENT(openGlobalDeleteId);
        await getCalendarData(true);
        await generateQuarterCalendar(openGlobalDeleteApplicantId, undefined, undefined, true);
      } catch (e) {
        setLoadingGlobal(false);
      }
    }
  };

  const handleClickPin = async (recruiter_id, applicant_id) => {
    const res = await api.CHANGE_PINS(recruiter_id, applicant_id);
    if (res) {
      const updatedApplicantsData = applicantsData.items.map(recruiter => {
        if (recruiter.id === recruiter_id) {
          let updatedPinnedCount = recruiter.pinned_count;
          const updatedApplicants = recruiter.applicants.items.map(applicantDetail => {
            if (applicantDetail.id === applicant_id) {
              if (applicantDetail.pinned) {
                updatedPinnedCount -= 1;
              } else {
                updatedPinnedCount += 1;
              }
              customSuccessNotification('Successfully' + (!applicantDetail.pinned ? ' pinned' : ' unpinned'));
              return { ...applicantDetail, pinned: !applicantDetail.pinned };
            }
            return applicantDetail;
          });
          recruiter.pinned_count = updatedPinnedCount;
          return {
            ...recruiter,
            applicants: {
              ...recruiter.applicants,
              items: updatedApplicants,
              pinned_count: updatedPinnedCount,
            },
          };
        }
        return recruiter;
      });

      setApplicantsData({ ...applicantsData, items: updatedApplicantsData });
    }
  };

  const saveDataTypeToLocalStorage = (dataType) => {
    localStorage.setItem('dataType', JSON.stringify(dataType));
  };

  const getDataTypeFromLocalStorage = () => {
    const storedDataType = localStorage.getItem('dataType');
    return storedDataType ? JSON.parse(storedDataType) : false;
  };

  useEffect(() => {
    const data = getDataTypeFromLocalStorage();
    setIsExternalData(data);
  });

  const checkFilters = (allNewFilters) => {
    const {
      sectors,
      specialties,
      grades,
      compliance_statuses,
      applicant_statuses,
      positions,
      client_trusts,
      preference_trusts,
    } = allNewFilters;
    const allNewFilterIdsSector = sectors.map(filter => filter.id);
    const updatedSelectedSector = selectedSector.filter(id => allNewFilterIdsSector.includes(id));
    setSelectedSector(updatedSelectedSector);

    const allNewFilterIdsPositions = positions.map(filter => filter.id);
    const updatedApplicantPositions = selectedPositions.filter(id => allNewFilterIdsPositions.includes(id));
    setSelectedPositions(updatedApplicantPositions);

    const allNewFilterIdsSpecialties = specialties.map(filter => filter.id);
    const updatedSelectedSpecialties = selectedSpecialty.filter(id => allNewFilterIdsSpecialties.includes(id));
    setSelectedSpecialty(updatedSelectedSpecialties);

    const allNewFilterIdsGrades = grades.map(filter => filter.id);
    const updatedSelectedGrades = selectedGrade.filter(id => allNewFilterIdsGrades.includes(id));
    setSelectedGrade(updatedSelectedGrades);

    const allNewFilterIdsApplicantStatuses = applicant_statuses.map(filter => filter.id);
    const updatedApplicantStatuses = selectedApplicantStatuses.filter(id => allNewFilterIdsApplicantStatuses.includes(id));
    setSelectedApplicantStatuses(updatedApplicantStatuses);

    const allNewFilterIdsComplianceStatuses = compliance_statuses.map(filter => filter.id);
    const updatedComplianceStatuses = selectedComplianceStatus.filter(id => allNewFilterIdsComplianceStatuses.includes(id));
    setSelectedComplianceStatus(updatedComplianceStatuses);

    const allNewFilterIdsClientTrusts = [...client_trusts, ...preference_trusts].map(filter => filter.id);
    const updatedApplicantClientTrusts = (selectedTrust ? [selectedTrust] : []).filter(id => allNewFilterIdsClientTrusts.includes(id));
    setSelectedTrust(updatedApplicantClientTrusts.length > 0 ? updatedApplicantClientTrusts[0] : null);

    setGetRecruiterDataState(true);
  };

  useEffect(() => {
    if (getRecruiterDataState) {
      getRecruiterData(selectedRecruiter, null, null, false);
      setGetRecruiterDataState(false);
    }
  }, [getRecruiterDataState]);

  const handleChangeRecruiterFilter = async (filter, key) => {
    setSelectedRecruiter(filter);
    const allNewFilters = await getAllFiltersData(filter, true);
    checkFilters(allNewFilters);
  };

  const handleSendEmail = async () => {
    setLoading(true);
    const result = await api.CANCEL_BOOKED_EVENT(sendData);
    if (result === 'OK') {
      handleToggleDeleteMailModal();
      setSendData({});
      await getCalendarData(true);
      await generateQuarterCalendar(sendData.applicant_id, undefined, undefined, true);
    }
    setLoading(false);
  };

  const handleToggleDeleteMailModal = () => {
    setIsShowSendEmailModal(!isShowSendEmailModal);
  };

  return (
    <main className={css.main}>
      <div className={css.tableWrapper}>
        <div className={css.selectorsWrapper}>
          <div className={css.rowThird}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
              <div className={css.recruiterSelect}>
                <SelectRecruiter
                  user={user}
                  onGetRecruiterData={async (filter, key) => {
                    await handleChangeRecruiterFilter(filter, key);
                  }}
                  onChangeSelectedRecruiter={handleChangeSelectedRecruiter}
                  formFilters={recruitersSelect}
                  isRecruiter={isRecruiter}
                  onOpenSelect={handleOpenSelect}
                  isActive={activeSelect === 'recruiter'}
                />
              </div>
              <Input
                custom={css.inputContainer}
                size={'x25'}
                value={searchApplicant}
                type='text'
                placeholderStyle={css.placeholderStyle}
                placeholder='Search by Applicant name or ID'
                onChange={handleSearchApplicant}
                clear={true}
              />
              <PeriodSelect
                onOpenSelect={(value) => setActiveSelect(value)}
                onGetData={() => {
                  setShouldGetData(true);
                }}
                shouldCleanData={shouldCleanData}
                isActive={activeSelect === 'Period'}
                onChangeType={(type, checked) => {
                  setSelectedAvailabilityType(checked ? type : null);
                }}
                onChangeSelectedPeriod={(period) => setSelectedAvailabilityPeriod(period)}
              />
            </div>
            <div className={css.filterButtonContainer}>
              {selectFiltersCount > 0 &&
                <span
                  className={css.clearAllText}
                  onClick={handleClearFilters}>
                  Clear All
                </span>
              }
              <div
                className={css.openFilterContainer}
                onClick={() => {
                  setIsFiltersOpened(!isFiltersOpened);
                }}>
                {selectFiltersCount > 0 &&
                  <div className={css.filterCountContainer}>
                    <span className={css.filterCount}>{selectFiltersCount}</span>
                  </div>
                }
                <span className={css.filterText}>Filters</span>
                <img
                  alt='sorting way'
                  className={css.filterIcon}
                  src={isFiltersOpened ? sortUp : sortDown}
                />
              </div>
            </div>
          </div>
          {isFiltersOpened &&
            <>
              <div className={css.rowThird}>
                <MultiSelect
                  placeholder='Sector'
                  selectedItems={selectedSector}
                  onGetData={handleGetDataByFilters}
                  onChange={handleChangeSelectedSector}
                  dataList={sectorsSelect}
                  onOpenSelect={handleOpenSelect}
                  setShouldGetData={setShouldGetData}
                  shouldCleanData={shouldCleanData}
                  isActive={activeSelect === 'Sector'}
                  setShouldGetInit={setShouldGetInit}
                />
                <MultiSelect
                  className={css.secondSelect}
                  placeholder='Position'
                  selectedItems={selectedPositions}
                  onGetData={handleGetDataByFilters}
                  onChange={handleChangeSelectedPositions}
                  dataList={positionsSelect}
                  onOpenSelect={handleOpenSelect}
                  setShouldGetData={setShouldGetData}
                  shouldCleanData={shouldCleanData}
                  isActive={activeSelect === 'Position'}
                />
                <MultiSelect
                  className={css.secondSelect}
                  placeholder='Specialty'
                  selectedItems={selectedSpecialty}
                  onGetData={handleGetDataByFilters}
                  onChange={handleChangeSelectedSpecialty}
                  dataList={specialtiesSelect}
                  onOpenSelect={handleOpenSelect}
                  setShouldGetData={setShouldGetData}
                  shouldCleanData={shouldCleanData}
                  isActive={activeSelect === 'Specialty'}
                />
                <MultiSelect
                  className={css.secondSelect}
                  placeholder='Grade/band'
                  selectedItems={selectedGrade}
                  onGetData={handleGetDataByFilters}
                  onChange={handleChangeSelectedGrade}
                  dataList={gradesSelect}
                  onOpenSelect={handleOpenSelect}
                  setShouldGetData={setShouldGetData}
                  shouldCleanData={shouldCleanData}
                  isActive={activeSelect === 'Grade/band'}
                />
              </div>
              <div className={css.rowThird}>
                <MultiSelect
                  placeholder='Applicant Status'
                  selectedItems={selectedApplicantStatuses}
                  onGetData={handleGetDataByFilters}
                  onChange={handleChangeSelectedApplicantStatus}
                  dataList={applicantStatusesSelect}
                  onOpenSelect={handleOpenSelect}
                  setShouldGetData={setShouldGetData}
                  shouldCleanData={shouldCleanData}
                  isActive={activeSelect === 'Applicant Status'}
                />
                <MultiSelect
                  className={css.secondSelect}
                  placeholder='Compliance Status'
                  selectedItems={selectedComplianceStatus}
                  onGetData={handleGetDataByFilters}
                  onChange={handleChangeSelectedComplianceStatus}
                  dataList={complianceStatusSelect}
                  onOpenSelect={handleOpenSelect}
                  setShouldGetData={setShouldGetData}
                  shouldCleanData={shouldCleanData}
                  isActive={activeSelect === 'Compliance Status'}
                />
                <MultiSelect
                  className={css.secondSelect}
                  placeholder='Trust'
                  selectedItems={selectedTrust}
                  onGetData={handleGetDataByFilters}
                  onChange={handleTrustChange}
                  dataList={trustFilterType === 'history' ? trustSelect : (trustFilterType === 'preferences' ? preferenceTrustSelect : combinedTrustSelect)}
                  onOpenSelect={handleOpenSelect}
                  setShouldGetData={setShouldGetData}
                  shouldCleanData={shouldCleanData}
                  isActive={activeSelect === 'Trust'}
                  setTrustFilterType={setTrustFilterType}
                  trustFilterType={trustFilterType}
                  search={true}
                />
                <MultiSelect
                  className={css.secondSelect}
                  placeholder='Hospitals'
                  selectedItems={selectedHospitals}
                  onGetData={handleGetDataByFilters}
                  onChange={handleChangeHospitals}
                  dataList={hospitalsSelect}
                  disabled={!selectedTrust}
                  onOpenSelect={handleOpenSelect}
                  setShouldGetData={setShouldGetData}
                  shouldCleanData={shouldCleanData}
                  isActive={activeSelect === 'Hospitals'}
                />
              </div>
              <div style={{ marginTop: '20px' }}>
                <Select
                  value={isExternalData}
                  options={options}
                  size={'x25'}
                  onChange={async (e) => {
                    const value = e.target.value;
                    setIsExternalData(value);
                    saveDataTypeToLocalStorage(!isExternalData);
                    setShouldGetData(true);
                    await getCalendarData(true, value === 'true');
                  }}
                />
              </div>
            </>
          }
        </div>
        {(applicantsData?.items ?? []).length > 0 &&
          <div
            className={css.datesMainContainer}
            style={{ top: isFiltersOpened ? '185px' : '60px' }}>
            <div className={css.dateSelectContainer}>
              <img
                src={arrowLeft}
                className={css.dateArrowLeft}
                alt={'arrowLeft'}
                onClick={() => {
                  getDatesAndMonths(true);
                }} />
              <span>
                {dates[0].date}&nbsp;{months[0]}&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;{dates[dates.length - 1].date}&nbsp;{months[1]}
              </span>
              <img
                src={arrowRight}
                className={css.dateArrowRight}
                alt={'arrowRight'}
                onClick={() => {
                  getDatesAndMonths(false);
                }} />
            </div>
            <div className={css.datesOverlap}>
              {dates.map((date, index) => {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const dateObj = new Date();
                dateObj.setTime(date.timestamp);
                dateObj.setHours(0, 0, 0, 0);
                const isToday = dateObj.getTime() === today.getTime();

                return (
                  <div className={css.datesContainer} key={index}>
                    <span
                      className={css.dateText}
                      style={isToday ? { color: '#0CADD3' } : (date.weekday === 'Sat' || date.weekday === 'Sun') ? { color: '#A4A4A4' } : {}}>
                      {date.date}
                    </span>
                    <span
                      className={css.dateNameText}
                      style={isToday ? { color: '#0CADD3' } : (date.weekday === 'Sat' || date.weekday === 'Sun') ? { color: '#A4A4A4' } : {}}>
                      {date.weekday}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        }
        {loading && !loadingGlobal && (
          <div className={css.loader}>
            <Oval {...props} height={50} width={50} />
          </div>
        )}
        {!loading && (applicantsData?.items ?? []).length === 0 &&
          <div className={css.noDataContainer}>
            <img src={noDataIcon} className={css.noDataImg} alt='noDataIcon' />
            <span className={css.noDataText}>No Data Available</span>
          </div>
        }
        {loadingGlobal && (
          <div className={css.modalOverlay}>
            <div className={css.loader}>
              <Oval {...props} height={50} width={50} />
            </div>
          </div>
        )}
        {(applicantsData?.items ?? []).map((applicant) => (
          <div>
            <div className={css.recruiterNameContainer}>
              <span className={css.recruiterName}>
                {applicant.name}
              </span>
            </div>
            <div className={css.recruiterCountContainer}>
              <div className={css.recruiterCount}>
                <img src={usersIcon} className={css.usersIcon} alt={'usersIcon'} />
                <span>{applicant?.applicants?.total}</span>
              </div>
              <div className={css.dot} />
              <div className={css.recruiterCount}>
                <img src={starInactive} className={css.usersIcon} alt={'starInactive'} />
                <span>{applicant.pinned_count}</span>
                <span style={{ color: '#878787' }}>/10</span>
              </div>
            </div>

            {(applicant?.applicants?.items ?? []).map((applicantDetail, applicantIndex) => (
              <div>
                <div
                  key={applicantIndex}
                  className={`
                ${css.mainApplicantContainer}
                  ${applicantIndex === 0 ? css.mainApplicantContainerBorder : {}} 
                `}>
                  <div
                    className={`
                  ${css.itemsContainer} 
                  ${applicantIndex === 0 ? css.itemContainerBorder : {}} 
                  ${(applicant?.applicants?.lastPage === applicant?.applicants?.currentPage && applicantIndex === (applicant?.applicants?.items ?? []).length - 1) ? css.lastItem : {}}
                  `}>
                    <div className={`${css.itemContainer}`}>
                      <div className={css.itemHeader}>
                        <span
                          className={css.itemArrow}
                          onClick={async () => {
                            await handleOpenAdditionalInformation(applicantDetail);
                          }}>
                          {applicantDetail.openDetail ?
                            <img src={arrowDown} className={css.itemArrowIconDown} alt={'arrowDown'} />
                            :
                            <>
                              {applicantDetailLoaderId === applicantDetail.id ?
                                <div className={css.itemArrowIcon}>
                                  <Oval {...props} height={12} width={12} wrapperClass={css.detailLoader} />
                                </div>
                                :
                                <img src={arrowRight} className={css.itemArrowIcon} alt={'arrowRight'} />
                              }
                            </>
                          }
                        </span>
                        <span className={css.itemName}>{applicantDetail?.name}</span>
                        <div style={{ width: '30px', display: 'flex', justifyContent: 'flex-end' }}
                             onClick={async () => {
                               await handleClickPin(applicant.id, applicantDetail.id);
                             }}>
                          {applicantDetail.pinned ?
                            <img src={starActive} className={css.starIconActive} alt={'starInactive'} />
                            :
                            <img src={starInactive} className={css.starIcon} alt={'starInactive'} />
                          }
                        </div>
                        <span
                          onClick={() => {
                            setIsShowInsertBooking(applicant.id + applicantDetail.id);
                            setApplicantDetailId(applicantDetail.external_id);
                          }}
                          className={css.itemAdd}>
                          +
                        </span>
                      </div>
                      {isShowInsertBooking === (applicant.id + applicantDetail.id) &&
                        <InsertBookingRequest
                          applicantDetailId={applicantDetailId}
                          onCloseInsertBooking={() => setIsShowInsertBooking(false)}
                        />
                      }
                      <div className={css.itemSubtitle}>
                        {applicantDetail?.grade &&
                          <span className={css.itemRole}>{applicantDetail?.grade}</span>
                        }
                        <div className={css.itemContainer1}>
                          <div className={css.itemHeader1}>
                        <span
                          className={`${css.itemRole1} ${(applicantDetail?.specialties ?? []).length > 1 ? css.itemRole2 : {}}`}>{applicantDetail?.specialties?.[0]?.name}</span>
                            {(applicantDetail?.specialties ?? []).length > 1 &&
                              <span
                                className={css.itemMore}>, +{(applicantDetail?.specialties ?? []).length - 1}</span>
                            }
                          </div>
                          {(applicantDetail?.specialties ?? []).length > 1 &&
                            <div className={css.itemSubtitle}>
                              <div className={css.itemSpecialtiesList}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  {applicantDetail?.specialties?.map((specialty, index, array) => (
                                    <span key={index} className={css.tooltipText}>
                                  {specialty.name}{index < array.length - 1 ? ', ' : ''}
                                </span>
                                  ))}
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={css.colorsContainer}
                    style={{ height: applicantIndex === 0 ? '50px' : (applicantIndex === (applicant?.applicants?.items ?? []).length - 1) ? '52px' : '51px' }}>
                    {Array.from({ length: calendarData?.[applicantDetail.id] ? calendarData[applicantDetail.id].length : 28 }).map((calendarItem, index) => (
                      <CalendarDataItem
                        index={index}
                        setGlobalLoading={setLoadingGlobal}
                        eventDataLocal={eventData}
                        calendarData={calendarData}
                        date={dates[index]}
                        applicantIndex={applicantIndex}
                        applicantDetail={applicantDetail}
                        onGetEvenData={handleGetEvenData}
                        onGetCalendarData={getCalendarData}
                        isOpenAddEvent={openedModalIndex === `${applicantDetail.id}_${index}`}
                        isOpenViewEvent={openedModalIndex === `${applicantDetail.id}_${index}_view`}
                        onOpen={() => setOpenedModalIndex(`${applicantDetail.id}_${index}`)}
                        onOpenView={() => setOpenedModalIndex(`${applicantDetail.id}_${index}_view`)}
                        onClose={() => setOpenedModalIndex(null)}
                      />
                    ))}
                  </div>
                </div>
                {applicantDetail.openDetail &&
                  <div className={css.openDetailContainer}>
                    <ApplicantDetail applicant={applicantDetail} onPressBooking={handlePressBooking} />
                    <DetailCalendarView
                      setIsShowSendEmailModal={setIsShowSendEmailModal}
                      setSendData={setSendData}
                      sendData={sendData}
                      onToggleDeleteMailModal={handleToggleDeleteMailModal}
                      openGlobalDelete={handleOpenGlobalDelete}
                      setGlobalLoading={setLoadingGlobal}
                      loadingDates={loadingDates}
                      eventDataLocal={eventData}
                      onGetEvenData={handleGetEvenData}
                      applicantDetail={applicantDetail}
                      handleChangeYear={getYearCalendar}
                      onGetCalendarData={getCalendarData}
                      onGenerateQuarterCalendar={generateQuarterCalendar}
                    />
                  </div>
                }
              </div>
            ))}
            {applicant?.applicants?.lastPage !== applicant?.applicants?.currentPage &&
              <div className={css.showMoreContainer} onClick={async () => {
                await handleLoadMoreApplicant(applicant);
              }}>
                <span className={css.showMoreText}>
                  Show +{((applicant?.applicants?.total ?? 0) - (applicant?.applicants?.items ?? []).length) > 50 ? 50 : ((applicant?.applicants?.total ?? 0) - (applicant?.applicants?.items ?? []).length)}
                </span>
                {loading &&
                  <Oval {...props} height={20} width={20} wrapperStyle={{ marginLeft: 10 }} />
                }
              </div>
            }
          </div>
        ))}
        {(applicantsData?.currentPage ?? 1) !== (applicantsData?.lastPage ?? 1) &&
          <div
            className={css.loadMoreButtonContainer}
            onClick={async () => {
              setPageApplicants(pageApplicants + 1);
              await getRecruiterData(null, null, pageApplicants + 1, false, true);
            }}>
            <div className={css.loadMoreButton}>
              {loading ?
                <Oval {...props1} height={20} width={20} />
                :
                <span className={css.loadMoreButtonText}>Load More</span>
              }
            </div>
          </div>
        }
        <div
          className={`${css.statusLegendContainer} ${loading && (applicantsData?.items ?? []).length === 0 ? css.statusLegendContainer1 : {}}`}>
          <div className={css.statusLegendItem}>
            <div className={`${css.statusBox} ${css.noEvents}`}></div>
            <span className={css.itemSubtitle}>No Events</span>
          </div>
          <div className={css.statusLegendItem}>
            <div className={`${css.statusBox} ${css.available}`}></div>
            <span className={css.itemSubtitle}>Available</span>
          </div>
          <div className={css.statusLegendItem}>
            <div className={`${css.statusBox} ${css.booked}`}></div>
            <span className={css.itemSubtitle}>Booked</span>
          </div>
          <div className={css.statusLegendItem}>
            <div className={`${css.statusBox} ${css.notAvailable}`}></div>
            <span className={css.itemSubtitle}>Not Available</span>
          </div>
        </div>
      </div>
      {isOpenGlobalDelete &&
        <DeleteEventModal
          onChangeVisibility={handleOpenGlobalDelete}
          onDeleteEvent={handleDeleteEvent}
          handleEditEvent={openGlobalDeleteEdit} />
      }
      {isShowSendEmailModal &&
        <SendDeleteEmailModal
          onCloseModal={handleToggleDeleteMailModal}
          sendData={sendData}
          setSendData={setSendData}
          onSendEmail={handleSendEmail} />
      }
    </main>
  );
};

export default AvailabilityFormPage;
