import css from './CalendarDataItem.module.css';
import React, { useState } from 'react';
import plusBlackIcon from '../../../../assets/images/plusBlackIcon.png';
import { AddNewEventModal } from '../AddNewEventModal/AddNewEventModal';
import { ViewEventModal } from '../ViewEventModal/ViewEventModal';
import { SendDeleteEmailModal } from '../SendDeleteEmailModal/SendDeleteEmailModal';
import api from '../../../../config/api';

export const CalendarDataItem = ({
                                   applicantIndex,
                                   index,
                                   calendarData,
                                   applicantDetail,
                                   isOpenAddEvent,
                                   onClose,
                                   onOpen,
                                   onOpenView,
                                   onGetEvenData,
                                   isOpenViewEvent,
                                   onGetCalendarData,
                                   eventDataLocal,
                                   setGlobalLoading,
                                   date,
                                 }) => {
  const calendarItem = calendarData?.[applicantDetail.id]?.[index];
  const itemColor = calendarItem?.color;

  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [isShowSendEmailModal, setIsShowSendEmailModal] = useState(false);
  const [isDeleteMore, setIsDeleteMore] = useState(false);
  const [sendData, setSendData] = useState({});

  const handleChangeVisibility = () => {
    setIsEdit(false);
    setIsBooking(false);
    if (isOpenAddEvent) {
      onClose();
    } else {
      onOpen();
      onGetEvenData(applicantDetail.id, (calendarItem || date));
    }
  };

  const handleChangeVisibilityView = () => {
    setIsBooking(false);
    if (isOpenViewEvent) {
      onClose();
    } else {
      onOpenView();
      onGetEvenData(applicantDetail.id, (calendarItem || null));
    }
  };

  const colorName = {
    'red': '#EB3223',
    'orange': '#EB9723',
    'green': '#4EAC5B',
  };

  const colorNameToRgb = {
    'white': '255, 255, 255',
    'red': '235, 50, 35',
    'orange': '235, 151, 0',
    'green': '78, 172, 91',
  };

  const handleEditEvent = (isDelete, isBooking, deleteLocal) => {
    if (deleteLocal) {
      handleChangeVisibilityView();
      handleChangeVisibility();
      onClose();
      checkIfCanDelete();
      if(isBooking){
        setIsBooking(true)
      }
      return;
    }
    if (isBooking) {
      handleChangeVisibilityView();
      handleChangeVisibility();
      setIsBooking(true);
      // setIsShowSendEmailModal(true);
    } else {
      setIsBooking(false);
      handleChangeVisibilityView();
      handleChangeVisibility();
    }
    setIsEdit(true);
    setIsDelete(isDelete === true);
  };

  const checkIfCanDelete = async () => {
    let newEventData = [];
    let myEventDataCopy = JSON.parse(JSON.stringify(eventDataLocal.events));
    myEventDataCopy.map((event) => {
      newEventData.push({
        type_id: 31,
        event_id: event.id,
      });
    });
    const data = {
      'mail_data': {
        'microsoft_access_token': localStorage.getItem('MICROSOFT_ACCESS_TOKEN'),
      },
      'tab': 'ad_hoc',
      'applicant_id': applicantDetail.id,
      'events': newEventData,
    };
    const result = await api.CHECK_BEFORE_CANCEL(data);
    if (result.status !== 'FAILED') {
      setSendData({...data, mail_data: result?.data?.mail_data ?? {}});
      handleToggleModal();
      console.log('result')
    }
  };

  const handleToggleModal = () => {
    setIsShowSendEmailModal(!isShowSendEmailModal);
  };

  function isWeekend(dateInput) {
    const date = new Date(typeof dateInput === 'number' ? dateInput : dateInput);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6;
  }

  function colorNameToRgba(colorName, opacity) {
    const rgb = colorName ? colorNameToRgb[colorName.toLowerCase()] : '255, 255, 255';
    return `rgba(${rgb}, ${opacity})`;
  }

  const dynamicStyle = (calendarItem?.color ?? '') === 'orangeTransparent' ? {
    borderWidth: 2,
    borderColor: '#EB9723',
    borderStyle: 'solid',
    backgroundColor: '#EB97234D',
  } : {};

  const handleSendEmail = async () => {
    setGlobalLoading(true);
    const result = await api.CANCEL_BOOKED_EVENT(sendData);
    if (result === 'OK') {
      handleToggleModal();
      setSendData({});
      onGetCalendarData(true);
    }
    setGlobalLoading(false);
  };

  return (
    <div className={css.colorItemContainer}>
      <div
        className={css.colorItemContainer1}
        onClick={() => {
          if ((calendarItem?.value ?? '') === '') {
            handleChangeVisibility();
          } else {
            handleChangeVisibilityView();
          }
          setIsDelete(false);
        }}
        style={{
          height: applicantIndex === 0 ? '100%' : 'calc(100% - 1px)',
          borderLeftStyle: index === 0 ? 'none' : 'solid',
          borderTopRightRadius: index === 27 && applicantIndex === 0 ? '4px' : 0,
          backgroundColor: isOpenAddEvent ? '#E7E7E8' : isWeekend(calendarItem?.date ?? date.timestamp) ? ((itemColor && itemColor !== 'white' ? colorNameToRgba(itemColor, 0.7) : '#F3F3F3')) : (itemColor && itemColor !== 'white' ? colorName[itemColor] : 'transparent'),
          ...dynamicStyle,
        }}>
        {(!isOpenAddEvent || isEdit) ?
          <>
            {calendarData?.[applicantDetail.id] &&
              <span
                key={index}
                style={{ color: (calendarItem?.color ?? '') === 'orangeTransparent' ? 'black' : 'white' }}
                className={css.calendarText}>
                {calendarItem?.value ?? ''}
              </span>
            }
          </>
          :
          <img src={plusBlackIcon} alt={'plusBlackIcon'} className={css.plusBlackIcon} />
        }
        {(calendarItem?.note && calendarItem.note !== '') &&
          <>
            <div className={css.triangleBorder} />
            <div className={css.noteTriangle} />
          </>
        }
      </div>
      {isOpenAddEvent &&
        <AddNewEventModal
          index={index}
          isEdit={isEdit}
          isBooking={isBooking}
          setSendData={setSendData}
          onChangeDeleteModal={handleToggleModal}
          sendData={sendData}
          onGetEvenData={onGetEvenData}
          eventDataGlobal={eventDataLocal}
          setGlobalLoading={setGlobalLoading}
          onGetCalendarData={onGetCalendarData}
          applicantId={applicantDetail.id}
          calendarItem={(calendarItem || date)}
          onChangeVisibility={handleChangeVisibility}
          isDeleteEvent={isDelete}
        />
      }
      {isOpenViewEvent &&
        <ViewEventModal
          setGlobalLoading={setGlobalLoading}
          index={index}
          onGetCalendarData={onGetCalendarData}
          onEditEvent={handleEditEvent}
          calendarItem={calendarItem}
          onChangeVisibility={handleChangeVisibilityView}
        />
      }
      {isShowSendEmailModal &&
        <SendDeleteEmailModal
          calendarData={calendarItem}
          onCloseModal={handleToggleModal}
          sendData={sendData}
          setSendData={setSendData}
          onSendEmail={handleSendEmail} />
      }
    </div>
  );
};
