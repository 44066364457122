import css from './PeriodSelect.module.css';
import React, { useEffect, useState } from 'react';
import checkedRadioButton from '../../../../assets/images/checkedRadioButton.png';
import crossIcon from '../../../../assets/images/close-button.png';

export const PeriodSelect = ({
                               onOpenSelect,
                               isActive,
                               onChangeSelectedPeriod,
                               onGetData,
                               onChangeType,
                               shouldCleanData,
                             }) => {
  const [filters, setFilters] = useState([
    { id: 'available', color: 'green', name: 'Available', checked: false },
    { id: 'booked', color: 'orange', name: 'Booked', checked: false },
    { id: 'none', color: '#F6F6F6', name: 'None', checked: false },
    { id: 'not_available', color: 'red', name: 'Not Available', checked: false },
  ]);
  const [selectedTab, setSelectedTab] = useState(7);

  useEffect(() => {
    if (shouldCleanData) {
      const newFilters = filters.map((filter) => {
        return { ...filter, checked: false };
      });
      setFilters(newFilters);
      setSelectedTab(7);
    }
  }, [shouldCleanData]);

  const changeVisibilityModal = () => {
    onOpenSelect(isActive ? null : 'Period');
  };

  const handleCheckboxChange = (index) => {
    const updatedFilters = filters.map((filter, i) => {
      if (i === index) {
        onChangeType(filter.id, !filter.checked);
        return { ...filter, checked: !filter.checked };
      } else {
        return { ...filter, checked: false };
      }
    });
    setFilters(updatedFilters);
  };

  const onPressClear = () => {
    const newFilters = filters.map((filter) => {
      return { ...filter, checked: false };
    });
    setFilters(newFilters);
    onChangeType(null);
    onChangeSelectedPeriod(7);
    setSelectedTab(7);
    onGetData();
    if (isActive) {
      changeVisibilityModal();
    }
  };

  const onPressApply = () => {
    onGetData();
    changeVisibilityModal();
  };
  const checkedValue = filters.filter(el => el.checked);

  return (
    <div className={css.mainContainer}>
      <div
        className={`${checkedValue.length === 0 ? css.selectContainer : css.selectContainerWithoutImage}`}
        onClick={changeVisibilityModal}>
       <span className={css.selectContainerText}>
         Availability & period {checkedValue.length > 0 ? (': ' + selectedTab + ' Days - ' + checkedValue[0].name) : ''}
       </span>
        {checkedValue.length > 0 &&
          <div className={css.crossImageContainer} onClick={(event) => {
            onPressClear();
            event.stopPropagation();
          }}>
            <img src={crossIcon} className={css.crossImage} alt={'crossIcon'} />
          </div>
        }
      </div>
      {isActive && (
        <div className={`${css.modalContainer}`}>
          <div className={css.modalContent}>
            <div className={css.listContainer}>
              <div className={css.daysMainContainer}>
                <div
                  onClick={() => {
                    setSelectedTab(7);
                    onChangeSelectedPeriod(7);
                  }}
                  className={`${selectedTab === 7 ? css.daysButtonContainerActive : css.daysButtonContainerInactive}`}>
                  <span className={`${selectedTab === 7 ? css.activeButton : css.inactiveButton}`}>7 Days</span>
                </div>
                <div
                  onClick={() => {
                    setSelectedTab(14);
                    onChangeSelectedPeriod(14);
                  }}
                  className={`${selectedTab === 14 ? css.daysButtonContainerActive : css.daysButtonContainerInactive}`}>
                  <span className={`${selectedTab === 14 ? css.activeButton : css.inactiveButton}`}>14 Days</span>
                </div>
                <div
                  onClick={() => {
                    setSelectedTab(28);
                    onChangeSelectedPeriod(28);
                  }}
                  className={`${selectedTab === 28 ? css.daysButtonContainerActive : css.daysButtonContainerInactive}`}>
                  <span className={`${selectedTab === 28 ? css.activeButton : css.inactiveButton}`}>28 Days</span>
                </div>
              </div>
              {filters.map((filter, index) => (
                <div className={css.itemContainer} onClick={() => {
                  handleCheckboxChange(index);
                }}>
                  <div className={css.itemNameContainer}>
                    <div className={css.colorItem} style={{ backgroundColor: filter.color }} />
                    <span className={css.itemName}>{filter.name}</span>
                  </div>
                  {!filter.checked ?
                    <div className={css.uncheckedRadioButton} />
                    :
                    <img src={checkedRadioButton} className={css.checkedRadioButton} alt={'checkedRadioButton'} />
                  }
                </div>
              ))}
            </div>
            <div className={css.buttonsContainer}>
              <span onClick={onPressClear}>Clear</span>
              <div className={css.applyButton} onClick={onPressApply}>
                <span>Apply</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
