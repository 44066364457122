import React, { useState } from 'react';
import css from './FilterModal.module.css';
import magnifyGlass from './../../assets/images/magnifyingGlass.png';

const FilterModal = ({ onClose, onSearch, formFilters, onClick, modalContainerCustom }) => {
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState(formFilters);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleApplySearch = () => {
    const checkedFilters = filters.filter(filter => filter.checked).map(filter => filter.key);
    onSearch(null, searchText, checkedFilters);
  };

  const handleCheckboxChange = (index) => {
    setFilters(filters.map((filter, i) => {
      if (i === index) {
        return {...filter, checked: !filter.checked};
      } else {
        return filter;
      }
    }));
  };

  return (
    <div className={`${css.modalContainer} ${modalContainerCustom || {}}`} onClick={onClick}>
      <div className={css.modalContent}>
        <span className={css.closeButton} onClick={onClose}>✖</span>
        <div className={css.searchContainer}>
          <input
            type='text'
            value={searchText}
            onChange={handleSearchChange}
            className={css.searchInputModal}
            placeholder='Enter search text'
          />
          <button onClick={handleApplySearch} className={css.magnifyButton}>
            <img src={magnifyGlass} alt='Search' />
          </button>
        </div>
        <div className={css.listContainer}>
          {formFilters.map((item, index) => (
            <div key={index} className={css.filterItemContainer} onClick={() => handleCheckboxChange(index)}>
              <input
                type='checkbox'
                id={`item${index}`}
                name={`item${index}`}
                className={css.checkBox}
                value={item.checked}/>
              <label htmlFor={`item${index}`} className={css.itemName}>{item.title}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
