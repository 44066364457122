import css from './DeleteEventModal.module.css';
import closeButton from '../../../../assets/images/close-button.png';

export const DeleteEventModal = ({ onChangeVisibility, onDeleteEvent, handleEditEvent, isBooking }) => {
  return (
    <div className={css.mainModalContainer}>
      <div className={css.modalContentContainer}>
        <div className={css.headerContainer}>
          <span className={css.deleteEvent}>{isBooking ? 'Delete Booked Event?' : 'Delete Event?'}</span>
          <img
            src={closeButton}
            alt={'closeButton'}
            className={css.closeButton}
            onClick={onChangeVisibility}
          />
        </div>
        <div className={css.mainTextContainer}>
          <span>Are you sure you want to delete the existing {isBooking ? 'booked' : ''} event</span>
          <span className={css.orDeleteText}>or <span
            onClick={() => {
              onChangeVisibility();
              handleEditEvent(true);
            }} style={{ color: '#0CADD3' }}>Delete More?</span></span>
        </div>
        <div className={css.buttonsContainer}>
          <div className={css.cancelButtonContainer} onClick={() => onChangeVisibility()}>
            <span className={css.cancelText}>Cancel</span>
          </div>
          <div className={css.deleteButtonContainer} onClick={onDeleteEvent}>
            <span className={css.deleteText}>Delete</span>
          </div>
        </div>
      </div>
    </div>
  );
};
