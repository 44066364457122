import axios from 'axios';
import { error } from '../helpers/notyf';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const URL = process.env.REACT_APP_URL;

axios.defaults.withCredentials = true;

const LOG_IN_DEV = credantials =>
  axios.get(`${BASE_URL}/sanctum/csrf-cookie`).then(() =>
    axios
      .post(`${URL}/auth/login`, credantials)
      .then(response => {
        localStorage.setItem('ACCESS_TOKEN', response.data.data.access_token);
        return response.data.status;
      })
      .catch(er => {
        error(er.response.data);
        er.response.status === 401 && window.location.replace('/login');
      }),
  );

const LOG_IN_LINK = () =>
  axios.get(`${BASE_URL}/sanctum/csrf-cookie`).then(() =>
    axios
      .get(`${URL}/auth/login-link`)
      .then(response => response.data.data.link)
      .catch(er => {
        error(er.response.data);
        er.response.status === 401 && window.location.replace('/login');
      }),
  );

const LOG_IN_BY_TOKEN = credantials =>
  axios
    .post(`${URL}/auth/login-by-token`, credantials)
    .then(response => {
      localStorage.setItem('ACCESS_TOKEN', response.data.data.access_token);

      return response.data.status;
    })
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const AUTH_ME = () =>
  axios
    .get(`${URL}/auth/me`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_FORMS = page =>
  axios
    .get(`${URL}/booking-forms?exclude[status]=archive&sort=-id&page=${page}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => ({
      items: response.data.data.items,
      lastPage: response.data.data.lastPage,
    }))
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_FILTERED_FORMS = (filter, page) =>
  axios
    .get(
      `${URL}/booking-forms?filter[status]=${filter}&sort=-id&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => ({
      items: response.data.data.items,
      lastPage: response.data.data.lastPage,
    }))
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_SEARCHED_FORMS = (sortBy, search, keys, value) =>
  axios
    .get(`${URL}/booking-forms?${value === 'all' ? 'excluded' : 'filter'}[status]=${value}&sort=${sortBy}&filter[search]=${search}&filter[keys]=${keys}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.data.items)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });
const GET_SORTED_FORMS = (sortBy) =>
  axios
    .get(`${URL}/booking-forms?exclude[status]=archive&sort=${sortBy}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.data.items)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_FORMS_OPTIONS = () =>
  axios
    .get(`${URL}/reference-book?only[]=booking_form_statuses&only[]=form_filters`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const ARCHIVATE_FORM = data =>
  axios
    .post(`${URL}/booking-forms/actions/archivate-selected`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.status)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const DELETE_FORM = id =>
  axios
    .delete(`${URL}/booking-forms/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.status)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_USERS = () =>
  axios
    .get(`${URL}/users`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.data.items)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_SORTED_USERS = sortBy =>
  axios
    .get(`${URL}/users?sort=${sortBy}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.data.items)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const LOG_OUT = () =>
  axios
    .post(
      `${URL}/auth/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(() => localStorage.clear())
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_REF_BOOK = () =>
  axios
    .get(`${URL}/reference-book`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_APPLICANT = id =>
  axios
    .get(`${URL}/booking-forms/actions/get-applicant?external_id=${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_WORK_HISTORY = id =>
  axios
    .get(
      `${URL}/booking-forms/actions/get-applicant-work-history?external_id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const SET_AND_GET_DATA_BY_PLACEMENT_ID = (formData, id) =>
  axios
    .post(
      `${URL}/booking-forms/actions/get-job-data?placement_external_id=${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const SET_AND_GET_DATA_BY_JOB_ID = (formData, id) =>
  axios
    .post(
      `${URL}/booking-forms/actions/get-job-data?job_external_id=${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const SET_AND_GET_DATA_BY_CLIENT_ID = (formData, id) =>
  axios
    .post(
      `${URL}/booking-forms/actions/get-job-data?client_external_id=${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const SAVE_FORM_AS_DRAFT = formData =>
  axios
    .post(`${URL}/booking-forms/actions/save-draft`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.status)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const SHOW_FORM = id =>
  axios
    .get(`${URL}/booking-forms/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const APPLICANT_RESUME = id =>
  axios
    .post(
      `${URL}/booking-forms/actions/applicant-resume/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data.status)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const CREATE_PLACEMENT = formData =>
  axios
    .post(`${URL}/booking-forms`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.status)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_DOCUMENTS = (id, only) =>
  axios
    .get(
      `${URL}/booking-forms/actions/${id}/documents${
        only ? `?only=${only}` : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const DOWNLOAD_DOCUMENT = (id, doc) =>
  axios
    .post(`${URL}/booking-forms/actions/${id}/documents`, doc, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
      responseType: 'blob',
    })
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const SEND_EMAIL = (id, data) =>
  axios
    .post(
      `${URL}/booking-forms/actions/${id}/send-email`,
      {
        ...data,
        microsoft_access_token: localStorage.getItem('MICROSOFT_ACCESS_TOKEN'),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data.status)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_EMAIL_DATA = (id) =>
  axios
    .get(
      `${URL}/booking-forms/actions/${id}/email-data`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const UPDATE_DOCUMENT = async (id, data) => {
  const formData = new FormData();

  formData.append('filename', data.filename);
  formData.append('file', data.file);
  formData.append('_method', 'PUT');

  try {
    const response = await axios.post(`${URL}/booking-forms/actions/${id}/documents`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        'Content-Type': 'multipart/form-data', // Add this line
      },
    });

    return response.data;
  } catch (er) {
    error(er.response.data);
    if (er.response.status === 401) {
      window.location.replace('/login');
    }
  }
};

const GET_FILES = (files) =>
  axios
    .post(`${URL}/booking-forms/actions/notes/download-files`, { files: files }, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => {

      let blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      let fileName = 'default.pdf'; // default file name if 'content-disposition' header is not found

      let headerLine = response.headers['content-disposition'];
      if (headerLine) {
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        fileName = headerLine.substring(startFileNameIndex, endFileNameIndex);
        if (fileName === '') {
          let parts = headerLine.split('=');
          fileName = parts[1].trim();
        }
      }

      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.target = '_blank';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });


const CHANGE_EDITING = (id) =>
  axios
    .delete(
      `${URL}/booking-forms/actions/${id}/edited-by`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const INIT_AVAILABILITY = () => {
  const url = `${URL}/availability/init`;

  return axios
    .get(
      url,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });
};

const GET_APPLICANTS = (page, recruiters, search, selectedSector, selectedSpecialty, selectedGrade, selectedApplicantStatuses, selectedPositions, selectedTrust, selectedHospitals, selectedComplianceStatus, availability_type, availability_period, dateFrom, trustFilterType) => {
  const recruitersQueryParam = recruiters && recruiters.length > 0
    ? recruiters
      .filter(id => id !== 'all')
      .map(id => `recruiters[]=${id}`)
      .join('&')
    : '';

  const sectorsQueryParam = selectedSector && selectedSector.length > 0
    ? selectedSector
      .map(id => `sectors[]=${id}`)
      .join('&')
    : '';

  let specialtiesQueryParam = selectedSpecialty && selectedSpecialty.length > 0
    ? selectedSpecialty
      .map(id => `specialties[]=${id}`)
      .join('&')
    : '';

  let gradesQueryParam = selectedGrade && selectedGrade.length > 0
    ? selectedGrade
      .map(id => `grades[]=${id}`)
      .join('&')
    : '';

  let positionsQueryParam1 = selectedPositions && selectedPositions.length > 0
    ? selectedPositions
      .map(id => `positions[]=${id}`)
      .join('&')
    : '';

  let selectedApplicantStatusesQueryParam = selectedApplicantStatuses && selectedApplicantStatuses.length > 0
    ? selectedApplicantStatuses
      .map(id => `applicant_statuses[]=${id}`)
      .join('&')
    : '';

  let selectedHospitalsQueryParam = selectedHospitals && selectedTrust && selectedHospitals.length > 0
    ? selectedHospitals
      .map(id => `hospitals[]=${id}`)
      .join('&')
    : '';

  let selectedComplianceQueryParam = selectedComplianceStatus && selectedComplianceStatus.length > 0
    ? selectedComplianceStatus
      .map(id => `compliance_statuses[]=${id}`)
      .join('&')
    : '';

  const url = `${URL}/availability/recruiter-applicants?${recruitersQueryParam}${recruitersQueryParam ? '&' : ''}page=${page}${search ? '&search=' + search : ''}${sectorsQueryParam ? '&' + sectorsQueryParam : ''}${gradesQueryParam ? '&' + gradesQueryParam : ''}${selectedApplicantStatusesQueryParam ? '&' + selectedApplicantStatusesQueryParam : ''}${selectedComplianceQueryParam ? '&' + selectedComplianceQueryParam : ''}${specialtiesQueryParam ? '&' + specialtiesQueryParam : ''}${selectedHospitalsQueryParam ? '&' + selectedHospitalsQueryParam : ''}${positionsQueryParam1 ? '&' + positionsQueryParam1 : ''}${selectedTrust ? '&trust=' + selectedTrust : ''}${availability_type ? '&availability_type=' + availability_type : ''}${availability_type ? '&availability_period=' + availability_period : ''}&availability_start_date=${dateFrom}&trust_filter_type=${trustFilterType}`;
  return axios
    .get(
      url,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });
};

const GET_MORE_APPLICANTS = (page, recruiter, trust_filter_type, availability_type, availability_period, recruiters, selectedSector, selectedSpecialty, selectedGrade, selectedPositions, selectedApplicantStatuses, selectedHospitals, selectedTrust, selectedComplianceStatus, search, dateFrom) => {
  const sectorsQueryParam = selectedSector && selectedSector.length > 0
    ? selectedSector
      .map(id => `sectors[]=${id}`)
      .join('&')
    : '';

  let specialtiesQueryParam = selectedSpecialty && selectedSpecialty.length > 0
    ? selectedSpecialty
      .map(id => `specialties[]=${id}`)
      .join('&')
    : '';

  let gradesQueryParam = selectedGrade && selectedGrade.length > 0
    ? selectedGrade
      .map(id => `grades[]=${id}`)
      .join('&')
    : '';

  let positionsQueryParam1 = selectedPositions && selectedPositions.length > 0
    ? selectedPositions
      .map(id => `positions[]=${id}`)
      .join('&')
    : '';

  let selectedApplicantStatusesQueryParam = selectedApplicantStatuses && selectedApplicantStatuses.length > 0
    ? selectedApplicantStatuses
      .map(id => `applicant_statuses[]=${id}`)
      .join('&')
    : '';

  let selectedHospitalsQueryParam = selectedHospitals && selectedTrust && selectedHospitals.length > 0
    ? selectedHospitals
      .map(id => `hospitals[]=${id}`)
      .join('&')
    : '';

  let selectedComplianceQueryParam = selectedComplianceStatus && selectedComplianceStatus.length > 0
    ? selectedComplianceStatus
      .map(id => `compliance_statuses[]=${id}`)
      .join('&')
    : '';

  return axios
    .get(
      `${URL}/availability/applicants?recruiters[]=${recruiter}&page=${page}${search ? '&search=' + search : ''}${sectorsQueryParam ? '&' + sectorsQueryParam : ''}${gradesQueryParam ? '&' + gradesQueryParam : ''}${selectedApplicantStatusesQueryParam ? '&' + selectedApplicantStatusesQueryParam : ''}${selectedComplianceQueryParam ? '&' + selectedComplianceQueryParam : ''}${specialtiesQueryParam ? '&' + specialtiesQueryParam : ''}${selectedHospitalsQueryParam ? '&' + selectedHospitalsQueryParam : ''}${positionsQueryParam1 ? '&' + positionsQueryParam1 : ''}${selectedTrust ? '&trust=' + selectedTrust : ''}${availability_type ? '&availability_type=' + availability_type : ''}&availability_period=${availability_period}&availability_start_date=${dateFrom}&trust_filter_type=${trust_filter_type}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });
};

const GET_APPLICANT_DETAIL = (id) =>
  axios
    .get(
      `${URL}/availability/applicants/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_CALENDAR_DATA = (date_from, date_to, applicant_id, dataType) =>
  axios
    .get(
      `${URL}/availability/calendar?start_date=${date_from}&end_date=${date_to}${applicant_id ? '&applicant_id=' + applicant_id : ''}&driver=${!dataType ? 'local' : 'external'}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const GET_HOSPITALS_BY_TRUST = (
  trust_id,
  selectedRecruiter,
  searchApplicant,
  selectedSector,
  selectedSpecialty,
  selectedGrade,
  selectedApplicantStatuses,
  selectedPositions,
  selectedHospitals,
  selectedComplianceStatus,
  trustFilterType,
) => {
  const params = new URLSearchParams();
  if (searchApplicant) {
    params.append('search', searchApplicant);
  }
  selectedSector.forEach(id => params.append('sectors[]', id));
  selectedRecruiter.forEach(id => {
    if (id !== 'all') {
      params.append('recruiters[]', id);
    }
  });
  selectedApplicantStatuses.forEach(id => params.append('applicant_statuses[]', id));
  selectedComplianceStatus.forEach(id => params.append('compliance_statuses[]', id));
  selectedHospitals.forEach(id => {
    if (trust_id) {
      params.append('hospitals[]', id);
    }
  });
  selectedPositions.forEach(id => params.append('positions[]', id));
  selectedSpecialty.forEach(id => params.append('specialties[]', id));
  selectedGrade.forEach(id => params.append('grades[]', id));
  if (trustFilterType) {
    params.append('trust_filter_type', trustFilterType);
  }

  const queryString = params.toString();
  const requestUrl = `${URL}/availability/hospitals?trust=${trust_id}${queryString ? '&' + queryString : ''}`;
  return axios
    .get(
      requestUrl,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      if (er.response.status === 401) {
        window.location.replace('/login');
      }
    });
};


const GET_EVENT_DATA = (event_id, applicant_id, date) =>
  axios
    .get(
      `${URL}/availability/events/get-data?${event_id ? 'event_id=' + event_id : ''}${'&applicant_id=' + applicant_id}${'&date=' + date}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const SAVE_EVENT = (data) =>
  axios
    .post(
      `${URL}/availability/events/save`,
      {
        ...data,
        microsoft_access_token: localStorage.getItem('MICROSOFT_ACCESS_TOKEN'),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
      throw new Error(er);
    });

const DELETE_EVENT = id =>
  axios
    .delete(`${URL}/availability/events/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
    })
    .then(response => response.data.status)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });

const CHANGE_PINS = (recruiter_id, applicant_id) =>
  axios
    .post(
      `${URL}/availability/pins?recruiter_id=${recruiter_id}&applicant_id=${applicant_id}`,
      {
        microsoft_access_token: localStorage.getItem('MICROSOFT_ACCESS_TOKEN'),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data.status)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
    });


const GET_FILTERS_DATA = (
  searchApplicant,
  selectedSector,
  selectedApplicantStatuses,
  selectedComplianceStatus,
  selectedTrust,
  selectedHospitals,
  selectedPositions,
  selectedSpecialty,
  selectedGrade,
  selectedRecruiter,
  trustFilterType,
) => {
  const params = new URLSearchParams();
  if (searchApplicant) {
    params.append('search', searchApplicant);
  }
  selectedSector.forEach(id => params.append('sectors[]', id));
  (Array.isArray(selectedRecruiter) ? selectedRecruiter : []).forEach(id => {
    if (id !== 'all') {
      params.append('recruiters[]', id);
    }
  });
  selectedApplicantStatuses.forEach(id => params.append('applicant_statuses[]', id));
  selectedComplianceStatus.forEach(id => params.append('compliance_statuses[]', id));
  if (selectedTrust) {
    params.append('trust', selectedTrust);
  }
  selectedHospitals.forEach(id => {
    if (selectedTrust) {
      params.append('hospitals[]', id);
    }
  });
  selectedPositions.forEach(id => params.append('positions[]', id));
  selectedSpecialty.forEach(id => params.append('specialties[]', id));
  selectedGrade.forEach(id => params.append('grades[]', id));
  if (trustFilterType) {
    params.append('trust_filter_type', trustFilterType);
  }
  const queryString = params.toString();
  const requestUrl = queryString ? `${URL}/availability/filters?${queryString}` : `${URL}/availability/filters`;

  return axios
    .get(
      requestUrl,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      if (er.response.status === 401) {
        window.location.replace('/login');
      }
    });
};

const CHECK_BEFORE_CANCEL = (data) =>
  axios
    .post(
      `${URL}/availability/events/check-before-cancel`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
      return er.response.data;
    });


const CANCEL_BOOKED_EVENT = (data) =>
  axios
    .post(
      `${URL}/availability/events/cancel-booked`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data.status)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
      return er.response.data;
    });

const CALCULATE_RANGE_DATES = (data) =>
  axios
    .post(
      `${URL}/availability/events/calculate-range-dates`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        },
      },
    )
    .then(response => response.data)
    .catch(er => {
      error(er.response.data);
      er.response.status === 401 && window.location.replace('/login');
      return er.response.data;
    });


const api = {
  URL,
  LOG_IN_DEV,
  LOG_IN_LINK,
  LOG_IN_BY_TOKEN,
  AUTH_ME,
  GET_FORMS,
  GET_FILTERED_FORMS,
  GET_SEARCHED_FORMS,
  GET_SORTED_FORMS,
  GET_FORMS_OPTIONS,
  ARCHIVATE_FORM,
  DELETE_FORM,
  GET_USERS,
  GET_SORTED_USERS,
  LOG_OUT,
  GET_REF_BOOK,
  GET_APPLICANT,
  GET_WORK_HISTORY,
  SET_AND_GET_DATA_BY_PLACEMENT_ID,
  SET_AND_GET_DATA_BY_JOB_ID,
  SET_AND_GET_DATA_BY_CLIENT_ID,
  SAVE_FORM_AS_DRAFT,
  SHOW_FORM,
  APPLICANT_RESUME,
  CREATE_PLACEMENT,
  GET_DOCUMENTS,
  DOWNLOAD_DOCUMENT,
  SEND_EMAIL,
  GET_EMAIL_DATA,
  UPDATE_DOCUMENT,
  GET_FILES,
  CHANGE_EDITING,
  INIT_AVAILABILITY,
  GET_APPLICANTS,
  GET_MORE_APPLICANTS,
  GET_APPLICANT_DETAIL,
  GET_CALENDAR_DATA,
  GET_HOSPITALS_BY_TRUST,
  GET_EVENT_DATA,
  SAVE_EVENT,
  DELETE_EVENT,
  CHANGE_PINS,
  GET_FILTERS_DATA,
  CHECK_BEFORE_CANCEL,
  CANCEL_BOOKED_EVENT,
  CALCULATE_RANGE_DATES,
};

export default api;
