import React from 'react';
import css from './Input.module.css';
import closeButton from '../../assets/images/close-button.png';

const Input = ({
                 type,
                 placeholder,
                 value,
                 onChange,
                 onKeyDown,
                 autoFocus,
                 name,
                 readOnly,
                 size,
                 custom,
                 id,
                 withoutAdaptivePlaceholder,
                 required,
                 clear,
                 placeholderStyle,
                 clearButtonStyle,
               }) => (
    <div className={`${css.container} ${css[size]}`}>
      {value && !withoutAdaptivePlaceholder && (
        <span className={`${css.placeholder} ${placeholderStyle || {}}`}> {placeholder}</span>
      )}
      <input
        id={id}
        name={name}
        autoFocus={autoFocus}
        type={type}
        className={`${css.input} ${required && !value ? css.required : null} ${
          size === 'xsm' ? css[size] : null
        } ${custom ? custom : ''}`}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
      />
      {clear && value &&
        <div className={`${css.crossContainer} ${clearButtonStyle || {}}`} style={{right: clearButtonStyle ? clearButtonStyle : '-27px'}}>
          <img
            onClick={() => {
              onChange({ target: { value: '' } });
            }}
            alt='cross'
            className={css.closeButton}
            src={closeButton}
          />
        </div>
      }
    </div>
  )
;

export default Input;
