import React, { useState, useRef, useEffect } from 'react';

import css from './SendEmailModal.module.css';
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import api from '../../../config/api';
import { Oval } from 'react-loader-spinner';
import { confirmSuccessUpdateFile } from '../../../helpers/notyf';
import uploadIcon from '../../../assets/images/uploadIcon.png';

const props = {
  color: 'var(--primary)',
  height: 100,
  width: 110,
  secondaryColor: 'var(--primary)',
};

const EmailSection = ({ showEmailModal, onCloseModal, onSendEmail, emailData, onGeneratePdf, formDataId }) => {
  const { client } = emailData;
  const [subject, setSubject] = useState(client?.subject ?? '');
  const [toEmails, setToEmails] = useState(client?.emails ?? []);
  const [ccEmails, setCCEmails] = useState(client?.cc_emails ?? []);
  const [bccEmails, setBCCEmails] = useState(client?.bcc_emails ?? []);
  const [attachments, setAttachments] = useState(client?.documents ?? []);
  const [body, setBody] = useState(client?.body ?? '');
  const [toEmail, setToEmail] = useState('');
  const [ccEmail, setCCEmail] = useState('');
  const [bccEmail, setBccEmail] = useState('');
  const [recipient, setRecipient] = useState('applicant');
  const [openTextArea, setOpenTextArea] = useState(false);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [filesClient, setFilesClient] = useState([]);
  const [filesApplicant, setFilesApplicant] = useState([]);

  const replaceInputRef = useRef(null);
  const indexRef = useRef(null);


  useEffect(() => {
    handleCheckboxClick(recipient);

  }, [emailData]);

  const handleAddToEmail = () => {
    setToEmails([...toEmails, toEmail]);
    setToEmail('');
  };

  const handleAddCCEmail = () => {
    setCCEmails([...ccEmails, ccEmail]);
    setCCEmail('');
  };

  const handleAddBccEmail = () => {
    setBCCEmails([...bccEmails, bccEmail]);
    setBccEmail('');
  };

  const handleRemoveEmail = (field, index) => {
    switch (field) {
      case 'to':
        const updatedToEmails = [...toEmails];
        updatedToEmails.splice(index, 1);
        setToEmails(updatedToEmails);
        break;
      case 'cc':
        const updatedCCEmails = [...ccEmails];
        updatedCCEmails.splice(index, 1);
        setCCEmails(updatedCCEmails);
        break;
      case 'bcc':
        const updatedBCCEmails = [...bccEmails];
        updatedBCCEmails.splice(index, 1);
        setBCCEmails(updatedBCCEmails);
        break;
      default:
        break;
    }
  };

  const handleSendEmail = async () => {
    setLoadingSendEmail(true);
    const obj = {
      recipient,
      subject,
      emails: toEmails,
      cc_emails: ccEmails,
      bcc_emails: bccEmails,
      body,
      filesSend: recipient === 'applicant' ? filesApplicant : filesClient,
    };
    await onSendEmail(obj);
    setLoadingSendEmail(false);
  };

  const handleSaveBody = (event) => {
    setBody(event.target.value);
  };

  const handleReplaceAttachment = (index) => {
    indexRef.current = index;

    // Make sure replaceInputRef.current is not null before calling click
    if (replaceInputRef.current) {
      replaceInputRef.current.click();
    }
  };

  const handleFileSelected = async (index, e) => {
    const file = e.target.files[0];
    setLoadingIndex(index);

    if (!file) return;  // No file selected
    await api.UPDATE_DOCUMENT(formDataId, { filename: attachments[index].key, file: file, _method: 'PUT' })
      .then(data => data.status === 'OK' && confirmSuccessUpdateFile(data.message));

    // Replace the old attachment with the new one in your state
    setAttachments(prevAttachments => {
      const oldFile = prevAttachments[index];
      const newFile = new File([file], oldFile.name, { type: file.type });
      const newAttachments = [...prevAttachments];
      newAttachments[index] = newFile;
      newAttachments[index].key = oldFile.key;
      return newAttachments;
    });
    setLoadingIndex(null);
  };

  const handleCheckboxClick = (id) => {
    const newData = emailData[id];
    setSubject(newData?.subject ?? '');
    setToEmails(newData?.emails ?? []);
    setCCEmails(newData?.cc_emails ?? []);
    setBCCEmails(newData?.bcc_emails ?? []);
    setAttachments(newData?.documents ?? []);
    setBody(newData?.body ?? '');
    setRecipient(id);
  };

  const handleOpenTextArea = () => {
    setOpenTextArea(!openTextArea);
  };

  const handleImageToBase64 = (event) => {
    const files = event.target.files;
    let qq = [];

    const promises = Array.from(files).map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = function() {
          qq.push({
            name: file.name,
            content: reader.result.split(',')[1],
          });
          resolve();
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    });

    return Promise.all(promises)
      .then(() => {
        return qq;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleImageChange = async (event) => {
    if (recipient === 'applicant') {
      const base64Img = await handleImageToBase64(event);
      setFilesApplicant([...filesApplicant, ...base64Img]);
    } else {
      const base64Img = await handleImageToBase64(event);
      setFilesClient([...filesClient, ...base64Img]);
    }
    event.target.value = null;
  };

  const handleRemoveImage = async (index) => {
    if (recipient === 'applicant') {
      const updatedFiles = [...filesApplicant];
      updatedFiles.splice(index, 1);
      setFilesApplicant(updatedFiles);
    } else {
      const updatedFiles = [...filesClient];
      updatedFiles.splice(index, 1);
      setFilesClient(updatedFiles);
    }
  };

  return (
    <>
      {showEmailModal &&
        <div className={css.modalOverlay}>
          <div className={css.modal}>
            <div className={css.mainContent}>
              <Input
                value={subject}
                size='x100'
                type='text'
                onChange={(e) => setSubject(e.target.value)}
                placeholder='Subject'
              />

              <div className={css.addEmailContainer}>
                <div className={css.emailsContainer}>
                  <h4>To:</h4>
                  {toEmails.map((email, index) => (
                    <div key={index} className={css.modalBorder}>
                      <span>{email}</span>
                      <span onClick={() => handleRemoveEmail('to', index)} className={css.removeIcon}>✖</span>
                    </div>
                  ))}
                </div>
                <div className={css.inputAndButtonContainer}>
                  <Input
                    value={toEmail}
                    size='x70'
                    type='text'
                    placeholder='To Email'
                    required={toEmails.length === 0}
                    onChange={(e) => setToEmail(e.target.value)}
                  />
                  <Button
                    title='Add To Email'
                    type={'secondary'}
                    onClick={handleAddToEmail}
                    style={{ marginLeft: 20, margin: 0, marginBottom: 10, width: 190 }}
                  />
                </div>
              </div>

              <div className={css.addEmailContainer}>
                <div className={css.emailsContainer}>
                  <h4>CC:</h4>
                  {ccEmails.map((email, index) => (
                    <div key={index} className={css.modalBorder}>
                      <span>{email}</span>
                      <span onClick={() => handleRemoveEmail('cc', index)} className={css.removeIcon}>✖</span>
                    </div>
                  ))}
                </div>
                <div className={css.inputAndButtonContainer}>
                  <Input
                    value={ccEmail}
                    size='x70'
                    type='text'
                    placeholder='CC Email'
                    onChange={(e) => setCCEmail(e.target.value)}
                  />
                  <Button
                    title='Add CC Email'
                    type={'secondary'}
                    onClick={handleAddCCEmail}
                    style={{ marginLeft: 20, margin: 0, marginBottom: 10, width: 190 }}
                  />
                </div>
              </div>

              <div className={css.addEmailContainer}>
                <div className={css.emailsContainer}>
                  <h4>BCC:</h4>
                  {bccEmails.map((email, index) => (
                    <div key={index} className={css.modalBorder}>
                      <span>{email}</span>
                      <span onClick={() => handleRemoveEmail('bcc', index)} className={css.removeIcon}>✖</span>
                    </div>
                  ))}
                </div>
                <div className={css.inputAndButtonContainer}>
                  <Input
                    value={bccEmail}
                    size='x70'
                    type='text'
                    placeholder='BCC Email'
                    onChange={(e) => setBccEmail(e.target.value)}
                  />
                  <Button
                    title='Add BCC Email'
                    type={'secondary'}
                    onClick={handleAddBccEmail}
                    style={{ marginLeft: 20, margin: 0, marginBottom: 10, width: 190 }}
                  />
                </div>
              </div>
              <Button
                type={'primary'}
                style={{ position: 'relative', background: 'rgba(13, 85, 130, 0.2)', color: 'var(--secondary)' }}
                onClick={handleOpenTextArea}
                title={!openTextArea ? 'Open email body' : 'Close email body'}>
              </Button>
              {openTextArea &&
                <div className={css.bodyContainer}>
                  <p className={css.bodyTitle}>Body</p>
                  <textarea
                    className={css.textArea}
                    value={body}
                    onChange={handleSaveBody}
                  />
                </div>
              }

              <div className={css.attachmentsNewContainer}>
                <div className={css.attachmentsNewContainer1}>
                  <h4 className={css.h4Attachment}>Attachments:</h4>
                  <div className={css.attachmentsContainer}>
                    {attachments.map((attachment, index) => (
                      <div
                        key={index}
                        className={`${css.attachmentItem} ${css.modalWithoutBorder}`}
                        onClick={() => handleReplaceAttachment(index)}>
                      <span className={css.replaceIcon}>
                        <label
                          htmlFor={`file-input-${index}`}
                          className={loadingIndex === index ? css.uploadLabelGray : css.uploadLabel}>
                          Edit file
                          <input
                            id={`file-input-${index}`}
                            type='file'
                            className={css.inputFile}
                            accept='.pdf'
                            onChange={(e) => handleFileSelected(index, e)}
                            style={{ display: 'none' }}
                          />
                        </label>
                      </span>
                        <span title={attachment.name}>{attachment.key}</span>
                        {loadingIndex === index &&
                          <div className={css.loaderButton}>
                            <Oval {...props} height={20} width={20} />
                          </div>
                        }
                      </div>
                    ))}
                  </div>
                </div>
                <div className={css.attachmentsContainerRight}>
                  <div className={css.additionalFilesContainer}>
                    <h4 className={css.headerName}>Additional attachments:</h4>
                    <div className={css.tooltipWrapper}>
                      <label htmlFor='file-upload' className={css.customFileUpload} data-title='Upload File'>
                        <img
                          alt='Upload File'
                          className={css.uploadIcon}
                          src={uploadIcon}
                        />
                      </label>
                      <input
                        id='file-upload'
                        type='file'
                        accept='.png, .jpg, .jpeg, .docx, .doc, .xls, .xlsx, .pdf'
                        onChange={handleImageChange}
                        className={css.hiddenFileInput}
                        multiple
                      />
                    </div>
                  </div>
                  {recipient === 'applicant' ?
                    <div>
                      {filesApplicant.map((file, index) => (
                        <div
                          key={index}
                          className={`${css.attachmentItem} ${css.modalWithoutBorder}`}
                          onClick={() => handleRemoveImage(index)}>
                      <span className={css.replaceIcon}>
                        <label
                          htmlFor={`file-${index}`}
                          className={loadingIndex === index ? css.uploadLabelGray : css.uploadLabel}>
                         ✖
                          <input
                            id={`file-input-${index}`}
                            type='file'
                            className={css.inputFile}
                            accept='.pdf'
                            style={{ display: 'none' }}
                          />
                        </label>
                      </span>
                          <span title={file.name}>{file.name}</span>
                          {loadingIndex === index &&
                            <div className={css.loaderButton}>
                              <Oval {...props} height={20} width={20} />
                            </div>
                          }
                        </div>
                      ))}
                    </div>
                    :
                    <div>
                      {filesClient.map((file, index) => (
                        <div
                          key={index}
                          className={`${css.attachmentItem} ${css.modalWithoutBorder}`}
                          onClick={() => handleRemoveImage(index)}>
                      <span className={css.replaceIcon}>
                        <label
                          htmlFor={`file-${index}`}
                          className={loadingIndex === index ? css.uploadLabelGray : css.uploadLabel}>
                         ✖
                          <input
                            id={`file-input-${index}`}
                            type='file'
                            className={css.inputFile}
                            accept='.pdf'
                            style={{ display: 'none' }}
                          />
                        </label>
                      </span>
                          <span title={file.name}>{file.name}</span>
                          {loadingIndex === index &&
                            <div className={css.loaderButton}>
                              <Oval {...props} height={20} width={20} />
                            </div>
                          }
                        </div>
                      ))}
                    </div>
                  }
                </div>
              </div>
            </div>

            <div className={css.checkboxContainer}>
              <div className={css.checkboxItem}>
                <label>
                  <input
                    type='radio'
                    name='userType'
                    checked={recipient === 'applicant'}
                    className={css.checkbox}
                    onChange={() => handleCheckboxClick('applicant')}
                  />
                  Applicant
                </label>
              </div>
              <div className={css.checkboxItem}>
                <label>
                  <input
                    type='radio'
                    name='userType'
                    checked={recipient === 'client'}
                    className={css.checkbox}
                    onChange={() => handleCheckboxClick('client')}
                  />
                  Client
                </label>
              </div>
            </div>

            <div className={css.buttonsContainer}>
              <div>
                <Button
                  title='Download PDFs Documents'
                  type={'secondary'}
                  onClick={() => {
                    onGeneratePdf(recipient);
                  }}
                />
                <Button
                  type={'secondary'}
                  style={{ minWidth: 170, marginLeft: 10 }}
                  disabled={false}
                  onClick={handleSendEmail}>
                  {loadingSendEmail ?
                    <div className={css.loaderButtonEmail}>
                      <Oval {...props} height={13} width={13} />
                    </div>
                    :
                    'Send e-mail'
                  }
                </Button>
              </div>
              <Button
                style={{ minWidth: 170 }}
                disabled={false}
                onClick={onCloseModal}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      }

    </>
  );
};

export default EmailSection;
