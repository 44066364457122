import css from './DetailCalendarView.module.css';
import React, { useState } from 'react';
import arrowLeft from '../../../../assets/images/arrowLeft.png';
import arrowRight from '../../../../assets/images/arrowRight.png';
import { Oval } from 'react-loader-spinner';
import moment from 'moment';
import { ViewEventModal } from '../ViewEventModal/ViewEventModal';
import { AddNewEventModal } from '../AddNewEventModal/AddNewEventModal';
import plusBlackIcon from '../../../../assets/images/plusBlackIcon.png';
import api from '../../../../config/api';
import { SendDeleteEmailModal } from '../SendDeleteEmailModal/SendDeleteEmailModal';

const props = {
  color: 'var(--primary)',
  height: 100,
  width: 110,
  secondaryColor: 'var(--primary)',
};

export const DetailCalendarView = ({
                                     applicantDetail,
                                     loadingDates,
                                     onGenerateQuarterCalendar,
                                     handleChangeYear,
                                     onGetEvenData,
                                     onGetCalendarData,
                                     eventDataLocal,
                                     setGlobalLoading,
                                     openGlobalDelete,
                                     onToggleDeleteMailModal,
                                     setSendData,
                                     sendData
                                   }) => {
  const [quarterly, setQuarterly] = useState(true);
  const [isOpenViewEvent, setIsOpenViewEvent] = useState('');
  const [isOpenCreateEvent, setIsOpenCreateEvent] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isBooking, setIsBooking] = useState(false);

  const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  const totalDays = 37;
  const daysToRender = Array.from({ length: totalDays }, (_, index) => weekDays[index % 7]);

  const colorNameToRgb = {
    'white': '255, 255, 255',
    'red': '235, 50, 35',
    'orange': '235, 151, 0',
    'green': '78, 172, 91',
  };
  const colorName = {
    'red': '#EB3223',
    'orange': '#EB9723',
    'green': '#4EAC5B',
  };

  function colorNameToRgba(colorName, opacity) {
    const rgb = colorName ? colorNameToRgb[colorName.toLowerCase()] : '255, 255, 255';
    return `rgba(${rgb}, ${opacity})`;
  }

  function isWeekend(dateInput) {
    const date = new Date(typeof dateInput === 'number' ? dateInput : dateInput);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6;
  }

  const checkIfCanDelete = async () => {
    let newEventData = [];
    let myEventDataCopy = JSON.parse(JSON.stringify(eventDataLocal?.events ?? []));
    myEventDataCopy.map((event) => {
      newEventData.push({
        type_id: 31,
        event_id: event.id,
      });
    });
    const data = {
      'mail_data': {
        'microsoft_access_token': localStorage.getItem('MICROSOFT_ACCESS_TOKEN'),
      },
      'tab': 'ad_hoc',
      'applicant_id': applicantDetail.id,
      'events': newEventData,
    };
    const result = await api.CHECK_BEFORE_CANCEL(data);
    if (result.status !== 'FAILED') {
      setSendData(data);
      setSendData({ ...data, mail_data: result?.data?.mail_data ?? {} });
      onToggleDeleteMailModal();
    }
  };


  return (
    <div className={css.detailContainer}>
      <div className={css.selectContainer}>
        <div className={css.buttonsContainer}>
          <div
            className={css.buttonContainer}
            style={{ backgroundColor: quarterly ? '#0CADD3' : 'white' }}
            onClick={() => {
              setIsDelete(false);
              setQuarterly(true);
              setIsOpenViewEvent('');
              setIsOpenCreateEvent('');
            }}>
            <span className={css.buttonText} style={{ color: quarterly ? '#ffffff' : '#2E2E30' }}>Quarterly</span>
          </div>
          <div
            className={css.buttonContainer}
            style={{ backgroundColor: quarterly ? 'white' : '#0CADD3' }}
            onClick={() => {
              setIsDelete(false);
              setQuarterly(false);
              setIsOpenCreateEvent('');
            }}>
            <span className={css.buttonText} style={{ color: quarterly ? '#2E2E30' : '#FFFFFF' }}>Yearly</span>
          </div>
        </div>
        {!quarterly &&
          <div className={css.yearSelectContainer}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleChangeYear(applicantDetail.id, false);
              }}>
              <img src={arrowLeft} alt={'arrowLeft'} className={css.arrowIconBig} />
            </div>
            <span className={css.yearText}>{applicantDetail?.yearData?.year ?? ''}</span>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleChangeYear(applicantDetail.id, true);
              }}>
              <img src={arrowRight} alt={'arrowRight'} className={css.arrowIconBig} />
            </div>
          </div>
        }
      </div>
      {loadingDates && (
        <div className={css.loader}>
          <Oval {...props} height={50} width={50} />
        </div>
      )}
      {quarterly &&
        <div className={css.quarterlyContainer}>
          <div
            className={css.bigButtonContainer}
            onClick={() => {
              onGenerateQuarterCalendar(applicantDetail.id, false);
            }}>
            <img src={arrowLeft} alt={'arrowLeft'} className={css.arrowIcon} />
          </div>
          {(applicantDetail?.quarterData ?? []).map((quarter, quarterIndex) => (
            <div className={css.quarterContainer} key={`quarter_${quarterIndex}`}>
              <span className={css.monthName}>{quarter.monthName}</span>
              <div className={css.datesQuarterContainer}>
                <div className={css.weekDayQuarterContainer}>
                  <span className={css.weekDayQuarter}>Mo</span>
                  <span className={css.weekDayQuarter}>Tu</span>
                  <span className={css.weekDayQuarter}>We</span>
                  <span className={css.weekDayQuarter}>Th</span>
                  <span className={css.weekDayQuarter}>Fr</span>
                  <span className={css.weekDayQuarterGray}>Sa</span>
                  <span className={css.weekDayQuarterGray}>Su</span>
                </div>
                <div style={{ border: '1px solid #E7E7E8', marginRight: '-4px' }}>
                  <div className={css.dateGrid}>
                    {quarter.dates.map((date, index) => {
                      const currentMonthYear = moment().format('MMMM YYYY');
                      const isCurrentMonthYear = currentMonthYear === quarter.monthName;
                      let isToday = false;
                      if (isCurrentMonthYear) {
                        isToday = moment().isSame(moment(date.date), 'day');
                      }
                      const dynamicStyle = (date?.color ?? '') === 'orangeTransparent' ? {
                        borderWidth: 2,
                        borderColor: '#EB9723',
                        borderStyle: 'solid',
                        backgroundColor: '#EB97234D',
                      } : {};
                      return (
                        <div
                          onClick={() => {
                            if (!date?.value ?? false) {
                              if (isOpenCreateEvent === '') {
                                onGetEvenData(applicantDetail.id, date, quarter.monthName);
                                setIsOpenCreateEvent(`${quarter.monthName}_${date.date}_${index}_create`);
                                setIsDelete(false);
                                setIsBooking(false);
                              }
                            } else {
                              if (isOpenViewEvent === '' && isOpenCreateEvent === '') {
                                onGetEvenData(applicantDetail.id, date, quarter.monthName);
                                setIsOpenViewEvent(`${quarter.monthName}_${date.date}_${index}`);
                                setIsDelete(false);
                                setIsBooking(false);
                              }
                            }
                          }}
                          style={{
                            backgroundColor:
                              (isOpenCreateEvent === `${quarter.monthName}_${date.date}_${index}_create`) ? '#E7E7E8' :
                                (date.color !== '' && date.color !== 'white') ? (isWeekend(date?.date ?? date.timestamp) ? colorNameToRgba(date.color, 0.7) : colorNameToRgba(date.color, date.isInCurrentMonth ? 1 : 0.3)) :
                                  isWeekend(date?.date ?? date.timestamp) ? '#F3F3F3' : 'white',
                            ...dynamicStyle,
                          }}
                          className={css.dateItemContainer}
                          key={`date_${index + quarter.monthName}`}>
                          {(isOpenCreateEvent === `${quarter.monthName}_${date.date}_${index}_create` && !isEdit) ?
                            <div className={css.plusContainer}>
                              <img src={plusBlackIcon} alt={'plusBlackIcon'} className={css.plusBlackIcon} />
                            </div>
                            :
                            <>
                              <span className={css.dateItemText} style={{
                                color: (date?.color ?? '') === 'orangeTransparent' ? 'black' : (date.color && date.color !== 'white') ? 'white' : 'black',
                                opacity: date.isInCurrentMonth ? 1 : (!date.color || date.color === 'white' ? 0.3 : 1),
                              }}>
                                {moment(date.date).format('D')}
                              </span>
                              {date.value &&
                                <span
                                  className={css.dateItemValue}
                                  style={{ color: (date?.color ?? '') === 'orangeTransparent' ? 'rgb(58,58,58)' : 'white' }}>{date.value}</span>
                              }
                              {isToday && <span className={css.todayIndicator} />}
                            </>
                          }
                          {isOpenViewEvent === `${quarter.monthName}_${date.date}_${index}` &&
                            <ViewEventModal
                              openGlobalDelete={(id, applicantId, handleEditEvent, isBookingNew) => {
                                openGlobalDelete(id, applicantId, handleEditEvent, isBookingNew);
                                setIsDelete(true);
                              }}
                              setGlobalLoading={setGlobalLoading}
                              month={quarter.monthName}
                              calendarItem={date}
                              onChangeVisibility={() => {
                                setIsOpenViewEvent('');
                              }}
                              applicantId={applicantDetail.id}
                              onGetCalendarData={() => {
                                onGetCalendarData(true);
                                onGenerateQuarterCalendar(applicantDetail.id, undefined, undefined, true);
                              }}
                              onEditEvent={(e, isBookingNew, isOnlyCheck) => {
                                if (isOnlyCheck) {
                                  checkIfCanDelete();
                                } else {
                                  setIsOpenCreateEvent(`${quarter.monthName}_${date.date}_${index}_create`);
                                  setIsEdit(true);
                                }
                                setIsBooking(isBookingNew);
                                setIsOpenViewEvent('');
                              }}
                              index={quarterIndex > 1 ? 20 : 0}
                            />
                          }
                          {isOpenCreateEvent === `${quarter.monthName}_${date.date}_${index}_create` &&
                            <AddNewEventModal
                              setGlobalLoading={setGlobalLoading}
                              isEdit={isEdit}
                              calendarItem={date}
                              isBooking={isBooking}
                              setSendData={setSendData}
                              onChangeDeleteModal={onToggleDeleteMailModal}
                              sendData={sendData}
                              applicantId={applicantDetail.id}
                              index={quarterIndex > 1 ? 20 : 0}
                              onGetCalendarData={() => {
                                onGetCalendarData(true);
                                onGenerateQuarterCalendar(applicantDetail.id, undefined, undefined, true);
                              }}
                              onChangeVisibility={() => {
                                setIsOpenCreateEvent('');
                                setIsEdit(false);
                              }}
                              isDeleteEvent={isDelete}
                            />
                          }
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div
            className={css.bigButtonContainer}
            onClick={() => {
              onGenerateQuarterCalendar(applicantDetail.id, true);
            }}>
            <img src={arrowRight} alt={'arrowLeft'} className={css.arrowIcon} />
          </div>
        </div>
      }
      {!quarterly &&
        <div className={css.yearlyContainer}>
          <div className={css.weekDayYearContainer}>
            {daysToRender.map((day, index) => (
              <span key={index} className={day === 'Sa' || day === 'Su' ? css.weekDayYearGray : css.weekDayYear}>
                {day}
              </span>
            ))}
          </div>
          <div className={css.monthsContainer}>
            <div className={css.colContainer}>
              {(applicantDetail?.yearData?.items ?? []).map((year, index) => (
                <div className={css.monthItemContainer} key={index}>
                  <span className={css.monthNameText}>{year.monthName}</span>
                </div>
              ))}
            </div>

            <div className={css.colContainer1}>
              {(applicantDetail?.yearData?.items ?? []).map((year, yearIndex) => (
                <div className={css.datesContainer} key={yearIndex}>
                  {year.dates.map((date, dateIndex) => {
                    const isToday = date.date !== '-' && moment().isSame(moment(date.date), 'day');
                    const dynamicStyle = {
                      borderWidth: 1,
                      borderColor: '#EB9723',
                      borderStyle: 'solid',
                      backgroundColor: '#EB97234D',
                      position: 'absolute',
                    };
                    return (
                      <div
                        onClick={() => {
                          if (!date?.value ?? false) {
                            if (isOpenCreateEvent === '') {
                              onGetEvenData(applicantDetail.id, date, year.monthName, year);
                              setIsOpenCreateEvent(`${year.monthName}_${date.date}_${dateIndex}_create`);
                              setIsDelete(false);
                              setIsBooking(false);
                            }
                          } else {
                            if (isOpenViewEvent === '' && isOpenCreateEvent === '') {
                              onGetEvenData(applicantDetail.id, date, year.monthName, year);
                              setIsOpenViewEvent(`${year.monthName}_${date.date}_${dateIndex}`);
                              setIsDelete(false);
                              setIsBooking(false)
                            }
                          }
                        }}
                        className={css.dateContainer}
                        key={dateIndex}
                        style={{
                          borderTop: yearIndex === 0 ? 'none' : '1px solid #E7E7E8',
                          borderRight: dateIndex === year.dates.length - 1 ? 'none' : '1px solid #E7E7E8',
                          backgroundColor: isOpenCreateEvent === `${year.monthName}_${date.date}_${dateIndex}_create`
                            ? '#E7E7E8'
                            : (date.color && date.color !== 'white' ? (isWeekend(date?.date ?? date.timestamp) ? colorNameToRgba(date.color, 0.7) : colorName[date.color]) : isWeekend(date?.date ?? date.timestamp) ? '#F3F3F3' : 'white'),
                        }}>
                        {(date?.color ?? '') === 'orangeTransparent' &&
                          <div className={css.dateContainer1} style={dynamicStyle} />
                        }
                        {(isOpenCreateEvent === `${year.monthName}_${date.date}_${dateIndex}_create` && !isEdit) ?
                          <div className={css.plusContainerYear}>
                            <img src={plusBlackIcon} alt={'plusBlackIcon'} className={css.plusBlackIcon} />
                          </div>
                          :
                          <>
                             <span
                               style={{ color: (date?.color ?? '') === 'orangeTransparent' ? 'rgb(58,58,58)' : (date.color && date.color !== 'white') ? 'white' : 'black' }}
                               className={css.dateNameText}>
                                {date.date === '-' ? ' ' : moment(date.date).format('D')}
                              </span>
                            <span className={css.dateItemValue}
                                  style={{ color: (date?.color ?? '') === 'orangeTransparent' ? 'rgb(58,58,58)' : 'white' }}>
                                {date.value}
                              </span>
                            {isToday && <span className={css.todayIndicator1} />}
                          </>
                        }
                        {isOpenViewEvent === `${year.monthName}_${date.date}_${dateIndex}` &&
                          <ViewEventModal
                            openGlobalDelete={(id, applicantId, handleEditEvent, isBookingNew) => {
                              openGlobalDelete(id, applicantId, handleEditEvent, isBookingNew);
                              setIsDelete(true);
                            }}
                            setGlobalLoading={setGlobalLoading}
                            month={year.monthName}
                            calendarItem={date}
                            applicantId={applicantDetail.id}
                            onChangeVisibility={() => {
                              setIsOpenViewEvent('');
                            }}
                            onGetCalendarData={() => {
                              onGetCalendarData(true);
                              onGenerateQuarterCalendar(applicantDetail.id, undefined, undefined, true);
                            }}
                            onEditEvent={(e, isBookingNew, isOnlyCheck) => {
                              if (isOnlyCheck) {
                                checkIfCanDelete();
                              } else {
                                setIsOpenCreateEvent(`${year.monthName}_${date.date}_${dateIndex}_create`);
                              }
                              setIsBooking(isBookingNew)
                              setIsOpenViewEvent('');
                              setIsEdit(true);
                            }}
                            index={dateIndex}
                          />
                        }
                        {isOpenCreateEvent === `${year.monthName}_${date.date}_${dateIndex}_create` &&
                          <AddNewEventModal
                            setGlobalLoading={setGlobalLoading}
                            isEdit={isEdit}
                            calendarItem={date}
                            isBooking={isBooking}
                            setSendData={setSendData}
                            onChangeDeleteModal={onToggleDeleteMailModal}
                            sendData={sendData}
                            applicantId={applicantDetail.id}
                            index={dateIndex}
                            eventDataGlobal={eventDataLocal}
                            onGetCalendarData={() => {
                              onGetCalendarData(true);
                              onGenerateQuarterCalendar(applicantDetail.id, undefined, undefined, true);
                            }}
                            onChangeVisibility={() => {
                              setIsOpenCreateEvent('');
                              setIsEdit(false);
                            }}
                            isDeleteEvent={isDelete}
                          />
                        }
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    </div>
  );
};
