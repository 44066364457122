import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addApplicant,
  addJob,
  addClient,
} from '../../features/bookingForm/bookingFormSlice';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Select from '../Select/Select';
import vectorDown from '../../assets/images/vectorDown.png';
import vectorUp from '../../assets/images/vectorUp.png';
import warning from '../../assets/images/warning.png';
import css from './ApplicantSection.module.css';
import 'react-datepicker/dist/react-datepicker.css';

const ApplicantSection = () => {
  const [isApplicant, setIsApplicant] = useState(false);
  const applicant = useSelector(state => state.bookingForm.formData.applicant);
  const job = useSelector(state => state.bookingForm.formData.job);
  const client = useSelector(state => state.bookingForm.formData.client);
  const { applicant_statuses, pay_types } = useSelector(
    state => state.referenceBook.bookData,
  );
  const dispatch = useDispatch();

  const handleSectionShowAndHide = () => setIsApplicant(!isApplicant);

  const handleChange = e => {
    if (e.target.name === 'pay_type') {
      if (+e.target.value === 4) {
        dispatch(addClient({ ...client, outside_ir35: true }));
        dispatch(
          addJob({
            ...job,
            pay_type_external_id: pay_types.find(
              type => +type.id === +e.target.value,
            ).external_id,
          }),
        );
      } else if (+e.target.value === 6) {
        dispatch(
          addJob({
            ...job,
            pay_type_external_id: 440,
          }),
        );
      } else
        dispatch(
          addJob({
            ...job,
            pay_type_external_id: pay_types.find(
              type => +type.id === +e.target.value,
            ).external_id,
          }),
        );
    }
    dispatch(addApplicant({ ...applicant, [e.target.name]: e.target.value }));
  };

  return (
    <section>
      <Button
        type="sectionBtn"
        style={
          isApplicant
            ? null
            : {
                background: 'rgba(13, 85, 130, 0.2)',
                color: 'var(--secondary)',
              }
        }
        onClick={handleSectionShowAndHide}
        title="Applicant section"
      >
        <img
          className={css.arrow}
          alt="arrow"
          src={isApplicant ? vectorUp : vectorDown}
        />
      </Button>
      <div
        className={css.section}
        style={isApplicant ? { display: 'flex' } : { display: 'none' }}
      >
        <Input
          name="title"
          value={applicant.title || ''}
          size="x33"
          type="text"
          placeholder="Title"
          onChange={handleChange}
          readOnly
          required
        />
        <Input
          name="first_name"
          value={applicant.first_name || ''}
          size="x33"
          type="text"
          placeholder="Applicant First Name"
          onChange={handleChange}
          readOnly
          required
        />
        <Input
          name="last_name"
          value={applicant.last_name || ''}
          size="x33"
          type="text"
          placeholder="Applicant Last Name"
          onChange={handleChange}
          readOnly
          required
        />
        <Select
          name="status_id"
          value={applicant.status_id || ''}
          options={applicant_statuses}
          size="x25"
          defaultValue="Applicant Status"
          onChange={handleChange}
          required
        />
        <Select
          name="position_id"
          value={applicant.position_id || ''}
          options={applicant.positions}
          size="x25"
          defaultValue="Position"
          onChange={handleChange}
          required
        />
        <Select
          name="specialty_id"
          value={applicant.specialty_id || ''}
          options={applicant.specialties}
          size="x25"
          defaultValue="Specialty"
          onChange={handleChange}
          required
        />
        <Select
          name="grade_id"
          value={applicant.grade_id || ''}
          options={applicant.grades}
          size="x25"
          defaultValue="Grade/band"
          onChange={handleChange}
          required
        />
        <Select
          name="pay_type"
          value={applicant.pay_type || ''}
          options={pay_types}
          size="x33"
          defaultValue="Payment Type"
          onChange={handleChange}
          required
        />
        <Input
          name="ltd_name"
          value={applicant.ltd_name || ''}
          size="x33"
          type="text"
          placeholder="LtD Name"
          onChange={handleChange}
          readOnly
        />
        <Input
          name="ni_number"
          value={applicant.ni_number || ''}
          size="x33"
          type="text"
          placeholder="NI Number"
          onChange={handleChange}
          readOnly
        />
        <Input
          name="gender"
          value={applicant.gender || ''}
          size="x33"
          type="text"
          placeholder="Gender"
          onChange={handleChange}
          readOnly
        />
        <Input
          name="nationality"
          value={applicant.nationality || ''}
          size="x33"
          type="text"
          placeholder="Nationality"
          onChange={handleChange}
          readOnly
          required
        />
        <Input
          name="flag_text"
          value={applicant.flag_text || ''}
          size="x33"
          type="text"
          placeholder="Flag Text"
          onChange={handleChange}
          readOnly
        />
        <Input
          name="file_sign_off_status"
          value={applicant.file_sign_off_status || ''}
          size={applicant.warning ? 'x33' : 'x50'}
          type="text"
          placeholder="Compliance Status"
          onChange={handleChange}
          readOnly
          required
        />
        <Input
          name="expiry_date"
          value={applicant.expiry_date || ''}
          size={applicant.warning ? 'x33' : 'x50'}
          type="text"
          placeholder="Expiry Date"
          onChange={handleChange}
          readOnly
        />
        {applicant.warning && (
          <div className={css.warnMessage}>
            <img className={css.warnImg} alt="warning" src={warning} />
            <p className={css.warnText}>{applicant.warning}</p>
          </div>
        )}
        <Input
          name="registration_number"
          value={applicant.registration_number || ''}
          size="x50"
          type="text"
          placeholder="Registration Number"
          onChange={handleChange}
          readOnly
          required
        />
        <Input
          name="registration_notes"
          value={applicant.registration_notes || ''}
          size="x50"
          type="text"
          placeholder="Registration Notes"
          onChange={handleChange}
          readOnly
        />
        <Input
          name="dbs_number"
          value={applicant.dbs_number || ''}
          size="x50"
          type="text"
          placeholder="DBS Number"
          onChange={handleChange}
          readOnly
        />
        <Input
          name="dbs_notes"
          value={applicant.dbs_notes || ''}
          size="x50"
          type="text"
          placeholder="DBS Notes"
          onChange={handleChange}
          readOnly
        />
      </div>
      <hr
        className={css.hr}
        style={isApplicant ? { marginBottom: '60px' } : null}
      ></hr>
    </section>
  );
};

export default ApplicantSection;
