import css from './ApplicantDetail.module.css';
import arrowRight from '../../../../assets/images/arrowRight.png';
import copyIcon from '../../../../assets/images/copyIcon.png';
import React, { useState } from 'react';
import { customSuccessNotification } from '../../../../helpers/notyf';
import noDataIcon from '../../../../assets/images/noDataIcon.png';
import jobIcon from '../../../../assets/images/jobIcon.png';
import trustIcon from '../../../../assets/images/trustIcon.png';
import hospitalIcon from '../../../../assets/images/hospitalIcon.png';
import dateIcon from '../../../../assets/images/dateIcon.png';
import paymentIcon from '../../../../assets/images/paymentIcon.png';

export const ApplicantDetail = ({ applicant, onPressBooking }) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div className={css.mainTableContainer}>
      <div className={css.tableContainer}>
        <div className={css.tableRow}>
          <div className={css.tableCell}>ID</div>
          <div className={css.tableCellRight}>{applicant?.applicantDetail?.external_id ?? '-'}</div>
        </div>
        <div className={css.tableRow}>
          <div className={css.tableCell}>Status</div>
          <div className={css.tableCellRight}>{applicant?.applicantDetail?.status ?? '-'}</div>
        </div>
        <div className={css.tableRow}>
          <div className={css.tableCell}>Position</div>
          <div
            className={css.tableCellRight}>{(applicant?.applicantDetail?.positions ?? []).length > 0 ? (applicant?.applicantDetail?.positions ?? []).map(position => position.name).join(', ') : '-'}</div>
        </div>
        <div className={css.tableRow}>
          <div className={css.tableCell}>Specialty</div>
          <div
            className={css.tableCellRight}>{(applicant?.applicantDetail?.specialties ?? []).length > 0 ? (applicant?.applicantDetail?.specialties ?? []).map(position => position.name).join(', ') : '-'}</div>
        </div>
        <div className={css.tableRow}>
          <div className={css.tableCell}>Gender</div>
          <div className={css.tableCellRight}>{applicant?.applicantDetail?.gender ?? '-'}</div>
        </div>
        <div className={css.tableRow}>
          <div className={css.tableCell}>Nationality</div>
          <div className={css.tableCellRight}>{applicant?.applicantDetail?.nationality ?? '-'}</div>
        </div>
        <div className={css.tableRow}>
          <div className={css.tableCell}>Grade/Band</div>
          <div className={css.tableCellRight}>
            {(applicant?.applicantDetail?.grades ?? []).length > 0 ?
              <>
                {(applicant?.applicantDetail?.grades ?? []).slice(0, 3).map((grade, index, array) => (
                  <span
                    key={index}
                    style={{ color: index === 0 ? '#000' : '#878787', marginRight: index === 2 ? '0' : '5px' }}>
                {grade.name}{index < array.length - 1 ? ', ' : ''}
              </span>
                ))}
                {((applicant?.applicantDetail?.grades ?? []).length > 3) && (
                  <>
                    <span className={css.moreGrades}>+{applicant.applicantDetail.grades.length - 3}</span>
                    <div className={css.itemSubtitle}>
                      <div className={css.itemGradesList}>
                        {(applicant?.applicantDetail?.grades ?? []).map((grade, index) => (
                          <span key={index} className={css.tooltipText}>
                        {grade.name}{index < applicant.applicantDetail.grades.length - 1 ? ', ' : ''}
                      </span>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </>
              :
              <span>-</span>
            }
          </div>
        </div>

        <div className={css.tableRow}>
          <div className={css.tableCell}>Comp. status</div>
          <div className={css.tableCellRight}>
            {applicant?.applicantDetail?.compliance_status ?? '-'}
          </div>
        </div>
      </div>
      <div className={css.contactContainer}>
        <div
          className={css.contactItem}
          style={{ backgroundColor: applicant.openContactsDetail ? 'white' : '#F6F6F6' }}
          onClick={() => onPressBooking(applicant.id, 'openContactsDetail')}>
          Contacts
          <img src={arrowRight} className={css.itemArrowIcon} alt={'arrowRight'} />
        </div>
        {applicant.openContactsDetail &&
          <div className={css.bookingDetailsContacts}>
            <div className={css.bookingDetailsContactItemContainer}>
              <div className={css.bookingDetailItemContacts}>
                <div className={css.labelContacts}>Email</div>
                <a
                  className={css.noUnderline}
                  target='_blank'
                  href={`mailto:${applicant?.applicantDetail?.contacts?.email ?? ''}`}>
                  <div
                    className={css.valueContacts}>
                    {applicant?.applicantDetail?.contacts?.email ?? ''}
                  </div>
                </a>
              </div>
              <button
                onClick={() => {
                  handleCopyText(applicant?.applicantDetail?.contacts?.email ?? '');
                  customSuccessNotification('Copied');
                }}
                className={css.copyButton}>
                <img src={copyIcon} className={css.copyIcon} alt={'copyIcon'} />
                Copy
              </button>
            </div>

            <div className={css.bookingDetailsContactItemContainer}>
              <div className={css.bookingDetailItemContacts}>
                <div className={css.labelContacts}>Phone</div>
                <div
                  className={css.valueContacts}
                  style={{ color: '#000000' }}>
                  {applicant?.applicantDetail?.contacts?.phone ?? ''}
                </div>
              </div>
              <button
                onClick={() => {
                  handleCopyText(applicant?.applicantDetail?.contacts?.phone ?? '');
                  customSuccessNotification('Copied');
                }}
                className={css.copyButton}>
                <img src={copyIcon} className={css.copyIcon} alt={'copyIcon'} />
                Copy
              </button>
            </div>
          </div>
        }
      </div>
      <div className={css.contactContainer}>
        <div
          style={{ backgroundColor: applicant.openPreferencesDetail ? 'white' : '#F6F6F6' }}
          className={css.contactItem1}
          onClick={() => onPressBooking(applicant.id, 'openPreferencesDetail')}>
          Preferences
          <img src={arrowRight} className={css.itemArrowIcon} alt={'arrowRight'} />
        </div>
        {applicant.openPreferencesDetail &&
          <div className={css.bookingDetailsPreferences}>
            <div className={css.headerPref}>
              <span
                style={{
                  color: activeTab === 1 ? '#0CADD3' : '#B8B8B8',
                  borderBottom: activeTab === 1 ? '1px solid #0CADD3' : 'none',
                  paddingBottom: '6px',
                  fontSize: '14px',
                }}
                onClick={() => setActiveTab(1)}>
                Preferred clients
              </span>
              <span
                style={{
                  color: activeTab === 2 ? '#0CADD3' : '#B8B8B8',
                  borderBottom: activeTab === 2 ? '1px solid #0CADD3' : 'none',
                  paddingBottom: '6px',
                  fontSize: '14px',
                }}
                onClick={() => setActiveTab(2)}>
                Not preferred clients
              </span>
              <span
                style={{
                  color: activeTab === 3 ? '#0CADD3' : '#B8B8B8',
                  borderBottom: activeTab === 3 ? '1px solid #0CADD3' : 'none',
                  paddingBottom: '6px',
                  fontSize: '14px',
                }}
                onClick={() => setActiveTab(3)}>
                Excluded Areas
              </span>
            </div>
            {activeTab === 1 &&
              <>
                {(applicant?.applicantDetail?.preferences?.preferred_clients ?? []).length > 0 ?
                  <div>
                    {(applicant?.applicantDetail?.preferences?.preferred_clients ?? []).map((pref) => (
                      <div>
                        <span className={css.idText}>ID: {pref.client_id}</span>
                        <span className={css.nameText}>
                          {pref.parent_client && pref.client
                            ? `${pref.parent_client}, ${pref.client}`
                            : pref.parent_client || pref.client || '-'}
                        </span>
                      </div>
                    ))}
                  </div>
                  :
                  <div className={css.noDataContainer}>
                    <img src={noDataIcon} className={css.noDataImg} alt='noDataIcon' />
                    <span className={css.noDataText}>No Data Available</span>
                  </div>
                }
              </>
            }
            {activeTab === 2 &&
              <>
                {(applicant?.applicantDetail?.preferences?.not_preferred_clients ?? []).length > 0 ?
                  <div>
                    {(applicant?.applicantDetail?.preferences?.not_preferred_clients ?? []).map((pref) => (
                      <div>
                        <span className={css.idText}>ID: {pref.client_id}</span>
                        <span className={css.nameText}>{pref.parent_client && pref.client
                          ? `${pref.parent_client}, ${pref.client}`
                          : pref.parent_client || pref.client || '-'}</span>
                      </div>
                    ))}
                  </div>
                  :
                  <div className={css.noDataContainer}>
                    <img src={noDataIcon} className={css.noDataImg} alt='noDataIcon' />
                    <span className={css.noDataText}>No Data Available</span>
                  </div>
                }
              </>
            }
            {activeTab === 3 &&
              <>
                {(applicant?.applicantDetail?.preferences?.excluded_areas ?? []).length > 0 ?
                  <div>
                    {(applicant?.applicantDetail?.preferences?.excluded_areas ?? []).map((pref, index) => (
                      <div>
                        <span className={css.idText}>{index + 1}</span>
                        <span className={css.nameText}>{pref.attribute} {pref.notes ? ': ' + pref.notes : ''}</span>
                      </div>
                    ))}
                  </div>
                  :
                  <div className={css.noDataContainer}>
                    <img src={noDataIcon} className={css.noDataImg} alt='noDataIcon' />
                    <span className={css.noDataText}>No Data Available</span>
                  </div>
                }
              </>
            }
          </div>
        }
      </div>
      <div className={css.contactContainer}>
        <div
          style={{ backgroundColor: applicant.openNotesDetail ? 'white' : '#F6F6F6' }}
          className={css.contactItem1}
          onClick={() => onPressBooking(applicant.id, 'openNotesDetail')}>
          Notes
          <img src={arrowRight} className={css.itemArrowIcon} alt={'arrowRight'} />
        </div>
        {applicant.openNotesDetail &&
          <div className={css.bookingDetailsNotes}>
            {(applicant?.applicantDetail?.notes ?? '').length > 0 ?
              <div className={css.notesValue}>{applicant?.applicantDetail?.notes}</div>
              :
              <div className={css.noDataContainer}>
                <img src={noDataIcon} className={css.noDataImg} alt='noDataIcon' />
                <span className={css.noDataText}>No Data Available</span>
              </div>
            }
          </div>
        }
      </div>

      <div className={css.contactContainer}>
        <div
          style={{ backgroundColor: applicant.openBookingDetail ? 'white' : '#F6F6F6' }}
          className={css.contactItem1}
          onClick={() => onPressBooking(applicant.id, 'openBookingDetail')}>
          Last 3 bookings
          <img src={arrowRight} className={css.itemArrowIcon} alt={'arrowRight'} />
        </div>
        {applicant.openBookingDetail &&
          <>
            {(applicant?.applicantDetail?.work_histories ?? []).length > 0 ?
              <div className={css.bookingDetails}>
                {(applicant?.applicantDetail?.work_histories ?? []).map((pos, indexPos) => {
                  const startDate = new Date(pos.start_date);
                  const endDate = new Date(pos.end_date);
                  const formattedStartDate = startDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' });
                  const formattedEndDate = endDate.toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  });
                  const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

                  return (
                    <div className={css.bookingItemContainer}>
                      <div className={css.itemRow} style={{ justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '80%', alignItems: 'center' }}>
                          <img src={jobIcon} alt={'jobIcon'} className={css.bookingIcon} />
                          <div className={css.value}>{pos.title}</div>
                        </div>
                        <div className={css.valueId}>ID: {pos?.placement_id ?? '-'}</div>
                      </div>
                      <div className={css.itemRow}>
                        <img src={trustIcon} alt={'trustIcon'} className={css.bookingIcon} />
                        <div className={css.value}>{(pos?.trust ?? '').length > 0 ? pos?.trust : '-'}</div>
                      </div>
                      <div className={css.itemRow}>
                        <img src={hospitalIcon} alt={'hospitalIcon'} className={css.bookingIcon} />
                        <div className={css.value}>{pos?.client ?? '-'}</div>
                      </div>
                      <div className={css.itemRow}>
                        <img src={dateIcon} alt={'dateIcon'} className={css.bookingIcon} />
                        <div className={css.value}>{formattedDateRange}</div>
                      </div>
                      <div className={css.itemRow}>
                        <img src={paymentIcon} alt={'paymentIcon'} className={css.bookingIcon} />
                        <div className={css.value}>{(pos?.payment_type ?? '').length > 0 ? pos?.payment_type : '-'}</div>
                      </div>
                      <div className={css.payRatesTable}>
                        <div className={css.headerTable}>
                          <span className={`${css.headerText} ${css.c1}`}>Event Type</span>
                          <span className={`${css.headerText} ${css.c2}`}>Charge</span>
                          <span className={`${css.headerText} ${css.c2}`}>Pay Rate</span>
                          <span className={`${css.headerText} ${css.c2} ${css.withoutBorder}`}>Fee</span>
                        </div>
                        {((pos?.pay_rates ?? []).length > 0 ? (pos?.pay_rates ?? []) : [{
                          name: '-',
                          charge: '-',
                          rate: '-',
                          fee: '-',
                        }]).map((pay_rate, index) => (
                          <div
                            className={`${css.headerContent} ${((pos?.pay_rates ?? []).length > 0 ? (pos?.pay_rates ?? []) : [{
                              name: '-',
                              charge: '-',
                              rate: '-',
                              fee: '-',
                            }]).length - 1 !== index ? css.withoutBorderRadius : {}}`}>
                            <span className={`${css.contentTableText} ${css.c1}`}>{pay_rate.name}</span>
                            <span
                              className={`${css.contentTableText} ${css.c2}`}>{pay_rate.charge !== '-' ? '£ ' + pay_rate.charge : '-'}</span>
                            <span
                              className={`${css.contentTableText} ${css.c2}`}>{pay_rate.rate !== '-' ? '£ ' + pay_rate.rate : '-'}</span>
                            <span
                              className={`${css.contentTableText} ${css.c2} ${css.withoutBorder}`}>{pay_rate.fee !== '-' ? '£ ' + pay_rate.fee : '-'}</span>
                          </div>
                        ))}
                      </div>
                      {(applicant?.applicantDetail?.work_histories ?? []).length - 1 !== indexPos &&
                        <div className={css.positionDivider} />
                      }
                    </div>
                  );
                })}
              </div>
              :
              <div className={css.bookingDetails}>
                <div className={css.noDataContainer}>
                  <img src={noDataIcon} className={css.noDataImg} alt='noDataIcon' />
                  <span className={css.noDataText}>No Data Available</span>
                </div>
              </div>
            }
          </>
        }
      </div>
    </div>
  );
};
