import React, { useEffect, useRef, useState } from 'react';
import css from './ViewEventModal.module.css';
import editPencilIcon from '../../../../assets/images/editPencilIcon.png';
import trashIcon from '../../../../assets/images/trashIcon.png';
import closeButton from '../../../../assets/images/close-button.png';
import { Oval } from 'react-loader-spinner';
import { DeleteEventModal } from '../DeleteEventModal/DeleteEventModal';
import api from '../../../../config/api';

const props = {
  color: 'var(--primary)',
  height: 100,
  width: 110,
  secondaryColor: 'var(--primary)',
};

export const ViewEventModal = ({
                                 calendarItem,
                                 onChangeVisibility,
                                 index,
                                 onEditEvent,
                                 onGetCalendarData,
                                 setGlobalLoading,
                                 openGlobalDelete,
                                 applicantId,
                               }) => {
  const { eventData } = calendarItem || {};
  const {
    end_time,
    start_time,
    note,
    can_edit,
    can_delete,
    id,
    type_name,
    applicant,
    client,
  } = eventData?.events?.[0] ?? {};
  const [loading, setLoading] = useState(true);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const isBookingRef = useRef(null);

  useEffect(() => {
    if (eventData) {
      setLoading(false);
    }
  }, [eventData]);

  const convertDate = (dateString) => {
    if (dateString) {
      const dateObject = new Date(dateString);
      const options = { weekday: 'long', month: 'long', day: 'numeric' };
      return dateObject.toLocaleDateString('en-US', options);
    } else {
      return '';
    }
  };

  const convertTime = (timeString) => {
    if (timeString) {
      const date = new Date(timeString);
      const hours24 = date.getHours();
      const minutes = date.getMinutes();
      const formattedHours = hours24 < 10 ? `0${hours24}` : hours24;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const amPm = hours24 < 12 ? 'AM' : 'PM';

      return `${formattedHours}:${formattedMinutes} ${amPm}`;
    } else {
      return '';
    }
  };

  const changeDeleteVisibility = () => {
    if (((eventData?.events?.[0]?.type_external_id ?? '') === 31 || (eventData?.events?.[0]?.type_external_id ?? '') === 32) && !openGlobalDelete) {
      setIsOpenDeleteModal(!isOpenDeleteModal);
      setIsBooking(true);
      isBookingRef.current = true;
    } else if (((eventData?.events?.[0]?.type_external_id ?? '') === 31 || (eventData?.events?.[0]?.type_external_id ?? '') === 32) && openGlobalDelete) {
      isBookingRef.current = true;
      setIsBooking(true);
      openGlobalDelete(id, applicantId, handleEditEvent, true);
    } else {
      isBookingRef.current = false;
      setIsBooking(false);
      if (openGlobalDelete) {
        openGlobalDelete(id, applicantId, handleEditEvent);
        onChangeVisibility();
      } else {
        setIsOpenDeleteModal(!isOpenDeleteModal);
      }
    }
  };

  const handleEditEvent = (e, deleteBook) => {
    if (deleteBook) {
      onEditEvent(true, true, true);
    } else {
      if (e === true && isBookingRef.current) {
        onChangeVisibility();
        onEditEvent(e, true);
      } else {
        onChangeVisibility();
        onEditEvent(e);
      }
    }
  };

  const handleDeleteEvent = async () => {
    if (isBooking) {
      onEditEvent(true, true, true);
    } else {
      setGlobalLoading(true);
      changeDeleteVisibility();
      onChangeVisibility();
      try {
        await api.DELETE_EVENT(id);
        onGetCalendarData(true);
      } catch (e) {
        setGlobalLoading(false);
      }
    }
  };

  const isSameDay = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  };

  return (
    <div
      style={{ left: index < 18 ? '52px' : '-428px' }}
      className={css.modalContent}>
      {loading ?
        <div className={css.loader}>
          <Oval {...props} height={50} width={50} />
        </div>
        :
        <>
          {(eventData?.events ?? []).length === 0 &&
            <div
              className={css.headerContainer}
              style={{ borderBottom: 'none' }}>
              <img
                onClick={onChangeVisibility}
                className={css.closeIcon}
                style={{ position: 'absolute', right: '15px' }}
                src={closeButton}
                alt={'closeButton'} />
              <div className={css.noEventsContainer}>
                <span className={css.noEvents}>The detailed information about this booked event available in a cached database</span>
              </div>
            </div>
          }
          {(eventData?.events ?? []).map((event, eventIndex) => {
            const selectedEventType = (eventData?.event_types ?? []).find(eventType => eventType.id === event?.type_id ?? null);

            return (
              <>
                <div
                  className={css.headerContainer}
                  style={{ borderBottom: (eventData?.events ?? []).length > 0 ? '1px solid #E7E7E8' : 'none' }}>
                  {(eventData?.events ?? []).length > 0 ?
                    <>
                      {selectedEventType ?
                        <div className={css.colContainer}>
                          <span className={css.eventName}>{selectedEventType?.name ?? ''}</span>
                          <div className={css.rowContainer}>
                            <div
                              className={css.squareColorContainer}
                              style={{ backgroundColor: selectedEventType?.color ?? 'white' }} />
                            <span className={css.statusName}>
                              {(selectedEventType?.color ?? '') === 'green' ? 'Available' : 'Not Available'}
                            </span>
                          </div>
                        </div>
                        :
                        <>
                          {((eventData?.events?.[0]?.type_external_id ?? '') === 31 || (eventData?.events?.[0]?.type_external_id ?? '') === 32) &&
                            <div className={css.colContainer}>
                              <span className={css.eventName}>{type_name}</span>
                              <div className={css.rowContainer}>
                                <div
                                  className={css.squareColorContainer}
                                  style={{ backgroundColor: 'orange' }} />
                                <span className={css.statusName}>Booked</span>
                                <span className={css.divider}>|</span>

                                <span className={css.statusName}>Placement ID: {event.placement_id}</span>
                              </div>
                            </div>
                          }
                          {(eventData?.events?.[0]?.type_external_id ?? '') === 47 &&
                            <div className={css.colContainer}>
                              <span className={css.eventName}>Available</span>
                              <div className={css.rowContainer}>
                                <div
                                  className={css.squareColorContainer}
                                  style={{ backgroundColor: 'green' }} />
                                <span className={css.statusName}>Available</span>
                              </div>
                            </div>
                          }
                        </>
                      }
                    </>
                    :
                    <div className={css.colContainer} />
                  }
                  {((eventData?.events?.[0]?.availability ?? '') === 'Booking Request' &&
                    <div className={css.colContainer}>
                      <span className={css.eventName}>{(eventData?.events?.[0]?.type_name ?? '')}</span>
                      <div className={css.rowContainer}>
                        <div className={css.squareColorContainerEmpty} />
                        <span className={css.statusName}>
                   Booking Request
                  </span>
                        <span className={css.divider}>|</span>
                        <span className={css.statusName}>Booking ID: {eventData?.events?.[0]?.booking_id ?? ''}</span>
                      </div>
                    </div>
                  )}
                  <div className={css.iconsContainer}>
                    {can_edit &&
                      <img
                        onClick={handleEditEvent}
                        className={css.editIcon}
                        src={editPencilIcon}
                        alt={'editIcon'} />
                    }
                    {(can_delete || ((eventData?.events?.[0]?.type_external_id ?? '') === 31 || (eventData?.events?.[0]?.type_external_id ?? '') === 32)) &&
                      <img
                        src={trashIcon}
                        alt={'trashIcon'}
                        className={css.deleteIcon}
                        onClick={changeDeleteVisibility}
                      />
                    }
                    {eventIndex === 0 &&
                      <img
                        onClick={onChangeVisibility}
                        className={css.closeIcon}
                        src={closeButton}
                        alt={'closeButton'} />
                    }
                  </div>
                </div>
                {!selectedEventType &&
                  <>
                    <div className={css.applicantNameContainer}>
                          <span
                            className={css.applicantName}>{applicant?.first_name + ' ' + applicant?.last_name}</span>
                    </div>
                    <div className={css.clientNameContainer}>
                      <span className={css.clientName}>{client?.name ?? ''}</span>
                    </div>
                  </>
                }
                {(start_time || end_time) &&
                  <div className={css.dataContainer}>
                    <span className={css.dateText}>{convertDate(start_time)}</span>
                    {selectedEventType && !isSameDay(start_time, end_time) &&
                      <>
                        <span>{' - '}</span>
                        <span className={css.dateText}>{convertDate(end_time)}</span>
                      </>
                    }
                  </div>
                }
                {(start_time || end_time) &&
                  <div className={css.timeContainer}>
                    <span className={css.dateText}>{convertTime(start_time)}</span>
                    {start_time &&
                      <span>{' - '}</span>
                    }
                    <span className={css.dateText}>{convertTime(end_time)}</span>
                  </div>
                }
                {/*{selectedEventType &&*/}
                {/*  <div className={css.repeatContainer}>*/}
                {/*    <span className={css.repeatText}>Repeat</span>*/}
                {/*    <span className={css.repeatDataText}></span>*/}
                {/*  </div>*/}
                {/*}*/}
                {note &&
                  <div className={css.noteContainerModal}>
                    <span className={css.noteTitle}>Note</span>
                    <span className={css.noteText}>{note}</span>
                  </div>
                }
                {(eventData?.events ?? []).length - 1 !== eventIndex &&
                  <div className={css.emptyView} />
                }
              </>
            );
          })}
        </>
      }
      {isOpenDeleteModal &&
        <DeleteEventModal
          onChangeVisibility={changeDeleteVisibility}
          onDeleteEvent={handleDeleteEvent}
          handleEditEvent={handleEditEvent}
          isBooking={isBooking}
        />
      }
    </div>
  );
};
