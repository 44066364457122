import css from './MultiSelect.module.css';
import React, { useEffect, useRef, useState } from 'react';
import sortUp from '../../assets/images/sortUp.svg';
import sortDown from '../../assets/images/sortDown.svg';
import closeButton from '../../assets/images/close-button.png';
import Input from '../Input/Input';

export const MultiSelect = ({
                              placeholder,
                              onGetData,
                              onChange,
                              dataList,
                              className,
                              selectContainerClass,
                              onOpenSelect,
                              isActive,
                              setShouldGetData,
                              shouldCleanData,
                              disabled,
                              search,
                              selectedItems,
                              trustFilterType,
                              setTrustFilterType,
                            }) => {
  const [filtersOriginal, setFiltersOriginal] = useState(dataList);
  const [filters, setFilters] = useState(dataList);
  const [searchSelect, setSearchSelect] = useState('');
  const [localDisabled, setLocalDisabled] = useState(undefined);
  const [displayText, setDisplayedText] = useState('');
  const filtersRef = useRef(dataList);
  const filtersRef1 = useRef(dataList);


  useEffect(() => {
    if (dataList && dataList.length > 0) {
      const updatedFilters = dataList.map(item => {
        const existingFilter = (filtersRef?.current ?? []).find(filter => filter.key === item.key);
        return existingFilter ? { ...item, checked: existingFilter.checked } : item;
      });
      const updatedFilters1 = updatedFilters.map(filter => {
        const isChecked = (placeholder === 'Trust' ? [selectedItems] : (selectedItems ?? [])).includes(filter.key);
        return { ...filter, checked: isChecked };
      });
      filtersRef.current = updatedFilters1;
      setFilters(updatedFilters1);
      setFiltersOriginal(updatedFilters1);
    } else {
      setFilters(dataList);
      setFiltersOriginal(dataList);
      filtersRef.current = dataList;
    }
  }, [dataList]);

  useEffect(() => {
    if (shouldCleanData) {
      if (placeholder === 'Trust') {
        onChange('');
      }
      const newFilters = filters.map((filter) => {
        if (placeholder !== 'Trust') {
          onChange(filter.key, false);
        }
        return { ...filter, checked: false };
      });
      if (placeholder === 'Trust') {
        const newFiltersTrust = (filtersRef1?.current ?? []).map((filter) => {
          if (placeholder !== 'Trust') {
            onChange(filter.key, false);
          }
          return { ...filter, checked: false };
        });
        setFiltersOriginal(newFiltersTrust);
        setFilters(newFiltersTrust);
        setSearchSelect('');
      }
      setFilters(newFilters);
      filtersRef.current = newFilters;
    }
  }, [shouldCleanData]);

  // useEffect(() => {
  //   if (placeholder === 'Trust' && isActive) {
  //     setSearchSelect('');
  //     setFilters(filtersRef1?.current ?? []);
  //   }
  // }, [isActive, placeholder]);

  const changeVisibilityModal = () => {
    if (!disabled) {
      onOpenSelect(isActive ? null : placeholder);
    }
  };

  useEffect(() => {
    if (disabled && localDisabled !== undefined) {
      onPressClear();
    }
    setLocalDisabled(disabled);
  }, [disabled]);

  const handleCheckboxChange = (index) => {
    const updatedFilters = filters.map((filter, i) => {
      if (i === index) {
        if (search) {
          onChange(filter.key, !filter.checked);
          return { ...filter, checked: !filter.checked };
        }
        onChange(filter.key, !filter.checked);
        return { ...filter, checked: !filter.checked };
      } else {
        return search ? { ...filter, checked: false } : filter;
      }
    });
    filtersRef.current = updatedFilters;
    setFilters(updatedFilters);
    setFiltersOriginal(updatedFilters);
    if (search) {
      changeVisibilityModal();
    }
  };

  const onPressClear = (close) => {
    if (placeholder === 'Trust') {
      onChange(null);
    }
    const newFilters = filters.map((filter) => {
      if (placeholder !== 'Trust') {
        onChange(filter.key, false);
      }
      return { ...filter, checked: false };
    });
    setFilters(newFilters);
    setFiltersOriginal(newFilters);
    filtersRef.current = newFilters;
    setTimeout(() => {
      setShouldGetData(true);
    }, 1000);
    if (close !== true) {
      changeVisibilityModal();
    }
  };

  const onPressApply = () => {
    onGetData();
    changeVisibilityModal();
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchSelect(searchTerm);
    const filteredItems = filtersOriginal.filter(item => (item?.title ?? '').toLowerCase().includes(searchTerm.toLowerCase()));
    setFilters(searchTerm === '' ? filtersOriginal : filteredItems);
  };

  useEffect(() => {
    const updatedFilters = filters.map(filter => {
      const isChecked = (placeholder === 'Trust' ? [selectedItems] : (selectedItems ?? [])).includes(filter.key);
      return { ...filter, checked: isChecked };
    });

    const updatedFilters1 = (filtersRef1?.current ?? []).map(filter => {
      const isChecked = (placeholder === 'Trust' ? [selectedItems] : (selectedItems ?? [])).includes(filter.key);
      return { ...filter, checked: isChecked };
    });

    setFilters(updatedFilters);
    setFiltersOriginal(updatedFilters1);
    filtersRef.current = updatedFilters;
    filtersRef1.current = updatedFilters1;
  }, [selectedItems]);

  useEffect(() => {
    const selectedTitles = filters
      .filter(item => (placeholder === 'Trust' ? [selectedItems] : (selectedItems ?? [])).includes(item.key))
      .map(item => item.title)
      .slice(0, 3)
      .join(', ');
    let displayTextNew = selectedTitles?.length > 0 ? `${placeholder}: ${selectedTitles}` : placeholder;
    setDisplayedText(displayTextNew);
  }, [filters, placeholder]);

  return (
    <div className={`${css.mainContainer} ${className || {}}`}>
      <div className={css.selectContainer} onClick={changeVisibilityModal}>
        <span className={css.selectContainerText} style={{ color: disabled ? '#d5d5d5' : '#2E2E30' }}>
          {displayText}
        </span>
        <div className={css.crossContainer}>
          {filters.filter(item => item.checked).length > 0 ?
            <img
              onClick={(e) => {
                e.stopPropagation();
                onPressClear(true);
              }}
              alt='cross'
              className={css.closeButton}
              src={closeButton}
            />
            :
            <img
              alt='sorting way'
              className={css.filterIcon}
              src={isActive ? sortUp : sortDown}
            />
          }
        </div>
      </div>
      {isActive && (
        <div className={`${css.modalContainer}`}>
          <div className={css.modalContent}>
            {placeholder === 'Trust' &&
              <div className={css.trustFilterContainer}>
                <div className={css.buttonsContainerFilter}>
                  <div
                    className={css.buttonSwitchContainer}
                    style={{ backgroundColor: trustFilterType === 'history' ? '#0CADD3' : 'white' }}
                    onClick={() => {
                      setTrustFilterType('history');
                      const selectedTitles = filters
                        .filter(item => (placeholder === 'Trust' ? [selectedItems] : (selectedItems ?? [])).includes(item.key))
                        .map(item => item.title)
                        .slice(0, 3)
                        .join(', ');
                      if (selectedTitles.length > 0) {
                        if (placeholder === 'Trust') {
                          onChange(null);
                        }
                        const newFilters = filters.map((filter) => {
                          return { ...filter, checked: false };
                        });
                        setFilters(newFilters);
                        setFiltersOriginal(newFilters);
                        filtersRef.current = newFilters;
                      }
                    }}>
                    <span className={css.buttonSwitchText}
                          style={{ color: trustFilterType === 'history' ? '#ffffff' : '#2E2E30' }}>History</span>
                  </div>
                  <div
                    className={css.buttonSwitchContainer}
                    style={{ backgroundColor: trustFilterType !== 'preferences' ? 'white' : '#0CADD3' }}
                    onClick={() => {
                      setTrustFilterType('preferences');
                      const selectedTitles = filters
                        .filter(item => (placeholder === 'Trust' ? [selectedItems] : (selectedItems ?? [])).includes(item.key))
                        .map(item => item.title)
                        .slice(0, 3)
                        .join(', ');
                      if (selectedTitles.length > 0) {
                        if (placeholder === 'Trust') {
                          onChange(null);
                        }
                        const newFilters = filters.map((filter) => {
                          return { ...filter, checked: false };
                        });
                        setFilters(newFilters);
                        setFiltersOriginal(newFilters);
                        filtersRef.current = newFilters;
                      }
                    }}>
                    <span className={css.buttonSwitchText}
                          style={{ color: trustFilterType !== 'preferences' ? '#2E2E30' : '#FFFFFF' }}>Preferences</span>
                  </div>
                  <div
                    className={css.buttonSwitchContainer}
                    style={{ backgroundColor: trustFilterType !== 'combine' ? 'white' : '#0CADD3' }}
                    onClick={() => {
                      setTrustFilterType('combine');
                      const selectedTitles = filters
                        .filter(item => (placeholder === 'Trust' ? [selectedItems] : (selectedItems ?? [])).includes(item.key))
                        .map(item => item.title)
                        .slice(0, 3)
                        .join(', ');
                      if (selectedTitles.length > 0) {
                        if (placeholder === 'Trust') {
                          onChange(null);
                        }
                        const newFilters = filters.map((filter) => {
                          return { ...filter, checked: false };
                        });
                        setFilters(newFilters);
                        setFiltersOriginal(newFilters);
                        filtersRef.current = newFilters;
                      }
                    }}>
                    <span className={css.buttonSwitchText}
                          style={{ color: trustFilterType !== 'combine' ? '#2E2E30' : '#FFFFFF' }}>Combination</span>
                  </div>
                </div>
              </div>
            }
            {search &&
              <div className={css.searchContainer}>
                <Input
                  size={'x100'}
                  value={searchSelect}
                  type='text'
                  placeholder='Search by name'
                  onChange={handleSearch}
                  clear={true}
                  clearButtonStyle={'3px'}
                />
              </div>
            }
            <div className={`${css.listContainer} ${selectContainerClass || {}}`}>
              {filters.map((item, index) => (
                <div key={`item_div${item?.id ?? index}`}>
                  <div key={item.id} className={css.filterItemContainer} onClick={() => handleCheckboxChange(index)}>
                    <input
                      type='checkbox'
                      id={`item${item.id}`}
                      name={`item_name${item.id}`}
                      className={css.checkBox}
                      onChange={() => {
                      }}
                      checked={item.checked} />
                    <label htmlFor={`item${index}`} className={css.itemName}>{item.title}</label>
                  </div>
                </div>
              ))}
            </div>
            {placeholder !== 'Trust' &&
              <div className={css.buttonsContainer}>
                <span onClick={onPressClear}>Clear</span>
                <div className={css.applyButton} onClick={onPressApply}>
                  <span>Apply</span>
                </div>
              </div>
            }
          </div>
        </div>
      )}
    </div>
  );
};
